<template>
  <div class="box">
<!--    <div class="contact">联系我们</div>-->
<!--    <div class="open">开启数智化新体验</div>-->
    <div class="contact-main">
      <div class="message" style="margin: 0.16rem 0 0.18rem 0">
      <span >
        <el-input size="small " v-model="info.name" placeholder="请填写你的姓名"  style="width: 1.6rem"></el-input>
      </span>
        <span >
        <el-input size="small " v-model="info.telphone" placeholder="请填写联系方式" style="width:  1.6rem"></el-input>
      </span>
        <span>
        <el-select v-model="info.address" placeholder="请选择区域" size="small" style="width:  1.6rem">
          <el-option v-for="item in areaList" :key="item.code" :label="item.name" :value="item.name"></el-option>
        </el-select>
      </span>
        <span >
        <el-select v-model="info.partners" placeholder="请选择" size="small" style="width:  1.6rem">
          <el-option v-for="item in options2" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </span>
        <span class="caseGet" @click="submit">
          方案获取
        </span>
      </div>
<!--      <div class="message">-->

<!--      </div>-->
<!--      <div class="caseGet" @click="submit">-->
<!--        <span>方案获取</span>-->
<!--      </div>-->
    </div>

  </div>
</template>
<script>
import { postConsultingRecords } from "@/api/home"

export default {
  data() {
    return {
      areaList: [
        { code: "11", name: "北京市" },
        { code: "12", name: "天津市" },
        { code: "13", name: "河北省" },
        { code: "14", name: "山西省" },
        { code: "15", name: "内蒙古自治区" },
        { code: "21", name: "辽宁省" },
        { code: "22", name: "吉林省" },
        { code: "23", name: "黑龙江省" },
        { code: "31", name: "上海市" },
        { code: "32", name: "江苏省" },
        { code: "33", name: "浙江省" },
        { code: "34", name: "安徽省" },
        { code: "35", name: "福建省" },
        { code: "36", name: "江西省" },
        { code: "37", name: "山东省" },
        { code: "41", name: "河南省" },
        { code: "42", name: "湖北省" },
        { code: "43", name: "湖南省" },
        { code: "44", name: "广东省" },
        { code: "45", name: "广西壮族自治区" },
        { code: "46", name: "海南省" },
        { code: "50", name: "重庆市" },
        { code: "51", name: "四川省" },
        { code: "52", name: "贵州省" },
        { code: "53", name: "云南省" },
        { code: "54", name: "西藏自治区" },
        { code: "61", name: "陕西省" },
        { code: "62", name: "甘肃省" },
        { code: "63", name: "青海省" },
        { code: "64", name: "宁夏回族自治区" },
        { code: "65", name: "新疆维吾尔自治区" },
      ],
      options2: [
        {
          value: 1,
          label: "合作商",
        },
        {
          value: 2,
          label: "业务方",
        },
      ],
      info: {
        partners: null,
        address: null,
        telphone: null,
        name: null,
        froms: 1,
      },
    }
  },
  methods: {
    submit() {
      if (!this.info.name) {
        this.$message.error("请输入姓名")
        return
      } else if (!this.info.telphone) {
        this.$message.error("请输入联系方式")
        return
      } else if (!this.info.address) {
        this.$message.error("请选择区域")
        return
      } else if (!this.info.partners) {
        this.$message.error("请选择合作商")
        return
      }
      postConsultingRecords(this.info).then(res => {
        this.$app.$refs.successDialog.show()
      })
    },

  },
}
</script>
<style lang="less" scoped>
.box {
  height: .6rem;
  background: #1080ef;
  text-align: center;
  .contact-main {
    width: 8.8rem;
    margin: 0 auto;
  }
  .contact {
    font-size: 0.28rem;
    font-weight: 400;
    color: #ffffff;
    padding-top: 0.5rem;
  }

  .open {
    font-size: 0.14rem;

    color: #ffffff;
    line-height: 0.19rem;
  }

  .message {
    span {
      display: inline-block;
      width: 1.6rem;
      margin-left: 0.2rem;
    }
    .caseGet {
      //width: 10.4rem;
      //margin: 0 auto;
      //margin-top: -.8rem;
      //overflow: hidden;
        cursor: pointer;
        display: inline-block;
        width: 1rem;
        height: .3rem;
        background: #fdcd03;
        line-height:.3rem;
        text-align: center;
        font-size: 0.14rem;
        font-weight: 500;
        color: #0647b9;
        float: right;
        //margin-top: 0.26rem;
    }
  }

}
::v-deep .el-select-dropdown__item &:hover,
&.hover {
  background-color: #69bf5d !important;
  color: white !important;
}

//::v-deep .el-input__inner,
//::v-deep .el-select-dropdown__item {
//  height: 0.32rem !important;
//  line-height: 0.32rem !important;
//  font-size: 0.13rem !important;
//}
//::v-deep.el-select-dropdown__wrap {
//  max-height: 2.74rem !important;
//}
</style>
