<template>
  <div class="container">
    <div class="title">{{ title }}</div>
    <div class="line">
      <div class="blue"></div>
      <div class="black"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SceneTitle",
  props: {
    title: "",
  },
}
</script>

<style scoped lang="less">
.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 1rem;
  margin-bottom: 0.45rem;
  margin-top: 0.96rem;
  .title {
    font-size: 0.25rem;
    color: #4770c1;
    margin-bottom: 0.06rem;
  }
  .line {
    display: flex;
    align-items: flex-end;
    .blue {
      width: 0.73rem;
      height: 0.06rem;
      background: #4770c1;
    }
    .black {
      width: 0.51rem;
      height: 0.01rem;
      background: #989898;
    }
  }
}
</style>
