var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"index-header",on:{"touchmove":function($event){$event.preventDefault();}}},[_c('wrap',{staticClass:"pc-tab",class:_vm.isBlue ? 'custom-tab' : ''},[_c('img',{staticClass:"logo",attrs:{"src":require("../../assets/images/zm_logo.png")},on:{"click":_vm.reload}}),_c('div',{staticClass:"content"},[_c('div',{staticClass:"item",class:{
          selected: _vm.$route.path == '/' || _vm.$route.path.includes('/Home'),
        },on:{"click":function($event){return _vm.$router.replace('/Index/Home')}}},[_vm._v(" 首页 ")]),_c('div',{staticClass:"item",class:{
          selected: _vm.$route.path.includes('/productCenter'),
        },on:{"click":function($event){return _vm.$router.replace('/productCenter')}}},[_vm._v(" 产品中心 ")]),_c('div',{staticClass:"item",class:{
          selected: _vm.$route.path.includes('/page'),
          hover: _vm.isShowSolution,
        },on:{"mouseenter":_vm.showSolutionTab,"mouseleave":_vm.hideSolutionTab}},[_vm._v(" 解决方案 ")]),_c('div',{staticClass:"item",class:{ selected: _vm.$route.path.includes('/cases') },on:{"click":function($event){return _vm.$router.replace('/cases')}}},[_vm._v("经典案例")]),_c('div',{staticClass:"item",class:{ selected: _vm.$route.path.includes('/about') },on:{"click":function($event){return _vm.$router.replace('/about')}}},[_vm._v("关于筑美")])])]),_c('transition',{attrs:{"name":"slide-top"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowProduct),expression:"isShowProduct"}],staticClass:"pc_sub_tab",on:{"mouseenter":_vm.showProductTab,"mouseleave":_vm.hideProductTab}},[_c('Product',{on:{"hideMe":_vm.hideProductTab}})],1)]),_c('transition',{attrs:{"name":"slide-top"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowSolution),expression:"isShowSolution"}],staticClass:"pc_sub_tab",on:{"mouseenter":_vm.showSolutionTab,"mouseleave":_vm.hideSolutionTab}},[_c('Solution',{on:{"hideMe":_vm.hideSolutionTab}})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }