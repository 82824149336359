<template>
  <div id="app" :class="$app.isPc ? 'marginStyle' : ''">
    <wrap class="info-wrap">
      <div class="warp-div">
        <blue-title class="my-blue" title="资讯详情" en-title="Information For Details"></blue-title>
        <div class="release-time">发布时间: {{ detail.create_time }}</div>
        <div class="title">{{ detail.title }}</div>
        <div class="subtitle">{{ detail.subtitle }}</div>
        <div class="content" v-html="detail.article_details || detail.content"></div>
      </div>
    </wrap>
  </div>
</template>

<script>
import { getCompanyInformationDetails } from "@/api/about"
import Wrap from "@/components/common/Wrap"
import BlueTitle from "@/components/BlueTitle"
import { getArticleDetails } from "@/api/home"

export default {
  name: "InformationDetail",
  components: { BlueTitle, Wrap },
  data() {
    return {
      detail: {},
    }
  },
  mounted() {
    const id = this.$route.query.id
    const info = this.$route.query.info
    if (!id) {
      alert("资讯不存在!")
      return
    }
    if (info) {
      this.getInformationDetails(id)
      //主页来的
    } else {
      this.getArticleDetails(id)
    }
  },
  methods: {
    getInformationDetails(id) {
      getCompanyInformationDetails(id).then(res => {
        this.detail = res.data
      })
    },
    getArticleDetails(id) {
      getArticleDetails(id).then(res => {
        this.detail = res.data
      })
    },
  },
}
</script>

<style scoped lang="less">
#app {
  background-color: #ededed;
  height: 100%;
}
.marginStyle {
  margin-top: @headerHeight;
}
.info-wrap {
  display: flex;
  flex-direction: column;
}
.warp-div {
  flex: 1;
  background-color: white;
  padding: 0 0.5rem;
  display: flex;
  flex-direction: column;
  padding-bottom: 0.8rem;
}
.my-blue {
  padding: 0.36rem 0;
}
.release-time {
  font-size: 0.15rem;
  font-weight: 500;
  color: #626262;
  text-align: left;
}
.title {
  margin-top: 0.06rem;
  text-align: left;
  font-size: 0.3rem;
  font-weight: 600;
  color: #626262;
}
.subtitle {
  margin-top: 0.06rem;
  text-align: left;
  font-size: 0.24rem;
  font-weight: 500;
  color: #959595;
}
.content {
  margin-top: 0.5rem;
  text-align: justify;
  font-size: 0.14rem;
}
</style>
