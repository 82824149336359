<template>
  <div class="mask-layer" v-if="codeMaskShow">
      <div class="main">
        <div class="qrCode">
          <div class="information">扫码咨询售前顾问</div>
          <img src="@/assets/images/zm_erweima.png" alt="">
        </div>
        <div class="phone" >
          <div class="p1">电话咨询售前顾问</div>
          <div class="p2">180-0588-7019</div>
        </div>
        <div @click="aaa()">
          <img class="close" src="@/assets/images/close_phone.png" alt="" />
        </div>

      </div>
  </div>
</template>

<script>
export default {
  name: "PhoneDialog",
  props: {},
  data(){
    return {
      codeMaskShow: false
    }
  },
  methods:{
    aaa() {
      this.codeMaskShow = false
    }
  }
}
</script>

<style scoped lang="less">
.mask-layer {
  position: fixed;
  display: inline-block;
  width: 100%;
  height: 100%;
  background-color:rgba(0,0,0,0.5);
  //box-shadow: 0 0 0.1rem rgba(0, 0, 0, 0.04);
  right: 0;
  z-index: 99;
  overflow: hidden;
  .main {
    width: 4.05rem;
   // height: 3.38rem;
    margin: 0 auto;
    margin-top: 1rem;
    .qrCode {
      width: 4.05rem;
      height: 3.38rem;
      background: #FFFFFF;
      order-radius: 100px 0 50px 0;
      background:
        radial-gradient(circle at top left, transparent 0px, #FFFFFF 0) top left,
      radial-gradient(circle at top right, transparent 0px, #FFFFFF 0) top right,
      radial-gradient(circle at bottom right, transparent .16rem, #FFFFFF 0) bottom right,
      radial-gradient(circle at bottom left, transparent .16rem, #FFFFFF 0) bottom left;
      background-size: 50% 50%;
      background-repeat: no-repeat;
      .information {
       padding: .2rem 0;
        font-size: 28px;
        color: #03162C;
      }
      img {
        width: 2.2rem;
        height: 2.2rem;
      }
    }
    .phone {
      width: 4.05rem;
      height: 1.14rem;
      background: url("@/assets/images/phone_bj.png") no-repeat;
      background-size: 100% 100%;
      font-size: 22px;
      font-weight: 500;
      color: #FFFFFF;
      line-height: .35rem;
      .p1 {
        padding-top:.2rem;
      }
    }
    .close {
      width: .5rem;
      height: .5rem;
      margin-top: .5rem;
    }

  }
}
</style>
