<template>
  <div class="partner">
    <div class="nav">
      <img src="../assets/images/partner/partner.png" alt="" class="nav_bg">
      <div class="word">全国区域服务商正在火热招募中</div>
      <div class="applyNow" >
        <a href="#apply" style="color: #FFFFFF">立即申请></a></div>
    </div>
    <div class="main">
      <div class="main_all">
        <div class="numOne">
          <div class="header">
            <img src="../assets/images/partner/01@2x.png" alt="">
            <div class="tip">加盟优势</div>
          </div>
          <div class="superiority">
            <div v-for="(list,index) in superioritys" :key="index" class="superiority_box">
              <img :src="list.img" alt="">
              <div class="characters">
                <div class="tit">{{list.tit}}</div>
                <div class="tip">{{list.tip}}</div>
                <div class="word">{{list.word}}</div>
              </div>
            </div>

          </div>
        </div>
        <div class="numTwo">
          <div class="header">
            <img src="../assets/images/partner/01@2x(1).png" alt="">
            <div class="tip">八大支持</div>
          </div>
          <div class="supports">
            <div v-for="(list,index) in eightSupports" :key="index" class="supports_box">
              <img :src="list.img" alt="">
              <div class="tit">{{ list.tit }}</div>
              <div class="word">{{list.word}}</div>
            </div>
          </div>
        </div>
        <div class="numThree">
          <div class="header">
            <img src="../assets/images/partner/01@2x(2).png" alt="">
            <div class="tip">服务商条件&加盟流程</div>
          </div>
          <div class="Join">
            <img src="../assets/images/partner/背景.png" alt="" class="Join_one">
            <img src="../assets/images/partner/流程@2x.png" alt="" class="Join_two">
          </div>
        </div>
        <div class="numFour">
          <div class="header">
            <img src="../assets/images/partner/04@2x.png" alt="">
            <div class="tip">服务商条件&加盟流程</div>
          </div>
          <div class="Join">
            <img src="../assets/images/partner/内容@2x.png" alt="" class="Join_one">
          </div>
        </div>
        <div class="numFive" id="apply">
          <div class="header">
            <div class="tip">立即申请</div>
            <div class="word">数智链接未来，与您共拓行业蓝海</div>
          </div>
          <div class="fiveMain">
            <div class="message" >
             <div class="left">姓名</div>
             <div class="right"><el-input size="small " v-model="info.name" placeholder="请填写你的姓名"></el-input></div>
           </div>
           <div class="message">
             <div class="left">电话</div>
             <div class="right"><el-input size="small " v-model="info.telphone" placeholder="请填写联系方式"></el-input></div>
          </div>
          <div class="message">
             <div class="left">城市</div>
            <div class="right">
             <el-select v-model="info.address" placeholder="请选择城市" size="small" style="width: 100%">
               <el-option v-for="item in areaList" :key="item.code" :label="item.name" :value="item.name"></el-option>
            </el-select>
            </div>
          </div>
          <div class="message">
            <div class="left">行业</div>
            <div class="right">
            <el-select v-model="info.partners" placeholder="请选择" size="small" style="width: 100%">
            <el-option v-for="item in options2" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
            </div>

          </div>
            <div class="caseGet" @click="submit">
              <span>提交</span>
            </div>
          </div>

        </div>
        <div class="numSix">
          <div>
            <img src="../assets/images/partner/phone-fill@2x.png" alt="" class="phone">
            <div>
              <div class="word">全国服务热线</div>
              <div class="word">18005887019</div>
            </div>
          </div>
          <div >
            <img src="../assets/images/partner/二维码@2x.jpg" alt="" class="code">
            <div>
              <div class="word">招商人员二维码</div>
              <div class="word">扫码了解更多信息</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { postConsultingRecords } from "@/api/home"
export default {
  name: "partner",
  data(){
    return{
      eightSupports:[
        {
          img:require('../assets/images/partner/八大支持@2x.png'),
          tit:'资源支持',
          word:'公司数十年行业积攒\n' +
            '资源共享'
        },
        {
          img:require('../assets/images/partner/八大支持@2x(1).png'),
          tit:'案例支持',
          word:'超27省，3000+成功案例可供参考'
        },
        {
          img:require('../assets/images/partner/八大支持@2x(2).png'),
          tit:'服务支持',
          word:'完善的客户服务体系'
        },
        {
          img:require('../assets/images/partner/八大支持@2x(3).png'),
          tit:'技术支持',
          word:'专业技术团队提供技术保障'
        },
        {
          img:require('../assets/images/partner/八大支持@2x(4).png'),
          tit:'品牌支持',
          word:'行业领先品牌赋能，快速提升影响力'
        },
        {
          img:require('../assets/images/partner/八大支持@2x(5).png'),
          tit:'市场支持',
          word:'市场商务人员协助洽谈合作事宜'
        },
        {
          img:require('../assets/images/partner/八大支持@2x(6).png'),
          tit:'培训支持',
          word:'全方面免费提供服务商培训支持'
        },
        {
          img:require('../assets/images/partner/八大支持@2x(7).png'),
          tit:'物料支持',
          word:'免费提供全套项目运营和销售物料'
        },
      ],
      superioritys:[
        {
          img:require('../assets/images/partner/独家授权@2x.png'),
          tit:'独家授权，资源共享',
          tip:'锁定重点区域',
          word:'公司通过自有渠道开发市场资源，所有资源免费对接至服务商。所选区域由服务商独家开发，筑美协助促进合作。'
        },
        {
          img:require('../assets/images/partner/全场景应用@2x.png'),
          tit:'产品丰富，全场景应用',
          tip:'标准化产品，个性化定制',
          word:'公司自持研发软硬件产品30余款，涵盖数智化管理软件，导示系统、监管系统等产品，实现标准化交付，个性定制化服务。'
        },
        {
          img:require('../assets/images/partner/图标@2x.png'),
          tit:'政策利好，前景广阔',
          tip:'企业数智升级是刚需',
          word:'政策利好+行业爆发+市场导向+民生支持，加快企业的数智化转型升级，可持续发展。'
        },
        {
          img:require('../assets/images/partner/图标@2x(1).png'),
          tit:'一次开发，长期收益',
          tip:'年利润可达200-500w',
          word:'客户积累越多，后期收益将呈滚雪球式增长（短期软件收益+中期项目收益+长期运维服务收益）。'
        }
      ],
      info: {
        partners: null,
        address: null,
        telphone: null,
        name: null,
        froms: 1,
      },
      areaList: [
        { code: "11", name: "北京市" },
        { code: "12", name: "天津市" },
        { code: "13", name: "河北省" },
        { code: "14", name: "山西省" },
        { code: "15", name: "内蒙古自治区" },
        { code: "21", name: "辽宁省" },
        { code: "22", name: "吉林省" },
        { code: "23", name: "黑龙江省" },
        { code: "31", name: "上海市" },
        { code: "32", name: "江苏省" },
        { code: "33", name: "浙江省" },
        { code: "34", name: "安徽省" },
        { code: "35", name: "福建省" },
        { code: "36", name: "江西省" },
        { code: "37", name: "山东省" },
        { code: "41", name: "河南省" },
        { code: "42", name: "湖北省" },
        { code: "43", name: "湖南省" },
        { code: "44", name: "广东省" },
        { code: "45", name: "广西壮族自治区" },
        { code: "46", name: "海南省" },
        { code: "50", name: "重庆市" },
        { code: "51", name: "四川省" },
        { code: "52", name: "贵州省" },
        { code: "53", name: "云南省" },
        { code: "54", name: "西藏自治区" },
        { code: "61", name: "陕西省" },
        { code: "62", name: "甘肃省" },
        { code: "63", name: "青海省" },
        { code: "64", name: "宁夏回族自治区" },
        { code: "65", name: "新疆维吾尔自治区" },
      ],
      options2: [
        {
          value: 1,
          label: "合作商",
        },
        {
          value: 2,
          label: "业务方",
        },
      ],
    }
  },
  methods: {
    submit() {
      if (!this.info.name) {
        this.$message.error("请输入姓名")
        return
      } else if (!this.info.telphone) {
        this.$message.error("请输入联系方式")
        return
      } else if (!this.info.address) {
        this.$message.error("请选择区域")
        return
      } else if (!this.info.partners) {
        this.$message.error("请选择合作商")
        return
      }
      postConsultingRecords(this.info).then(res => {
        this.$app.$refs.successDialog.show()
      })
    },
  },
}
</script>
<style >
.partner .el-input--small .el-input__inner{

  background: rgba(8,80,211,0.06)!important;


}
</style>
<style scoped lang="less">
.partner{
  width: 100%;
  height: 100vh;
  background: #EDEDED;
  .nav{
    width: 100%;
    height: 556px;
    background-image: url("@/assets/images/partner/图片@2x.png");
    background-size:100% 100% ;
    .nav_bg{
      width: 710px;
      height: 62px;
      margin-top: 160px;
    }

    .word{
      font-size: 43px;
      font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
      font-weight: 400;
      line-height: 92px;
      color: #FFFFFF;

    }
    .applyNow{
      width: 220px;
      height: 64px;
      background: #0850D3;
      border-radius: 10px 10px 10px 10px;
      opacity: 1;
      font-size: 29px;
      font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
      font-weight: 500;
      color: #FFFFFF;
      line-height: 64px;
      text-align: center;
      margin:30px auto 0;
    }
  }
  .main{
    width: 1152px;
    //height: 7783px;
    background: #FFFFFF;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    margin: 0 auto;
    .main_all{
      box-sizing: border-box;
      padding: 16px;
      .numOne{
        .header{
          height: 120px;
          line-height: 120px;
          position: relative;
          img{
            width: 45px;
            height:25px;
            position: absolute;
            left: 42%;
            top:42px
          }
          .tip{
            font-size: 28px;
            font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
            font-weight: bold;
            color: #0850D3;
            line-height: 120px;

          }
        }
        .superiority{
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;

          .superiority_box{
            width:548px;
            height: 323px;
            background-image: url("@/assets/images/partner/box.png");
            background-size:100% 100% ;
            margin: 0 0 15px 0;
            img{
              width: 66px;
              height: 66px;
              margin-top: 15px;
            }
            .characters{
              margin-top: 65px;

              .tip{
                font-size: 16px;
                font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
                font-weight: 400;
                color: #333333;
                line-height: 27px;
              }
              .tit{
                font-size: 18px;
                font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
                font-weight: bold;
                color: #333333;
              }
              .word{
                font-size: 16px;
                font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
                font-weight: 400;
                color: #666666;
                line-height: 27px;
                width: 460px;
                flex-wrap: wrap;
                text-align: center;
                margin: 10px auto 0;
              }
            }
          }
        }
      }
      .numTwo{
        .header{
          height: 120px;
          line-height: 120px;
          position: relative;
          img{
            width: 45px;
            height:25px;
            position: absolute;
            left: 42%;
            top:42px
          }
          .tip{
            font-size: 28px;
            font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
            font-weight: bold;
            color: #0850D3;
            line-height: 120px;

          }
        }
        .supports{
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          .supports_box{
            width: 266px;
            height: 225px;
            background: rgba(8,80,211,0.03);
            border-radius: 16px 16px 16px 16px;
            opacity: 1;
            margin: 0 0 12px 0;
            img{
              width: 50px;
              height: 50px;
              margin-top: 28px;
            }
            .tit{
              font-size: 18px;
              font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
              font-weight: bold;
              color: #333333;
              line-height: 33px;
            }
            .word{
              font-size: 16px;
              font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
              font-weight: 400;
              color: #666666;
              line-height: 27px;
              flex-wrap: wrap;
              width: 177px;
              margin: 8px auto 0;
              text-align: center;
            }
          }
        }
      }
      .numThree{
        .header{
          height: 120px;
          line-height: 120px;
          position: relative;
          img{
            width: 45px;
            height:25px;
            position: absolute;
            left: 34.5%;
            top:42px
          }
          .tip{
            font-size: 28px;
            font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
            font-weight: bold;
            color: #0850D3;
            line-height: 120px;

          }
        }
        .Join{
          .Join_one{
            width: 100%;
            height: 233px;
          }
          .Join_two{
            width: 1006px;
            height: 518px;
            margin-top: 30px;
          }
        }
      }
      .numFour{
        .header{
          height: 120px;
          line-height: 120px;
          position: relative;
          img{
            width: 45px;
            height:25px;
            position: absolute;
            left: 34.5%;
            top:42px
          }
          .tip{
            font-size: 28px;
            font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
            font-weight: bold;
            color: #0850D3;
            line-height: 120px;

          }
        }
        .Join{
          .Join_one{
            width: 100%;
            height: 361px;
          }
        }
      }
      .numFive{
        margin:80px ;
        .header{
          .tip{
            font-size: 28px;
            font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
            font-weight: bold;
            color: #0850D3;
            line-height: 38px;
          }
          .word{
            font-size: 20px;
            font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
            font-weight: 400;
            color: #999999;
            line-height: 27px;
            margin-top: 10px;
          }
        }
        .fiveMain{
          margin: 18px 0;
          .message{
            display: flex;
            justify-content: flex-start;
            width:650px ;
            margin: 0 auto;
            .left{
              font-size: 20px;
              font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
              font-weight: 400;
              color: #333333;
            }
            .right{
              margin-left: 8px;
              width: 594px;
              height: 59px;

              border-radius: 8px 8px 8px 8px;
              .el-input--small .el-input__inner{

                  background: rgba(8,80,211,0.06)!important;


              }
            }
          }
          .caseGet{
            width: 594px;
            height: 52px;
            background: linear-gradient(296deg, #0850D3 0%, #2B98FF 100%);
            border-radius: 16px;
            font-size: 24px;
            font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 52px;
            margin: 20px auto;
            margin-right: 180px;
          }
        }
      }
      .numSix{
        display: flex;
        justify-content:space-around;
        width: 900px;
        margin: 0 auto 80px;
        .phone{
          width: 76px;
          height: 76px;
          margin-bottom: 45px;
        }
        .code{
          width: 120px;
          height: 120px;
        }
        .word{
          font-size: 20px;
          font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
          font-weight: 400;
          color: #666666;
          line-height: 27px;
        }
      }
    }
  }
}
</style>
