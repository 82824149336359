import Vue from "vue"
import VueRouter from "vue-router"
import HomeView from "../views/HomeView.vue"
import About from "../views/About"
import ProductCenter from "@/views/ProductCenterNew"
import ProductPage1 from "@/components/ProductPage1"
import ProductPage2 from "@/components/ProductPage2"
import ProductPage3 from "@/components/ProductPage3"
import ProductPage4 from "@/components/ProductPage4"
import ProductPage5 from "@/components/ProductPage5"
import InformationDetail from "@/components/about/InformationDetail"
import ProductDetail from "@/components/product/ProductDetail"
import CaseInfo from "@/components/case/CaseInfo"
import CasePremium from "@/components/case/CasePremium"
import Cases from "@/views/Cases"
import partner from "@/views/partner"

Vue.use(VueRouter)

export const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/productCenter",
    name: "ProductCenter",
    component: ProductCenter,
  },
  {
    path: "/cases",
    name: "Cases",
    component: Cases,
  },
  {
    path: "/partner",
    name: "partner",
    component: partner,
  },
  {
    path: "/about",
    name: "About",
    component: About,
  },
  {
    path: "/page1",
    name: "ProductPage1",
    component: ProductPage1,
  },
  {
    path: "/page2",
    name: "ProductPage2",
    component: ProductPage2,
  },
  {
    path: "/page3",
    name: "ProductPage3",
    component: ProductPage3,
  },
  {
    path: "/page4",
    name: "ProductPage4",
    component: ProductPage4,
  },
  {
    path: "/page5",
    name: "ProductPage5",
    component: ProductPage5,
  },
  {
    path: "/product",
    name: "ProductDetail",
    component: ProductDetail,
  },
  {
    path: "/information",
    name: "InformationDetail",
    component: InformationDetail,
  },
  {
    path: "/caseInfo",
    name: "CaseInfo",
    component: CaseInfo,
  },
  {
    path: "/caseMobile",
    name: "CasePremium",
    component: CasePremium,
  },
  { path: "*", redirect: "/", hidden: true },
]
const router = new VueRouter({
  routes,
})

export default router
