<template>
  <div class="app">
    <case-detail ref="caseDetail"></case-detail>
  </div>
</template>

<script>
import Wrap from "@/components/common/Wrap"
import CaseDetail from "@/components/case/CaseDetail"
export default {
  name: "CasePremium",
  components: { CaseDetail, Wrap },
  data() {
    return {
      id: null,
    }
  },
  beforeCreate() {
    if (!this.$app.isPc) {
      document.querySelector("body").setAttribute("style", "min-width: initial !important;")
      this.$app.hiddenHeaderAndFooter = true
    }
  },
  created() {
    this.id = this.$route.query.id
    if (!this.id) {
      alert("案例不存在!")
      return
    }
  },
  mounted() {
    this.$refs.caseDetail.refreshInfo(this.id)
  },
}
</script>

<style scoped lang="less">
.app {
  padding: 0 0.3rem;
  background-color: white;
  margin-top: @headerHeight;
}
</style>
