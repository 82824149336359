import request from "@/utils/request"
import da from "element-ui/src/locale/lang/da"

//首页banner
export function getIndexBanner() {
  return request({
    url: `/api.php/Index/getIndexBanner`,
    method: "get",
  })
}

//点击首页banner统计pv,uv
export function getHitsBannerNum(id) {
  return request({
    url: `/api.php/Index/getHitsBannerNum`,
    method: "get",
    params: { id },
  })
}

//首页-底部咨询
export function postConsultingRecords(data) {
  return request({
    url: `/api.php/Common/postConsultingRecords`,
    method: "get",
    params: data,
  })
}

//点击首页banner-文章详情
export function getArticleDetails(article_id) {
  return request({
    url: `/api.php/index/getArticleDetails`,
    method: "get",
    params: { article_id },
  })
}
