<template>
  <div class="banner">
    <!-- Swiper -->
    <swiper class="swiper" ref="mySwiper" :options="swiperOption">
      <swiper-slide v-for="item in bannerList" :key="item.id">
        <img :src="item.img" class="img" alt="" />
        <wrap class="container">
          <div class="title">{{ item.title }}</div>
          <div class="sub-title">{{ item.subtitle }}</div>
          <div class="sub2">{{ item.describe }}</div>
          <a class="learn-more" @click="addHitsBannerNum(item)">
            进一步了解
            <img src="@/assets/images/enter_white.png" alt="" />
          </a>
          <div class="play-item" :class="item.video_status == 1 ? 'cursor' : ''" @click.stop="showVideo(item)">
            <img v-if="item.video_status == 1" src="../assets/images/play.png" alt="" />
          </div>
        </wrap>
      </swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
  </div>
</template>
<script>
import { getIndexBanner, getHitsBannerNum } from "@/api/home"
import Wrap from "@/components/common/Wrap"
let vm = null
export default {
  name: "Header",
  components: { Wrap },
  data() {
    return {
      bannerList: [],
      swiperOption: {
        effect: "fade",
        fadeEffect: {
          crossFade: true,
        },
        // loop: true,
        delay: 5000,
        speed: 2000,
        autoplay: true,
        dynamicBullets: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        // on: {
        //   click: function () {
        //     vm.addHitsBannerNum(this.clickedIndex)
        //   },
        // },
      },
    }
  },
  computed: {},
  created() {
    vm = this
    this.getIndexBanner()
  },
  mounted() {},
  methods: {
    getIndexBanner() {
      getIndexBanner().then(res => {
        this.bannerList = res.data
      })
    },
    addHitsBannerNum(obj) {
      getHitsBannerNum(obj.id).then(res => {})
      if (obj.type === 1) {
        //url
        window.location.href = obj.url
      } else {
        //文章库
        this.$router.push({
          name: "InformationDetail",
          query: {
            id: obj.url,
          },
        })
      }
    },
    showVideo(e) {
      if (e.video_status == 1) {
        this.$app.$refs.videoDialog.show({
          url: e.video,
        })
      }
    },
  },
}
</script>
<style scoped lang="less">
.banner {
  position: relative;
  //background: #ffffff;
  overflow: hidden;
  width: 100%;
  aspect-ratio: 2.6667;

  @media screen and (max-width: 1500px) {
    .title {
      font-size: 0.3rem !important;
    }
    .sub-title {
      font-size: 0.46rem !important;
    }
    .sub2 {
      font-size: 0.31rem !important;
    }
  }
  .container {
    //mark 2022-12-15 15:16:17
    //padding: 0 0 1.1rem 25vw;
    padding-bottom: 1rem;
    position: absolute;
    left: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    @media screen and (max-width: 1500px) {
      padding-bottom: 0.5rem;
    }
  }

  .img {
    //position: absolute;
    top: 0;
    z-index: -9;
    width: 100%;
    aspect-ratio: 2.6667;
    pointer-events: none;
  }

  .title {
    font-size: 0.36rem;
    font-weight: 400;
    color: #ffffff;
    line-height: 0.5rem;
  }

  .sub-title {
    margin-bottom: 0.14rem;
    font-size: 0.54rem;
    font-weight: 600;
    color: #ffffff;
    line-height: 0.75rem;
  }

  .sub2 {
    margin-bottom: 0.15rem;
    font-size: 0.38rem;
    font-weight: 500;
    color: #ffffff;
    line-height: 0.53rem;
  }

  .play-item {
    margin-left: 0.5rem;
    width: 0.64rem;
    height: 0.64rem;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .cursor {
    cursor: pointer;
  }
  .learn-more {
    margin-bottom: 0.2rem;
    width: 1.8rem;
    height: 0.42rem;
    color: #ffffff;
    text-align: center;
    cursor: pointer;
    font-size: 0.14rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(to left, #0850d3 50%, #00389d 50%) right;
    background-size: 200%;
    transition: 0.5s ease-out;

    &:hover {
      background-position: left;
    }

    a {
      width: 1.97rem;
      height: 0.37rem;
      font-size: 0.14rem;
      color: #ffffff;
      text-align: center;
      background: #0850d3;
    }

    img {
      display: inline-block;
      margin-left: 0.04rem;
      height: 0.13rem;
    }
  }
  .swiper {
    height: 100%;
  }
}
.swiper-slide-active img,
.swiper-slide-duplicate-active img {
  transition: 5s linear;
  transform: scale(1.1);
}
::v-deep .swiper-pagination-bullet {
  width: 0.55rem;
  height: 0.05rem;
  border-radius: 1px;
  background-color: #ffffff;
  background: linear-gradient(to left, #ffffff 50%, #8e9aae 50%) right;
  background-size: 200%;
  opacity: 1;
}
::v-deep .swiper-pagination-bullet-active {
  background-position: left;
  transition: 5s ease-out;
}
</style>
