<template>
  <div id="app" class="banner">
    <img fit="cover" :src="img" class="img" alt="" />
    <Wrap>
      <div class="container">
        <div class="title">{{ title }}</div>
        <div class="sub-title">{{ subTitle }}</div>
      </div>
    </Wrap>
  </div>
</template>

<script>
import Wrap from "@/components/common/Wrap"
export default {
  name: "BannerOne",
  components: { Wrap },
  props: {
    img: "",
    title: "",
    subTitle: "",
  },
}
</script>

<style scoped lang="less">
.banner {
  position: relative;
  width: 100%;

  z-index: 1;

  .container {
    z-index: 2;
    height: 100%;
    position: absolute;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    color: #ffffff;
    //margin-left: 0.77rem;
    //.title {
    //  font-size: 0.4rem;
    //  font-weight: 600;
    //}
    //.sub-title {
    //  font-size: 0.34rem;
    //  margin-bottom: 0.9rem;
    //}
    .title {
      font-size: 0.54rem;

      font-weight: 400;
      color: #ffffff;
      line-height: 0.5rem;
    }

    .sub-title {
      margin-bottom: 2rem;
      font-size: 0.36rem;
      font-weight: 600;
      color: #ffffff;
      line-height: 0.75rem;
    }
    @media screen and (max-width: 1500px) {
      .title {
        font-size: 0.46rem !important;
      }
      .sub-title {
        font-size: 0.3rem !important;
      }
    }
  }

  .img {
    //position: absolute;
    top: 0;
    z-index: -9;
    width: 100%;
    display: block;
    object-fit: cover;
    //aspect-ratio: 3.8;
    aspect-ratio: 2.6667;

    @supports not (aspect-ratio: 2.6667) {
      height: 7.2rem;
    }
    //@supports not (aspect-ratio: 3.8) {
    //  height: 5.1rem;
    //}
  }
}
</style>
