<template>
  <div class="index_footer">
    <wrap class="main_wrap">
      <div class="logo-item">
        <img :src="zmLogo" class="zm-logo" />
        <div class="zm-address">
          <span style="margin-right: 15px">中国 · 浙江省杭州市萧山区振宁路786号</span>
          <span style="margin-right: 15px">咨询：0571- 8276 7731</span>
          <span>邮箱：hr@zhumei.net</span>
        </div>
      </div>
      <div class="qr-item">
        <div class="column_wrap">
          <div class="title">全国服务热线</div>
          <div class="item">400-0086-522</div>
        </div>
        <div class="logo_wrap">
          <img class="qrcode" :src="qrcodeSrc" />
        </div>
      </div>
    </wrap>
    <wrap class="filings_wrap">
      <div class="content">
        <div class="label">
          Copyright © 2012-2022 Zhumei Incorporated.All right reserved
          <a class="item" href="https://beian.miit.gov.cn/" target="_blank">浙ICP备 ICP备12013633号-1</a>
          <span v-if="$app.isPc">&nbsp;杭州筑美信息科技有限公司 版权所有</span>
        </div>
      </div>
    </wrap>
  </div>
</template>

<script>
import Wrap from "./Wrap"

export default {
  name: "IndexFooter",
  components: { Wrap },
  data: () => ({}),
  computed: {
    qrcodeSrc() {
      return require("../../assets/images/zm_qrcode.png")
    },
    zmLogo() {
      return require("../../assets/images/zm_logo.png")
    },
  },
}
</script>

<style lang="less" scoped>
.index_footer {
  width: 100%;
  background: #242933;
  .main_wrap {
    padding: 0 0.25rem;
    height: 2rem;
    background: url("../../assets/images/bottom_bg.png") no-repeat;
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    user-select: text;
    &.white_bg {
      background: white;
    }
    .logo_wrap {
      margin-left: 0.2rem;
      text-align: right;
      display: flex;
      flex-direction: column;
      user-select: none;
      .logo {
        width: 1.56rem;
      }
      .qrcode {
        width: 0.9rem;
        height: 0.9rem;
      }
    }
    .qr-item {
      display: flex;
      align-items: flex-end;
    }
    .column_wrap {
      display: flex;
      flex-direction: column;
      &.no_select {
        user-select: none;
      }
      .title {
        text-align: right;
        line-height: 0.2rem;
        font-size: 0.18rem;
        color: #ffffff;
        font-weight: bold;
      }
      .item {
        margin-top: 0.04rem;
        line-height: 0.2rem;
        font-size: 0.24rem;
        color: #ffffff;
        white-space: nowrap;
        &.nav {
          cursor: pointer;
          &:hover {
            color: @themeBlue;
          }
        }
      }
      .line {
        max-width: 3.1rem;
        margin-top: 0.3rem;
        display: flex;
        line-height: 0.28rem;
        font-size: 0.2rem;
        color: #333;
        span {
          flex-shrink: 0;
        }
      }
    }
  }
  .filings_wrap {
    background: #242933;
    user-select: text;
    .content {
      height: 0.28rem;
      margin-bottom: calc(env(safe-area-inset-bottom) / 2);
      font-size: 0.08rem;
      background-color: #292929;
      display: flex;
      align-items: center;
      justify-content: center;
      .label {
        color: #999999;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
      .item {
        color: #999999;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
.logo-item {
  height: 1.06rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-align: left;
  .zm-logo {
    width: 1.6rem;
    //height: 0.46rem;
  }
  .zm-address {
    font-size: 0.13rem;
    color: #ffffff;
    @media screen and (max-width: 800px) {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }
}
</style>
