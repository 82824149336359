<template>
  <div id="app">
    <banner-one :img="require('../assets/images/product/np_3.png')" title="未来城市数智化农产品集配中心" sub-title="农贸农批一体化"></banner-one>
    <wrap class="main-warp">
      <div class="warp-div">
        <img class="img1" src="../assets/images/product/np_1.png" />
        <div style="margin-top: 0.5rem">
          <div class="top-text">围绕数智管理、采集、应用、服务等系统，通过科技、信息化手段实现平台搭建，</div>
          <div class="top-text">帮助市场与商户、商户与采购商、批发商搭建沟通桥梁，打造 “农产品批发市场运营服务平台新模式</div>
        </div>
        <blue-title-center title="一体化平台" sub-title="信息整合、数智赋能，多方协同、可视管理"></blue-title-center>
        <div class="img-container">
          <img src="../assets/images/product/np_15.png" />
        </div>
        <blue-title-center title="应用场景" sub-title="重新绘制“人、货、物、钱”四者关系，合理配置、促进高效"></blue-title-center>
        <div class="scene">
          <div class="item">
            <img src="../assets/images/product/np_10.png" alt="" />
            <div>人：智能 + 协同</div>
          </div>
          <div class="item">
            <img src="../assets/images/product/np_11.png" alt="" />
            <div>场：智能 + 高效</div>
          </div>
          <div class="item">
            <img src="../assets/images/product/np_13.png" alt="" />
            <div>货：智能 + 物流 + 仓储</div>
          </div>
          <div class="item">
            <img src="../assets/images/product/np_12.png" alt="" />
            <div>钱：智能 + 增收 + 金融</div>
          </div>
        </div>
        <blue-title-center title="应用板块" sub-title="数智赋能软硬件、六大创新解难点、高效管理助转型"></blue-title-center>
      </div>
    </wrap>
    <div class="function-items">
      <div class="item">
        <div class="title">智慧管理</div>
        <div class="sub-title">Wisdom management</div>
       <!--        <div class="more">了解更多</div>-->
      </div>
      <div class="item">
        <div class="title">进场预约</div>
        <div class="sub-title">In the booking</div>
       <!--        <div class="more">了解更多</div>-->
      </div>
      <div class="item">
        <div class="title">智能收费</div>
        <div class="sub-title">Intelligent charging</div>
       <!--        <div class="more">了解更多</div>-->
      </div>
      <div class="item">
        <div class="title">供应链管理</div>
        <div class="sub-title">Supply chain management</div>
       <!--        <div class="more">了解更多</div>-->
      </div>
      <div class="item">
        <div class="title">数据检测</div>
        <div class="sub-title">Data detection</div>
       <!--        <div class="more">了解更多</div>-->
      </div>
      <div class="item">
        <div class="title">一体化平台</div>
        <div class="sub-title">Integrated platform</div>
       <!--        <div class="more">了解更多</div>-->
      </div>
    </div>
    <wrap class="main-warp">
      <div class="warp-div">
        <div class="img3-container">
          <img src="../assets/images/product/np_16.png" />
        </div>
        <blue-title-center title="平台架构" sub-title="以一云多端为核心，赋能新模式，引领数字化新变革"></blue-title-center>
        <div class="img-container">
          <img src="../assets/images/product/np_2.png" />
        </div>
        <blue-title-center title="应用系统" sub-title="全方位覆盖、数据云集成，一站式服务、高效化运营"></blue-title-center>
        <utility-system></utility-system>
        <blue-title-center title="应用产品" sub-title="搭建平台、高效管理，创新系统、数智运营"></blue-title-center>
        <application :list="appList"></application>
        <!--        <img class="img4" src="../assets/images/product/4.png" />-->
        <blue-sub-title title="专属服务 Partners"></blue-sub-title>
        <div class="img5-container">
          <img src="../assets/images/product/np_17.png" />
        </div>

        <blue-title-center title="解决方案" id="solution" sub-title="赋能市场管理新模式，打通“城市一体化智慧运营服务”开启数字化新变革"></blue-title-center>
        <product-solution :id="4"></product-solution>
        <blue-title-center title="核心优势" sub-title="开拓数智建设+智慧物联+市场运营+数据服务的业务体系先河，构建专业的数智化运营管理平台"></blue-title-center>
      </div>
    </wrap>
    <advantage :page="2"></advantage>
    <classic-case></classic-case>
    <contactus></contactus>
  </div>
</template>

<script>
import BannerOne from "@/components/BannerOne"
import Wrap from "@/components/common/Wrap"
import BlueTitleCenter from "@/components/BlueTitleCenter"
import UtilitySystem from "@/components/UtilitySystem"
import BlueSubTitle from "@/components/BlueSubTitle"
import ClassicCase from "@/components/ClassicCase"
import Advantage from "@/components/Advantage"
import ProductSolution from "@/components/ProductSolution"
import Partners from "@/components/Partners"
import Contactus from "@/components/Contactus"
import Application from "@/components/product/Application"
//农批
export default {
  name: "ProductPage2",
  components: { Application, Contactus, Partners, ProductSolution, Advantage, ClassicCase, BlueSubTitle, UtilitySystem, BlueTitleCenter, Wrap, BannerOne },
  data() {
    return {
      appList: [4, 14, 6, 7, 8, 9, 5],
    }
  },
  mounted() {
    if (this.$route.params.isShowSolution) {
      setTimeout(() => {
        document.getElementById("solution").scrollIntoView()
      }, 100)
    }
  },
}
</script>

<style scoped lang="less">
.main-warp {
  display: flex;
  flex-direction: column;
  //background-color: #ffffff;
  align-items: center;
  .warp-div {
    background-color: #ffffff;
    width: 100%;
  }
}
.img1 {
  width: 100%;
  //height: 4.6rem;
  margin-top: 0.29rem;
}
.top-text {
  width: 100%;
  background-color: #ffffff;
  font-size: 0.14rem;

  font-weight: 400;
  color: #303030;
  line-height: 0.22rem;
}
.img3-container {
  padding: 0 0.77rem;
  margin-top: 0.68rem;
  margin-bottom: 0.1rem;
  img {
    width: 100%;
  }
}
.function-items {
  width: 100%;
  height: 4.23rem;
  background-color: #0b60d6;
  color: #ffffff;

  display: grid;
  grid-template-columns: repeat(3, 3.13rem);
  grid-gap: 0.11rem 0.11rem;
  justify-content: center;
  align-content: center;
  .item {
    //width: 3.24rem;
    height: 1.69rem;
    background: #0572fa;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .title {
      font-size: 0.18rem;
    }
    .sub-title {
      font-size: 0.12rem;
      margin-bottom: 0.05rem;
    }
    .more {
      width: 0.81rem;
      height: 0.25rem;
      background: #1caff7;
      font-size: 0.12rem;
      line-height: 0.25rem;
    }
  }
}
.scene {
  margin: 0 0.77rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
  grid-gap: 0.05rem;
  .item {
    font-size: 0.15rem;
    font-weight: 400;
    color: #696969;
    > img {
      width: 100%;
      //height: 2.53rem;
    }
  }
}
.img-container {
  margin: 0 0.77rem;
  img {
    width: 100%;
  }
}
.img5-container {
  padding: 0 0.77rem;
  img {
    width: 100%;
    margin-left: -0.14rem;
  }
}
</style>
