<template>
  <div class="container">
    <div class="title">
      {{ title }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: "",
    subTitle: "",
  },
}
</script>

<style scoped lang="less">
.container {
  background-color: #ffffff;
  margin-top: 0.6rem;
  padding: 0.3rem 0.78rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  .title {
    display: block;
    text-align: center;
    font-weight: 500;
    font-size: .28rem;
    color: #00215E;
  }
}
</style>
