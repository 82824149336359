<template>
  <Wrap>
    <div class="classicCase">
      <div class="container" v-if="isShowCaseItems">
        <div>
          <div class="circle">
            10+
            <div>年</div>
          </div>
          <div class="text">应用系统</div>
        </div>
        <div>
          <div class="circle">
            500 +
            <div>家</div>
          </div>
          <div class="text">服务市场</div>
        </div>
        <div>
          <div class="circle">
            600000+
            <div>位</div>
          </div>
          <div class="text">终端用户</div>
        </div>
        <div>
          <div class="circle">
            60+
            <div>项</div>
          </div>
          <div class="text">专利</div>
        </div>
        <div>
          <div class="circle">
            100+
            <div>城市</div>
          </div>
          <div class="text">服务网络</div>
        </div>
        <div>
          <div class="circle">
            80+
            <div>套</div>
          </div>
          <div class="text">应用系统</div>
        </div>
      </div>
    </div>
    <div class="caseContent">
      <div class="container" >
        <blue-sub-title title="经典案例"></blue-sub-title>
      </div>
      <div class="list">
        <div class="listCard" v-for="item in caseList" @click="clickItem(item)">
          <div class="list-img">
            <img :src="item.img" alt="" />
          </div>
          <div class="title">
            {{ item.title }}
          </div>
          <div class="subTitle">
            {{ item.subtitle }}
          </div>
        </div>
      </div>
      <div class="serve_main" v-if="serveShow">
        <div class="serve_item">
          <img src="@/assets/images/building-2-line.png"/>
          <div>服务城市100+</div>
        </div>
        <div class="serve_item">
          <img src="@/assets/images/community-line.png"/>
          <div>服务市场500+</div>
        </div>
        <div class="serve_item">
          <img src="@/assets/images/user-heart-line.png"/>
          <div>服务客户2000+</div>
        </div>
        <div class="serve_item">
          <img src="@/assets/images/apps-line.png"/>
          <div>服务终端60000+</div>
        </div>
      </div>

      <div class="flex-more more" v-if="this.$route.path === '/'">
        <a @click="consultation()">
          我要咨询
          <img src="@/assets/images/enter_white.png" alt="" />
        </a>
        <a @click="pathUrl()">
          更多案例
          <img src="@/assets/images/enter_blue_right.png" alt="" />
        </a>
<!--        <span  @click="pathUrl">-->
<!--          更多案例-->
<!--           <img src="@/assets/images/enter_white.png" />-->
<!--        </span>-->
      </div>
      <div class="paging-box" v-else>
        <pagination v-show="total>0" :total="total" :page.sync="parameter.current_page" :limit.sync="parameter.per_page"
                    @pagination="getMoreCommonExamplesList()" />
      </div>
    </div>

  </Wrap>
</template>
<script>
import Wrap from "@/components/common/Wrap"
import BlueSubTitle from "@/components/BlueSubTitle"
import { getMoreCommonExamplesList } from "@/api/case"
import pagination from "@/components/Pagination/index.vue"
export default {
  props: {
    isShowCaseItems: false,
    serveShow: {
      type: Boolean,
      default: false
    }
  },
  components: { BlueSubTitle, Wrap, pagination },
  data() {
    return {
      caseList: [],
      haveMore: true,
      parameter:{
        current_page: 1,
        per_page: 12,
      },
      total: 0
    }
  },
  created() {
    this.getMoreCommonExamplesList()
  },
  methods: {
    getMoreCommonExamplesList() {
      getMoreCommonExamplesList(this.parameter).then(res => {
        this.caseList = res.data.data
        this.total = res.data.total
      })
    },
    clickItem(e) {
      this.$router.push({
        name: "CaseInfo",
        query: {
          id: e.id,
        },
      })
    },
    pathUrl() {
      this.$router.push({
        path: "/cases",
      })
    },
    consultation() {
      console.log(111)
      this.$emit('consulT')
    }
  },
}
</script>
<style lang="less" scoped>
// 经典案例
.classicCase {
  //height: 3.5rem;
  color: #383838;

  .container {
    padding: 0.6rem 0.7rem 0 0.7rem;
    background: #ffffff;
    //display: flex;
    //justify-content: space-evenly;
    //text-align: center;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 0.4rem;

    .circle {
      width: 100%;
      aspect-ratio: 1;
      background: #ededed;
      border-radius: 50%;
      font-size: 0.22rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      line-height: 0.31rem;
      @supports not (aspect-ratio: 1) {
        height: 1.03rem;
      }

      div {
        font-size: 0.17rem;
      }
    }

    .text {
      font-size: 0.15rem;
      margin-top: 0.06rem;
    }
  }
}

.caseContent {
  // height: 8.2500rem;
  padding: 0 0.2rem;
  background: #ffffff;

  .container {
    padding-top: 0.13rem;
    background: #ffffff;
    text-align: center;
  }

  .list {
    margin: 0 auto;
    //width: 11.52rem;
    //padding: 0.16rem 0.66rem 0 0.76rem;
    background: #ffffff;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 0.1rem;
    .listCard {
      //width: 3.26rem;
      //height: 2.12rem;
      //margin: 0.04rem;
      margin-bottom: 0.1rem;
      padding-bottom: 0.1rem;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      text-align: left;

      .list-img {
        width: 100%;
        aspect-ratio: 1.95;
        @supports not (aspect-ratio: 1.95) {
          height: 1.3rem;
        }
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }

      &:hover {
        cursor: pointer;
        background: #007efc;
        img {
          transition: 1s;
          transform: scale(1.1);
        }
        .title {
          color: #ffffff;
        }
        .subTitle {
          color: #ffffff;
        }
      }
      .title {
        color: #000000;
        font-size: 0.15rem;
        line-height: 0.2rem;
        margin-top: 0.08rem;
        margin-bottom: 0.02rem;
        padding-left: 0.2rem;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }

      .subTitle {
        font-size: 0.12rem;
        color: #969696;
        line-height: 0.18rem;
        padding-left: 0.2rem;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
    }
  }

  .moreCase {
    //width: 11.52rem;
    margin: 0 auto;
    height: 0.45rem;
    width: 1.5rem;
    background: #0B36C9;
    font-size: 0.16rem;

    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      display: inline-block;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 0.15rem;
        height: 0.15rem;
        margin-left: 0.04rem;
      }
    }
  }

  .serve_main {
    height: 1.23rem;
    display: grid;
    grid-template-columns: repeat(4,1fr);
    grid-column-gap: .16rem;
    margin: .2rem 0;
    background: rgba(54,98,236,0.06);
    .serve_item {
      img {
        margin-top: .24rem;
        width: .4rem;
        height: .4rem;
      }
      div {
        font-size: 18px;
        font-weight: 500;
        color: #00215E;
      }
    }
  }
}
.flex-more {
  display: flex;
  align-items: center;
  justify-content: center;
}
.more {
  margin-top: 0.20rem;
  font-size: 0.14rem;
  a {
    width: 1.97rem;
    height: 0.37rem;
    font-size: .14rem;
    color: #FFFFFF;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    background: linear-gradient(to left, #0850D3 50%, #00389d 50%) right;
    background-size: 200%;
    transition: .5s ease-out;

    &:hover {
      background-position: left;
    }

    img {
      display: inline-block;
      margin-left: 0.04rem;
      height: .13rem;
      //vertical-align: middle;
    }
  }

  a:nth-child(2) {
    color: #0850D3;
    background: linear-gradient(to left, #FDCD03 50%, #ffb100 50%) right;
    background-size: 200%;
    &:hover {
      background-position: left;
    }
  }
}
.paging-box {
  height: 80px;
}
</style>
