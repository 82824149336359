<template>
  <el-dialog title="立即申请" :visible.sync="isShow" width="14.65rem">
    <div class="main-container">
      <div class="container">
        <div class="c-title">·{{ detail.title }}</div>
        <swiper class="swiper" :options="swiperOption">
          <swiper-slide v-for="item in detail.images">
            <img class="slide-img" :src="item" alt="" />
          </swiper-slide>
          <div class="swiper-button-prev swiper-button-style" slot="button-prev"></div>
          <div class="swiper-button-next swiper-button-style" slot="button-next"></div>
        </swiper>
        <div class="operation">
          <div class="operation-left">{{ detail.content }}</div>
          <div class="operation-right">
            <div class="or-top">
              <img :class="isShowAddAnimation ? 'addAnimation' : ''" src="../../assets/images/download_yellow_small.png" alt="" />
              <div class="or-title">获取资料</div>
              <div class="or-sub">
                <div class="ors-main">仅供个人学习研究，不可商用。</div>
                <div class="ors-sub">1024 x 670px (10.84 x 7.09cm) 240dpi | TIFF 1.96 MB</div>
                <div class="ors-category">
                  <div>PPT</div>
                  <div>WORD</div>
                  <div>PDF</div>
                  <div>视频</div>
                  <div>图片</div>
                </div>
              </div>
            </div>
            <div class="next-btn" @click="addToCart">立即获取</div>
            <div class="call-div">
              如需询价购买请拨打
              <a href="tel://400-0068-552">400-0068-552</a>
              或咨询在线客服。
            </div>
          </div>
        </div>
      </div>
    </div>
<!--    <div class="cart">-->
<!--      <div class="cart-container" @click="toNext">-->
<!--        <img src="../../assets/images/download_yellow_big.png" alt="" />-->
<!--        <div class="count" v-if="countNum > 0">{{ countNum }}</div>-->
<!--      </div>-->
<!--    </div>-->
  </el-dialog>
</template>

<script>
import { getProductConsultingDetails } from "@/api/product"
import { getProgrammesDetails } from "@/api/solution"

export default {
  name: "ApplyDialog",
  components: {},
  data() {
    return {
      detail: {},
      isShow: false,
      isShowAddAnimation: false,
      swiperOption: {
        slidesPerView: 2,
        spaceBetween: 8,
        slidesPerGroup: 2,
        loop: true,
        loopFillGroupWithBlank: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      countNum: 0,
      cartList1: [],
      cartList2: [],
      goodsInfo: null,
      isSolution: false,
    }
  },
  methods: {
    show(e, isSolution) {
      this.goodsInfo = e
      this.isSolution = isSolution || false
      if (isSolution) {
        this.countNum = this.cartList2.length
        getProgrammesDetails(e.id).then(res => {
          this.detail = res.data
          this.isShow = true
        })
      } else {
        this.countNum = this.cartList1.length
        getProductConsultingDetails(e.id).then(res => {
          this.detail = res.data
          this.isShow = true
        })
      }
    },
    hide() {
      this.isShow = false
    },
    toNext() {
      if (this.isSolution) {
        if (this.cartList2.length === 0) {
          alert("请先添加再试")
          return
        }
        this.hide()
        this.$app.$refs.contactDialog.show(this.cartList2, this.isSolution)
      } else {
        if (this.cartList1.length === 0) {
          alert("请先添加再试")
          return
        }
        this.hide()
        this.$app.$refs.contactDialog.show(this.cartList1, this.isSolution)
      }
    },
    addToCart() {
   //  this.isShowAddAnimation = true
   //    setTimeout(() => {
   //
   //    }, 100)
      this.isShowAddAnimation = false
      this.countNum++
      if (this.isSolution) {
        this.cartList2.push(this.goodsInfo)
        this.toNext()
      } else {
        this.cartList1.push(this.goodsInfo)
        this.toNext()
      }
    },
  },
}
</script>

<style scoped lang="less">
@import "../../assets/styles/dialog.less";
.container {
  .c-title {
    text-align: left;
    font-size: 0.25rem;

    font-weight: 500;
    color: #0647b9;
  }
  .swiper {
    padding: 0.08rem 0;
    height: 3.18rem;
    .slide-img {
      width: 100%;
      height: 100%;
      //object-fit: cover;
    }
    .swiper-button-style {
      color: white;
      background-color: #000712;
      opacity: 0.62;
    }
    .swiper-button-prev {
      left: 0;
      width: 0.24rem;
      height: 0.49rem;
    }
    .swiper-button-next {
      right: 0;
      width: 0.24rem;
      height: 0.49rem;
    }
    .swiper-button-prev:after,
    .swiper-button-next:after {
      font-size: initial;
    }
  }
  .operation {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 8px;
    .operation-left {
      text-align: justify;
      margin-top: 0.29rem;
      font-size: 0.14rem;

      font-weight: 400;
      color: #303030;
      width: 80%;
    }
    .operation-right {
      .or-top {
        display: flex;
        margin-top: 0.3rem;
        width: 80%;
        img {
          width: 0.24rem;
          height: 0.25rem;
        }
        .or-title {
          font-size: 0.2rem;

          font-weight: 500;
          color: #0647b9;
          line-height: 0.25rem;
          margin: 0 0.1rem;
          flex-shrink: 0;
        }
        .or-sub {
          font-size: 0.14rem;

          font-weight: 400;
          color: #303030;
          text-align: justify;
          .ors-main {
            font-weight: bold;
          }
          .ors-category {
            font-size: 0.13rem;
            font-weight: 500;
            color: #1080ef;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 0.08rem;
          }
        }
      }
      .next-btn {
        width: 100%;
        height: 0.39rem;
        line-height: 0.39rem;
        background: #f84949;
        font-size: 0.2rem;

        font-weight: 500;
        color: #ffffff;
        text-align: center;
        margin-top: 0.23rem;
        margin-bottom: 0.08rem;
        cursor: pointer;
      }
      /* 动画效果实现 */
      .addAnimation {
        animation: bounce-in 1s ease-in-out;
      }
      @keyframes bounce-in {
        0% {
          transform: scale(1) translate(0px, 0px);
        }
        100% {
          transform: scale(0.6) translate(-11.5rem, 3.55rem);
        }
      }
      .call-div {
        font-size: 0.14rem;

        font-weight: 400;
        color: #303030;
      }
    }
  }
}
.cart {
  height: 0.7rem;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;

  .cart-container {
    margin-left: 0.08rem;
    margin-bottom: 0.1rem;
    width: 0.5rem;
    height: 0.5rem;
    cursor: pointer;
    position: relative;
    img {
      width: 0.5rem;
      height: 0.5rem;
    }
    .count {
      position: absolute;
      width: 0.2rem;
      height: 0.2rem;
      background-color: red;
      right: -0.1rem;
      top: -0.1rem;
      border-radius: 0.1rem;
      color: white;
      font-size: 0.12rem;
    }
  }
}
</style>
