<template>
  <Wrap>
    <div class="box">
      <div class="container">
        <span class="caseTitle">合作伙伴</span>
      </div>
      <div class="partners">
        <img src="@/assets/images/logo_photo.png" alt="" />
      </div>
    </div>
  </Wrap>
</template>
<script>
import Wrap from "@/components/common/Wrap"
export default {
  components: { Wrap },
  data() {
    return {}
  },
}
</script>
<style lang="less" scoped>
.box {
  //width: 11.52rem;
  margin: 0 auto;
  padding-bottom: 1rem;

  background: #ffffff;

  .container {
    padding: 0.3rem 0;
    text-align: center;

    .caseTitle {
      display: block;
      height: .7rem;
      text-align: center;
      font-weight: 500;
      font-size: .28rem;
      color: #00215E;
      padding-top: .4rem;
    }
  }
  .partners {
    //height: 3.25rem;
    // background: red;
    img {
      width: 86.6%;
      //height: 100%;
      // padding: 0 0.7700rem;
      margin: 0 auto;
    }
  }
}
</style>
