<template>
  <el-dialog :title="title" :visible.sync="isShow" width="60vw" @closed="hide" destroy-on-close>
    <video-player ref="mediaPlayer" :options="playerOptions"></video-player>
  </el-dialog>
</template>

<script>
export default {
  name: "VideoDialog",
  data() {
    return {
      isShow: false,
      url: null,
      cover: null,
      title: null,
      playerOptions: {
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [
          {
            type: "video/mp4",
            src: this.url,
          },
        ],
        autoplay: true,
        poster: this.cover,
      },
    }
  },
  methods: {
    show(e) {
      this.title = e.title || "筑美科技"
      this.playerOptions.poster = e.cover || ""
      this.playerOptions.sources[0].src = e.url
      this.isShow = true
    },
    hide() {
      this.isShow = false
      this.playerOptions = {
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [
          {
            type: "video/mp4",
            src: "",
          },
        ],
        autoplay: true,
        poster: "",
      }
    },
  },
}
</script>

<style scoped lang="less">
@import "../../assets/styles/dialog.less";
::v-deep .video-js {
  width: 100%;
  aspect-ratio: 2.2;
  height: auto;
  //height: 5.22rem;
}
</style>
