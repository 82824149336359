<template>
  <div id="app">
    <div class="items" v-for="item in list" :key="item.id" @click="clickItem(item)">
      <img class="arrow" src="../assets/images/product/arrows_right_blue.png" alt="" />
      <div>• {{ item.title }}</div>
      <img class="arrow-after" src="../assets/images/enter_blue_right.png" alt="" />
    </div>
  </div>
</template>

<script>
import { getProgrammesListDetails } from "@/api/solution"

export default {
  name: "ProductSolution",
  props: {
    id: null,
  },
  data() {
    return {
      list: [],
    }
  },
  created() {
    this.getDetails()
  },
  methods: {
    getDetails() {
      getProgrammesListDetails(this.id).then(res => {
        this.list = res.data
      })
    },
    clickItem(e) {
      this.$app.$refs.applyDialog.show(e, true)
    },
  },
}
</script>

<style scoped lang="less">
#app {
  margin-left: 1.5rem;
  width: initial;
  background-color: #ffffff;
  display: grid;
  grid-template-columns: repeat(3, 2.9rem);
  justify-content: center;
  .items {
    cursor: pointer;
    font-size: 0.17rem;
    margin-top: 0.06rem;

    font-weight: 400;
    color: #1b1e3c;
    display: flex;
    align-items: center;
    position: relative;
    :hover {
      color: #0b60d6;
    }
    .arrow {
      position: absolute;
      left: -0.2rem;
      display: none;
      height: 0.1rem;
    }
    .arrow-after {
      margin-left: 0.05rem;
    }
  }
  .items:hover img {
    display: block;
  }
}
</style>
