<template>
  <div id="app">
    <BannerOne :img="bannerData.img" :title="bannerData.title" :sub-title="bannerData.subtitle"></BannerOne>
    <wrap>
      <div class="warp-div">
        <blue-title class="my-blue" title="案例" en-title="Cases"></blue-title>
        <div class="top-items">
          <div class="item" :class="topSelected === index ? 'hover' : ''" v-for="(item, index) in topItems" @click="clickTopItems(item, index)">{{ item.name }}</div>
        </div>
        <div class="sub-view">
          <div class="title">经典案例：</div>
          <div class="sub-items">
            <div class="item" :class="subSelected === index ? 'hover' : ''" v-for="(item, index) in subItems" @click="clickSubItems(item, index)">{{ item.title }}</div>
          </div>
        </div>
        <div class="case-imgs" v-if="caseImgs.length > 0">
          <img :src="i" alt="" v-for="i in caseImgs" />
        </div>
        <div class="expand" v-show="!isShowDetail && caseImgs.length > 0" @click="expandMore">
          <div>展开</div>
          <img src="../assets/images/case/case_16.png" alt="" />
        </div>
        <case-detail ref="caseDetail" v-if="isShowDetail"></case-detail>
        <div class="expand" v-show="isShowDetail" @click="expandMore">
          <img src="../assets/images/case/case_18.png" alt="" />
          <div>收起</div>
        </div>
        <blue-title class="my-blue2" title="更多案例" en-title="More cases"></blue-title>
      </div>
    </wrap>
    <classic-case class="classic-case" id="more-cases"></classic-case>
  </div>
</template>

<script>
import BlueTitle from "@/components/BlueTitle"
import BannerOne from "@/components/BannerOne"
import Wrap from "@/components/common/Wrap"
import ClassicCase from "@/components/ClassicCase"
import CaseDetail from "@/components/case/CaseDetail"
import { getExamplesTopImg, getExamplesList } from "@/api/case"

export default {
  name: "Cases",
  components: { CaseDetail, ClassicCase, Wrap, BannerOne, BlueTitle },
  data() {
    return {
      bannerData: {
        img: "",
        title: "",
        subtitle: "",
      },
      isShowDetail: false,
      caseImgs: [],
      topItems: [],
      subItems: [],
      topSelected: 0,
      subSelected: 0,
      selectedId: null,
    }
  },
  created() {
    this.getTopImg()
    this.getTopList()
  },
  mounted() {
    this.$nextTick(() => {
      this.dealCaseImgs()
    })
  },
  methods: {
    getTopList() {
      getExamplesList().then(res => {
        this.topItems = res.data
        this.subItems = this.topItems[0].list
        this.caseImgs = this.cutImgs(this.subItems[0].top_imgs, 4)
        this.selectedId = this.subItems[0].id
        if (this.isShowDetail) {
          this.$refs.caseDetail.refreshInfo(this.selectedId)
        }
      })
    },
    getTopImg() {
      getExamplesTopImg().then(res => {
        this.bannerData = res.data
      })
    },
    clickSubItems(e, i) {
      this.selectedId = e.id
      this.subSelected = i
      if (this.isShowDetail) {
        this.$refs.caseDetail.refreshInfo(this.selectedId)
      }
      this.caseImgs = this.cutImgs(e.top_imgs, 4)
    },
    cutImgs(arr, e) {
      if (arr.length > e) {
        arr = arr.slice(0, e)
      }
      return arr
    },
    clickTopItems(e, i) {
      this.topSelected = i
      this.subItems = e.list
      if (e.list.length == 0) {
        this.caseImgs = []
        this.isShowDetail = false
      } else {
        this.selectedId = e.list[0].id
        if (this.isShowDetail) {
          this.$refs.caseDetail.refreshInfo(this.selectedId)
        }
        this.caseImgs = this.cutImgs(this.subItems[0].top_imgs, 4)
      }
      // if (e.id === 999) {
      //   document.getElementById("more-cases").scrollIntoView({ behavior: "smooth" })
      // }
    },

    expandMore() {
      this.isShowDetail = !this.isShowDetail
      if (this.isShowDetail) {
        setTimeout(() => {
          this.$refs.caseDetail.refreshInfo(this.selectedId)
        })
      }
    },
    dealCaseImgs() {
      if (this.caseImgTimer) this.caseImgTimer = null
      this.caseImgTimer = setInterval(() => {
        this.caseImgs.push(this.caseImgs[0])
        this.caseImgs.shift()
      }, 5000)
    },
  },
  beforeDestroy() {
    clearInterval(this.caseImgTimer)
    this.caseImgTimer = null
  },
}
</script>

<style scoped lang="less">
.warp-div {
  background-color: #ffffff;
  padding: 0 0.77rem;
}
.top-items {
  padding: 0;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .item {
    flex: 1;
    margin-right: 1px;
    height: 0.35rem;
    line-height: 0.35rem;
    background-color: #0647b9;
    font-size: 0.14rem;
    cursor: pointer;
    &.hover,
    &:hover {
      background-color: #1080ef;
    }

    font-weight: 400;
    color: #ffffff;
  }
}
.my-blue {
  padding: 0.45rem 0 0.2rem 0;
  ::v-deep .title {
    font-size: 0.26rem;
    .en {
      font-size: 0.19rem;
    }
  }
}
.my-blue2 {
  padding: 0.45rem 0 0.2rem 0;
  ::v-deep .title {
    font-size: 0.21rem;
    .en {
      font-size: 0.15rem;
    }
  }
}
.sub-view {
  margin-top: 0.1rem;
  margin-bottom: 0.2rem;
  display: flex;
  .title {
    color: #0647b9;
    font-size: 0.19rem;
    font-weight: 500;
  }
  .sub-items {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .item {
      font-size: 0.17rem;
      margin: 0 0.1rem;
      color: #a3a2a2;
      cursor: pointer;
      &.hover,
      &:hover {
        color: black;
        text-decoration: underline;
      }
    }
  }
}
.classic-case {
  ::v-deep .caseContent .container {
    display: none;
  }
}
.case-imgs {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(3, 1rem);
  overflow: hidden;
  grid-gap: 0.05rem;
  margin-bottom: 0.34rem;
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    transition: fadeIn 3s linear;
  }
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  :first-child {
    grid-column: 1 / span 3;
    grid-row: 1 / span 3;
    height: 100%;
  }
}
.expand {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 0.13rem;

  font-weight: 400;
  color: #707070;
  cursor: pointer;
  margin-top: 0.1rem;
  img {
    margin-top: 0.03rem;
    width: 0.1818rem;
    height: 0.1363rem;
  }
}
</style>
