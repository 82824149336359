import request from "@/utils/request"

//解决方案-顶部方案列表
export function getProgrammesData() {
  return request({
    url: `/api.php/Programmes/getProgrammesData`,
    method: "get",
  })
}
//解决方案-弹窗内容详情
export function getProgrammesDetails(id) {
  return request({
    url: `/api.php/Programmes/getProgrammesDetails`,
    method: "get",
    params: { id },
  })
}
//解决方案-内容详情-解决方案列表
export function getProgrammesListDetails(programmes_top_data_id) {
  return request({
    url: `/api.php/Programmes/getProgrammesListDetails`,
    method: "get",
    params: { programmes_top_data_id },
  })
}
//解决方案-提交解决方案咨询
export function getProgrammesRecord(data) {
  return request({
    url: `/api.php/Programmes/getProgrammesRcord`,
    method: "get",
    params: data,
  })
}
