<template>
  <el-dialog title="立即联系" :visible.sync="isShow" width="8.65rem">
    <div class="main-container">
      <div class="container">
<!--        <div class="item-container">-->
<!--          <div class="ic-title">-->
<!--            ·服务项目-->
<!--            <div class="ict-count">{{ list.length }}</div>-->
<!--          </div>-->
<!--          <div class="ic-sub" v-for="(i, k) in list">{{ k + 1 }}. {{ i.title }}</div>-->
<!--        </div>-->
        <div class="info-container">
          <div class="ic-top">
            <img src="../../assets/images/download_yellow_small.png" alt="" />
            <div class="ic-title">获取资料</div>
            <div class="ic-sub">
              <div class="ics-main">仅供个人学习研究，不可商用。</div>
              <div class="ics-sub">1024 x 670px (10.84 x 7.09cm) 240dpi | TIFF 1.96 MB</div>
              <div class="ics-category">
                <div>PPT</div>
                <div>WORD</div>
                <div>PDF</div>
                <div>视频</div>
                <div>图片</div>
              </div>
            </div>
          </div>
          <el-input class="input-style" placeholder="请填写你的姓名" v-model="info.name" />
          <el-input class="input-style" placeholder="请填写联系方式" v-model="info.phone" />
          <el-select class="input-style" placeholder="请选择区域" v-model="info.address">
            <el-option :value="item.name" :label="item.name" v-for="item in areaList"></el-option>
          </el-select>
          <el-select class="input-style" placeholder="请选择合作商" v-model="info.partners">
            <el-option :value="i.id" :label="i.name" v-for="i in partnersList"></el-option>
          </el-select>
          <div class="submit-btn" @click="toNext">立即提交</div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { getConsultingrRcord } from "@/api/product"
import { getProgrammesRecord } from "@/api/solution"

export default {
  name: "ContactDialog",
  data() {
    return {
      isShow: false,
      list: [],
      partnersList: [
        {
          id: 1,
          name: "业务方",
        },
        {
          id: 2,
          name: "申请方",
        },
      ],
      areaList: [
        { code: "11", name: "北京市" },
        { code: "12", name: "天津市" },
        { code: "13", name: "河北省" },
        { code: "14", name: "山西省" },
        { code: "15", name: "内蒙古自治区" },
        { code: "21", name: "辽宁省" },
        { code: "22", name: "吉林省" },
        { code: "23", name: "黑龙江省" },
        { code: "31", name: "上海市" },
        { code: "32", name: "江苏省" },
        { code: "33", name: "浙江省" },
        { code: "34", name: "安徽省" },
        { code: "35", name: "福建省" },
        { code: "36", name: "江西省" },
        { code: "37", name: "山东省" },
        { code: "41", name: "河南省" },
        { code: "42", name: "湖北省" },
        { code: "43", name: "湖南省" },
        { code: "44", name: "广东省" },
        { code: "45", name: "广西壮族自治区" },
        { code: "46", name: "海南省" },
        { code: "50", name: "重庆市" },
        { code: "51", name: "四川省" },
        { code: "52", name: "贵州省" },
        { code: "53", name: "云南省" },
        { code: "54", name: "西藏自治区" },
        { code: "61", name: "陕西省" },
        { code: "62", name: "甘肃省" },
        { code: "63", name: "青海省" },
        { code: "64", name: "宁夏回族自治区" },
        { code: "65", name: "新疆维吾尔自治区" },
      ],
      info: {
        partners: null,
        address: null,
        phone: null,
        name: null,
        froms: 3,
      },
      isSolution: false,
    }
  },
  methods: {
    show(e, isSolution) {
      this.isSolution = isSolution || false
      this.list = e
      this.isShow = true
    },
    hide() {
      this.isShow = false
    },
    toNext() {
      if (!this.info.name) {
        this.$message.error("请输入姓名")
        return
      } else if (!this.info.phone) {
        this.$message.error("请输入联系方式")
        return
      } else if (!this.info.address) {
        this.$message.error("请选择区域")
        return
      } else if (!this.info.partners) {
        this.$message.error("请选择合作商")
        return
      }
      let ids = []
      for (const i of this.list) {
        ids.push(i.id)
      }
      if (this.isSolution) {
        this.info.froms = 2
        this.info.programmes_id = ids.join(",")
        getProgrammesRecord(this.info).then(res => {
          this.hide()
          this.$app.$refs.successDialog.show()
        })
      } else {
        this.info.froms = 3
        this.info.product_consulting_id = ids.join(",")
        getConsultingrRcord(this.info).then(res => {
          this.hide()
          this.$app.$refs.successDialog.show()
        })
      }
    },
  },
}
</script>

<style scoped lang="less">
@import "../../assets/styles/dialog.less";
.container {
  display: flex;
  justify-content: space-between;
}
.info-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  ::v-deep .el-input__inner {
    height: 0.37rem;
    line-height: 0.37rem;
    font-size: 0.13rem;
  }
  ::v-deep .el-input__icon {
    line-height: initial;
  }
  .input-style {
    width: 3.9rem;
    margin-bottom: 0.11rem;
  }
  .submit-btn {
    margin-top: 0.54rem;
    width: 3.9rem;
    height: 0.37rem;
    line-height: 0.37rem;
    background-color: #fdcd03;
    border-radius: 0px 0px 0px 0px;
    font-size: 0.16rem;
    font-weight: 500;
    color: #0647b9;
    cursor: pointer;
  }
  .ic-top {
    display: flex;
    margin-top: 0.3rem;
    margin-bottom: 0.24rem;
    width: 80%;
    img {
      width: 0.24rem;
      height: 0.25rem;
    }
    .ic-title {
      font-size: 0.2rem;

      font-weight: 500;
      color: #0647b9;
      line-height: 0.25rem;
      margin: 0 0.1rem;
      flex-shrink: 0;
    }
    .ic-sub {
      font-size: 0.14rem;

      font-weight: 400;
      color: #303030;
      text-align: justify;
      .ics-main {
        font-weight: bold;
      }
      .ics-category {
        font-size: 0.13rem;
        font-weight: 500;
        color: #1080ef;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 0.08rem;
      }
    }
  }
}
.item-container {
  margin-top: 0.3rem;
  text-align: left;
  .ic-title {
    font-size: 0.2rem;
    font-weight: 500;
    color: #0647b9;
    display: flex;
    align-items: center;
  }
  .ic-sub {
    font-size: 0.16rem;
    font-weight: 400;
    color: #0647b9;
    margin-left: 1rem;
    padding-bottom: 0.02rem;
  }
  .ict-count {
    width: 0.2rem;
    height: 0.2rem;
    background-color: red;
    border-radius: 0.1rem;
    color: white;
    font-size: 0.12rem;
    text-align: center;
    margin-left: 0.05rem;
  }
}
.main-container {
  padding-bottom: 1rem;
}
</style>
