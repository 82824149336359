<template>
  <div id="container">
    <div class="blue-title">看介绍</div>
    <div class="introduce">
      <div class="info" :class="isPc ? '' : 'no-padding'">
        <img class="icon" :src="introduceInfo.introduce_logo" alt="" />
        <div class="main">
          <div class="title">{{ introduceInfo.introduce_title }}</div>
          <div class="en">{{ introduceInfo.introduce_english_title }}</div>
          <div class="desc">{{ introduceInfo.introduce_subtitle }}</div>
          <div class="operation" v-if="isPc">
            <div class="item">
              <img src="../../assets/images/case/case_10.png" alt="" />
              <div>{{ introduceInfo.introduce_author }}</div>
            </div>
            <div class="item">
              <img class="mini" src="../../assets/images/case/case_9.png" alt="" />
              <div>{{ introduceInfo.introduce_views }}</div>
            </div>
            <div class="item">
              <img class="mini" src="../../assets/images/case/case_15.png" alt="" />
              <div>{{ introduceInfo.introduce_dzs }}</div>
            </div>
            <div class="item">
              <img class="mini" src="../../assets/images/case/case_14.png" alt="" />
              <div>{{ introduceInfo.introduce_share_num }}</div>
            </div>
          </div>
          <div class="sub" style="align-items: flex-start" v-if="!isPc">
            <div class="star">★★★★★</div>
            <div class="desc" v-for="i in introduceInfo.introduce_market_honor_label">{{ i }}</div>
          </div>
          <div class="main-info" v-if="isPc">
            <div>项目时间：{{ introduceInfo.introduce_item_date }}</div>
            <div>项目名称：{{ introduceInfo.introduce_item_name }}</div>
            <div>项目内容：{{ introduceInfo.introduce_item_content }}</div>
            <div>项目地址：{{ introduceInfo.introduce_item_address }}</div>
          </div>
        </div>
        <div class="sub" v-if="isPc">
          <div class="star">★★★★★</div>
          <div class="desc" v-for="i in introduceInfo.introduce_market_honor_label">{{ i }}</div>
          <div class="wap" @mouseenter="wapHover" @mouseleave="wapHover">
            <img src="../../assets/images/case/case_8.png" alt="" />
            <div>手机阅读</div>
            <div class="qr-item" v-if="isShowQr">
              <div class="qr-text">微信扫一扫</div>
              <div class="qr-text">分享本文</div>
              <div class="qr-div">
                <vue-qr class="vue-qr" :text="qrUrl"></vue-qr>
              </div>
            </div>
          </div>
        </div>
      </div>
      <template v-if="!isPc">
        <my-divider></my-divider>
        <div class="main-info">
          <div>项目时间：{{ introduceInfo.introduce_item_date }}</div>
          <div>项目名称：{{ introduceInfo.introduce_item_name }}</div>
          <div>项目内容：{{ introduceInfo.introduce_item_content }}</div>
          <div>项目地址：{{ introduceInfo.introduce_item_address }}</div>
        </div>
      </template>
      <my-divider></my-divider>
      <div class="intro-data" v-if="isPc">
        <swiper class="id-item" :options="labelSwiperOption" ref="labelSwiper">
          <swiper-slide class="lis-slide" v-for="(value, key) in introduceInfo.introduce_market_scale_label">
            <div class="idi-main">{{ value }}</div>
            <div class="idi-sub">{{ key }}</div>
          </swiper-slide>
          <div class="swiper-button-next swiper-button-style" slot="button-next"></div>
          <div class="swiper-button-prev swiper-button-style" slot="button-prev"></div>
        </swiper>
      </div>
      <div v-else class="id-mobile">
        <div class="idm-item" v-for="(value, key) in introduceInfo.introduce_market_scale_label">
          <div class="idi-main">{{ value }}</div>
          <div class="idi-sub">{{ key }}</div>
        </div>
      </div>
      <my-divider></my-divider>
      <div :class="isPc ? 'intro-product' : 'ip-mobile'">
        <div :class="isPc ? 'ip-item' : 'ip-item-mobile'" v-for="i in introduceInfo.introduce_market_product_label">{{ i }}</div>
      </div>
      <my-divider></my-divider>
      <div class="intro-text" v-html="introduceInfo.introduce_text_introduction"></div>
    </div>
    <template v-if="imgs.length > 0">
      <div class="blue-title">看图片</div>
      <div class="look-images" v-if="isPc">
        <swiper class="li-swiper gallery-top" :options="swiperOptionTop" ref="swiperTop">
          <swiper-slide class="lis-slide" v-for="item in imgs">
            <img fit="cover" :src="item.img" class="img" alt="" />
          </swiper-slide>
        </swiper>
        <swiper class="li-swiper gallery-thumbs" :options="swiperOptionThumbs" ref="swiperThumbs">
          <swiper-slide class="lis-slide" v-for="item in imgs">
            <img fit="cover" :src="item.img" class="img" alt="" />
            <div class="liss-title">{{ item.title }}</div>
            <div class="liss-sub">{{ item.subtitle }}</div>
          </swiper-slide>
          <div class="swiper-button-next swiper-button-style" slot="button-next"></div>
          <div class="swiper-button-prev swiper-button-style" slot="button-prev"></div>
        </swiper>
      </div>
      <div v-else>
        <div class="look-images-m">
          <div class="look-images-m-item" v-for="(item, index) in imgs">
            <img fit="cover" :src="item.img" class="img" alt="" />
            <div v-if="index > 0" class="lim-title">{{ item.title }}</div>
            <div v-if="index > 0" class="lim-sub">{{ item.subtitle }}</div>
          </div>
        </div>
      </div>
    </template>

    <div class="blue-title" v-if="products.length > 0">看产品</div>
    <div class="product" :class="isPc ? '' : 'no-grid'" v-if="products.length > 0">
      <div class="product-item" v-for="i in products">
        <div class="pi-img">
          <img :src="i.img" alt="" />
        </div>
        <div class="pi-title">
          <div>{{ i.title }}</div>
          <img src="../../assets/images/case/case_4.png" alt="" />
        </div>
        <div class="pi-sub">采购量：{{ i.purchase_volume }}</div>
        <div class="pi-sub">用户反馈：{{ i.feedback }}</div>
        <div class="pi-sub">使用效果：{{ i.use_effect }}</div>
        <div class="pi-sub">推荐指数：{{ i.recommende_index }}</div>
        <div class="pi-sub">复购率：100%</div>
        <div class="pi-sub">
          用户评价：
          <span>★★★★★</span>
        </div>
      </div>
    </div>
    <div class="blue-title" v-if="dataList.length > 0">看数据</div>
    <div class="data" :class="isPc ? '' : 'no-grid'" v-if="dataList.length > 0">
      <div class="data-item" v-for="item in dataList">
        <img :src="item.img" alt="" />
        <div class="di-title">{{ item.title }}</div>
        <div class="di-sub">{{ item.subtitle }}</div>
      </div>
    </div>
    <div class="blue-title" v-if="commentList.length > 0">看评论</div>
    <div class="comment" v-if="commentList.length > 0">
      <template v-for="i in commentList">
        <div class="comment-item">
          <img :src="i.img" alt="" />
          <div class="ci-main">
            <div class="cim-title">{{ i.nickname }}</div>
            <div class="cim-desc">{{ i.content }}</div>
            <div class="cim-bottom">
              <div class="cimb-date">{{ i.pl_time }}</div>
              <div class="cimb-like">
                <img src="../../assets/images/case/case_19.png" alt="" />
                <div>{{ i.dz_num }}</div>
              </div>
            </div>
          </div>
        </div>
        <my-divider v-if="i < 4" :no-padding="true"></my-divider>
      </template>
    </div>
    <div class="blue-title" v-if="reportList.length > 0">看报道</div>
    <div class="report" :class="isPc ? '' : 'no-grid'" v-if="reportList.length > 0">
      <div class="report-item" v-for="i in reportList" @click="clickReport(i)">
        <img :src="i.img" alt="" />
        <div class="ri-main">
          <div class="rim-title">{{ i.title }}</div>
          <div class="rim-date">互联网·{{ i.create_time }}</div>
        </div>
      </div>
    </div>
    <div class="blue-title" v-if="recommendList.length > 0">相关推荐</div>
    <div class="recommend" :class="isPc ? '' : 'no-grid'" v-if="recommendList.length > 0">
      <div class="recommend-item" v-for="i in recommendList" @click="clickReport(i)">
        <div class="ri-img">
          <img :src="i.img" alt="" />
        </div>
        <div class="ri-title">{{ i.title }}</div>
        <div class="ri-date">{{ i.create_time }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import MyDivider from "@/components/common/MyDivider"
import { getExamplesClassic, getExamplesClassicImgs, getExamplesClassicProducts, getExamplesClassicData, getExamplesClassicComment, getExamplesClassicReport, getExamplesClassicRecommend } from "@/api/case"
import { isMobile } from "@/utils"

export default {
  name: "CaseDetail",
  components: { MyDivider },
  data() {
    return {
      labelSwiperOption: {
        slidesPerView: 6,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      swiperOptionTop: {
        loop: true,
        loopedSlides: 5, // looped slides should be the same
        spaceBetween: 10,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      swiperOptionThumbs: {
        loop: true,
        loopedSlides: 5, // looped slides should be the same
        spaceBetween: 10,
        centeredSlides: true,
        slidesPerView: "auto",
        touchRatio: 0.2,
        slideToClickedSlide: true,
      },
      introduceInfo: {},
      imgs: [],
      products: [],
      dataList: [],
      commentList: [],
      reportList: [],
      recommendList: [],
      isPc: true,
      isShowQr: false,
      qrUrl: null,
      id: null,
    }
  },
  created() {
    if (isMobile()) {
      this.isPc = false
    } else {
      this.isPc = true
    }
  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        const swiperTop = this.$refs.swiperTop.$swiper
        const swiperThumbs = this.$refs.swiperThumbs.$swiper
        swiperTop.controller.control = swiperThumbs
        swiperThumbs.controller.control = swiperTop
      }, 200)
    })
  },
  methods: {
    wapHover() {
      this.isShowQr = !this.isShowQr
    },
    refreshInfo(id) {
      if (id) {
        this.id = id
        let url = window.location.href
        this.qrUrl = url.replace("/cases", "/caseMobile")
        if (this.qrUrl.indexOf("?id=") === -1) {
          this.qrUrl = this.qrUrl + `?id=${id}`
        }
        this.getIntroduce()
        this.getExamplesClassicImgs()
        this.getExamplesClassicProducts()
        this.getExamplesClassicData()
        this.getExamplesClassicComment()
        this.getExamplesClassicReport()
        this.getExamplesClassicRecommend()
      }
    },
    clickReport(e) {
      if (e.type == 1) {
        //url
        // window.location.href = e.content
        window.open(e.content, "_blank")
      } else {
        let routeData = this.$router.resolve({
          name: "InformationDetail",
          query: {
            id: e.content,
          },
        })
        window.open(routeData.href, "_blank")
      }
    },
    getIntroduce() {
      getExamplesClassic(this.id).then(res => {
        this.introduceInfo = res.data
      })
    },
    getExamplesClassicImgs() {
      getExamplesClassicImgs(this.id).then(res => {
        this.imgs = res.data
      })
    },
    getExamplesClassicProducts() {
      getExamplesClassicProducts(this.id).then(res => {
        this.products = res.data
      })
    },
    getExamplesClassicData() {
      getExamplesClassicData(this.id).then(res => {
        this.dataList = res.data
      })
    },
    getExamplesClassicComment() {
      getExamplesClassicComment(this.id).then(res => {
        this.commentList = res.data
      })
    },
    getExamplesClassicReport() {
      getExamplesClassicReport(this.id).then(res => {
        this.reportList = res.data
      })
    },
    getExamplesClassicRecommend() {
      getExamplesClassicRecommend(this.id).then(res => {
        this.recommendList = res.data
      })
    },
  },
}
</script>

<style scoped lang="less">
#container {
}
.blue-title {
  font-size: 0.2rem;

  font-weight: 600;
  color: #0647b9;
  text-align: left;
  margin-bottom: 0.3rem;
  padding-top: 0.1rem;
  position: relative;
  &::before {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 100%;
    height: 1px;
    border-top: 1px solid #707070;
    transform-origin: 50% 100%;
    transform: scaleY(0.5);
  }
}
.introduce {
  .no-padding {
    padding-bottom: 0 !important;
  }
  .intro-text {
    font-size: 0.14rem;
    @media screen and (max-width: 1200px) {
      font-size: 0.16rem;
    }
    font-weight: 400;
    color: #303030;
    line-height: 0.26rem;
    text-align: justify;
    margin-top: 0.3rem;
    margin-bottom: 0.84rem;
  }
  .info {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-bottom: 0.3rem;
    text-align: left;

    .icon {
      height: 0.76rem;
      width: 0.76rem;
      margin-right: 0.23rem;
      @media screen and (max-width: 1200px) {
        margin-right: 0.1rem;
      }
    }

    .main {
      margin-top: -0.05rem;
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .title {
        font-size: 0.29rem;
        font-weight: 500;
        color: #141414;
        @media screen and (max-width: 1200px) {
          font-size: 0.25rem;
        }
      }

      .en {
        font-size: 0.15rem;
        font-weight: 500;
        color: #626262;
        @media screen and (max-width: 1200px) {
          font-size: 0.13rem;
        }
      }

      .desc {
        font-size: 0.18rem;
        font-weight: 500;
        color: #626262;
        @media screen and (max-width: 1200px) {
          font-size: 0.15rem;
        }
      }
    }
  }
  .sub {
    margin-top: 0.05rem;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .star {
      font-size: 0.22rem;

      font-weight: 400;
      color: #fca518;
    }

    .desc {
      font-size: 0.15rem;
      font-weight: 500;
      color: #626262;
    }
    .wap {
      margin-top: 0.14rem;
      display: flex;
      align-items: center;
      font-size: 0.15rem;
      font-weight: 500;
      color: #626262;
      position: relative;
      .vue-qr {
        position: absolute;
        top: 0;
      }
      img {
        width: 0.18rem;
        height: 0.18rem;
        margin-right: 2px;
      }
      &:hover {
        cursor: pointer;
      }
      .qr-item {
        width: 0.87rem;
        height: 1.05rem;
        background: url("../../assets/images/case/case_20.png") no-repeat center;
        background-size: 100% 100%;
        position: absolute;
        top: 0.25rem;
        left: -0.05rem;
        z-index: 99999;
        padding: 0.2rem 0;
        .qr-text {
          text-align: center;
          font-size: 0.1rem;
        }
        .qr-div {
          height: 0.8rem;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0;
          .vue-qr {
            height: 0.75rem;
            width: 0.75rem;
            position: initial;
          }
        }
      }
    }
  }
  .intro-product {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0 0.5rem;
    .ip-item {
      font-size: 0.22rem;
      font-weight: 600;
      color: #626262;
      padding: 0 0.2rem;
    }
  }
  .intro-data {
    display: flex;
    justify-content: space-between;
    .swiper-button-next:after,
    .swiper-button-prev:after {
      font-size: initial;
    }
    ::v-deep .swiper-wrapper {
      width: auto;
      max-width: 86%;
      overflow: hidden;
    }
    .id-btn {
      width: 0.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 0.09rem;
        height: 0.18rem;
      }
      &:hover {
        cursor: pointer;
      }
    }
    .id-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      .idi-main {
        font-size: 0.3rem;
        font-weight: 500;
        color: #626262;
      }
      .idi-sub {
        font-size: 0.13rem;

        font-weight: 400;
        color: #626262;
      }
    }
  }
}
.id-mobile {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0.26rem;
  .idm-item {
    height: 1.1rem;
    border: 1px solid #acacac;
    border-radius: 0.04rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .idi-main {
      font-size: 0.28rem;
      font-weight: bold;
      color: #626262;
    }
    .idi-sub {
      font-size: 0.16rem;
      font-weight: 400;
      color: #626262;
    }
  }
}
.operation {
  padding: 0.2rem 0;
  display: flex;
  width: 100%;

  .item {
    margin-right: 0.2rem;
    font-size: 0.19rem;

    font-weight: 500;
    color: #7c7c7c;
    display: flex;
    align-items: center;
    .mini {
      height: 0.18rem;
      width: 0.2rem;
    }
    > img {
      width: 0.37rem;
      height: 0.37rem;
      object-fit: contain;
      margin-right: 0.08rem;
    }
  }
}
.main-info {
  display: flex;
  flex-direction: column;
  align-items: self-start;
  font-size: 0.16rem;
  text-align: left;

  font-weight: 400;
  color: #626262;
  line-height: 0.25rem;
}
.recommend {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 0.22rem;
  .recommend-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    margin: 0.15rem 0;
    &:hover {
      cursor: pointer;
      img {
        transition: 1s;
        transform: scale(1.1);
      }
    }
    .ri-img {
      aspect-ratio: 230/125;
      @supports not (aspect-ratio: 230/125) {
        height: 1.05rem;
        @media screen and (max-width: 800px) {
          height: 3.25rem;
        }
      }
      width: 100%;
      overflow: hidden;
      border-radius: 7px 7px 7px 7px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .ri-title {
      font-size: 0.18rem;

      font-weight: 500;
      color: #818181;
      margin-top: 0.11rem;
      margin-bottom: 0.08rem;
    }
    .ri-date {
      font-size: 0.16rem;

      font-weight: 500;
      color: #bebebe;
    }
  }
}
.report {
  padding-top: 0.1rem;
  margin-bottom: 0.99rem;
  @media screen and (max-width: 1200px) {
    margin-bottom: 0.5rem;
  }
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0.5rem;
  .report-item {
    display: flex;
    cursor: pointer;
    img {
      width: 1.12rem;
      height: 1.12rem;
    }
    .ri-main {
      flex: 1;
      margin-left: 0.22rem;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      text-align: left;
      .rim-title {
        font-size: 0.18rem;

        font-weight: 500;
        color: #414141;
      }
      .rim-date {
        font-size: 0.16rem;

        font-weight: 500;
        color: #bebebe;
      }
    }
  }
}
.comment {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.7rem;
  @media screen and (max-width: 1200px) {
    margin-bottom: 0.2rem;
  }
  .comment-item {
    //cursor: pointer;
    margin-bottom: 0.3rem;
    display: flex;

    img {
      width: 1.12rem;
      height: 1.12rem;
    }
    .ci-main {
      flex: 1;
      margin-left: 0.22rem;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      text-align: left;
      .cim-title {
        font-size: 0.17rem;
        font-weight: 500;
        color: #414141;
      }
      .cim-desc {
        font-size: 0.18rem;
        @media screen and (max-width: 1200px) {
          margin: 0.1rem 0;
        }
        font-weight: 500;
        color: #535353;
      }
      .cim-bottom {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .cimb-date {
          font-size: 0.16rem;

          font-weight: 500;
          color: #bebebe;
        }
        .cimb-like {
          display: flex;
          align-items: center;
          font-size: 0.17rem;

          font-weight: 500;
          color: #fd0100;
          img {
            width: 0.18rem;
            height: 0.18rem;
            margin-right: 7px;
          }
        }
      }
    }
  }
}
.data {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0.14rem;
  margin-bottom: 0.82rem;
  .data-item {
    display: flex;
    flex-direction: column;
    align-items: self-start;
    text-align: left;
    margin-bottom: 0.33rem;
    img {
      width: 100%;
      height: auto;
      object-fit: cover;
      aspect-ratio: 490/295;
      @supports not (aspect-ratio: 490/295) {
        height: 2.45rem;
        @media screen and (max-width: 800px) {
          height: 3.2rem;
        }
      }
      background-color: olive;
    }
    .di-title {
      font-size: 0.18rem;

      font-weight: 500;
      color: #03162c;
      margin-top: 0.19rem;
      margin-bottom: 1px;
    }
    .di-sub {
      font-size: 0.16rem;

      font-weight: 400;
      color: #535353;
    }
  }
}
.product {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 0.08rem;
  margin-bottom: 0.25rem;
  padding-top: 0.1rem;
  .product-item {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-bottom: 0.59rem;
    .pi-img {
      width: 100%;
      aspect-ratio: 1.67;
      object-fit: cover;
      @supports not (aspect-ratio: 1.67) {
        height: 1.18rem;
        @media screen and (max-width: 800px) {
          height: 3.2rem;
        }
      }
      //background-color: palegoldenrod;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .pi-title {
      margin-top: 0.22rem;
      margin-bottom: 0.12rem;
      display: flex;
      align-items: center;
      font-size: 0.18rem;

      font-weight: 500;
      color: #626262;
      img {
        width: 0.1314rem;
        height: 0.1485rem;
        margin-left: 0.12rem;
      }
    }
    .pi-sub {
      font-size: 0.14rem;
      font-weight: 400;
      color: #535353;
      margin: 0.04rem 0;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      span {
        color: #fca518;
      }
    }
  }
}
.look-images {
  margin-bottom: 0.7rem;
  .swiper-button-style {
    //color: #bebebe;
    color: black;
    background-color: #999999;
    opacity: 0.6;
  }
  .swiper-button-prev {
    left: 0;
    top: 38%;
  }
  .swiper-button-next {
    right: 0;
    top: 38%;
  }
  .swiper-button-prev:after,
  .swiper-button-next:after {
    font-size: initial;
    font-weight: bold;
  }
  .gallery-thumbs {
    cursor: pointer;
    img {
      width: 100%;
      aspect-ratio: 1.7524;
      @supports not (aspect-ratio: 1.7524) {
        height: 0.9rem;
        @media screen and (max-width: 800px) {
          height: 0.5rem;
        }
      }
      object-fit: cover;
    }
  }
  .gallery-top {
    img {
      width: 100%;
      aspect-ratio: 2.0578;
      object-fit: cover;
      @supports not (aspect-ratio: 2.0578) {
        height: 4rem;
        @media screen and (max-width: 800px) {
          height: 2.3rem;
        }
      }
    }
  }
  .li-swiper {
    .lis-slide {
      width: 100%;
      text-align: left;
      .liss-title {
        font-size: 0.14rem;
        @media screen and (max-width: 1200px) {
          font-size: 0.12rem;
        }
        font-weight: 500;
        color: #03162c;
      }
      .liss-sub {
        font-size: 0.12rem;
        @media screen and (max-width: 1200px) {
          font-size: 0.1rem;
        }
        font-weight: 400;
        color: #535353;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
    }
    &.gallery-top {
      height: 80%;
      width: 100%;
    }
    &.gallery-thumbs {
      height: 20%;
      box-sizing: border-box;
      padding: 0.09rem 0;
    }
    &.gallery-thumbs .swiper-slide {
      width: 20%;
      height: 100%;
      //opacity: 0.4;
    }
    //&.gallery-thumbs .swiper-slide-active {
    //  opacity: 1;
    //}
  }
}
.ip-mobile {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
  grid-row-gap: 0.2rem;
  .ip-item-mobile {
    border-radius: 50%;
    border: 1px solid #acacac;
    height: 1.1rem;
    width: 1.1rem;
    line-height: 1.1rem;
    font-size: 0.2rem;
    font-weight: bold;
  }
}
.no-grid {
  grid-template-columns: initial !important;
}

.look-images-m {
  margin-bottom: 0.5rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 6px;

  .look-images-m-item {
    &:first-child {
      grid-column-start: span 2;
      img {
        width: 100%;
        height: 2.5rem;
        object-fit: cover;
        vertical-align: top;
      }
    }
    text-align: left;
    .img {
      width: 100%;
      height: 1.3rem;
      object-fit: cover;
    }
    .lim-title {
      font-size: 0.18rem;
      font-weight: 500;
      color: #626262;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
    .lim-sub {
      font-size: 0.14rem;
      font-weight: 400;
      color: #9a9999;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }
}
</style>
