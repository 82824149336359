<template>
  <div className="index">
    <Banner></Banner>
    <Wrap>
      <div class="modifiedVersion1_1">
        <div class="box_title">我们的优势</div>
        <div class="advantage_box">
          <div class="advantage_item1">
            <img src="@/assets/images/advantage_module_hangye.png" alt="">
            <div class="advantage_p1">行业领先</div>
            <div class="advantage_p2">10余年经验</div>
          </div>
          <div class="advantage_item2">
            <img src="@/assets/images/advantage_module_kehu.png" alt="">
            <div class="advantage_p1">客户信赖</div>
            <div class="advantage_p2">2000家客户案例</div>
          </div>
          <div class="advantage_item3">
            <img src="@/assets/images/advantage_module_jishu.png" alt="">
            <div class="advantage_p1">技术领先</div>
            <div class="advantage_p2">国家高新技术企业</div>
          </div>
          <div class="advantage_item4">
            <img src="@/assets/images/advantage_module_gaoding.png" alt="">
            <div class="advantage_p1">高定服务</div>
            <div class="advantage_p2">定制化解决方案</div>
          </div>
        </div>
        <div class="box_title">智慧市场全场景应用服务商</div>
        <div class="box_tab_title">
          <div
            class="tab_item"
            :class="{ activeTile: titleIdex === index}"
            v-for="(item,index) in boxTitleData"
            @click="tapClk(index)"
            :key="index">
            {{item}}
            <div class="xian"></div>
          </div>
        </div>
      </div>
      <div v-if="titleIdex === 0" class="section1" >
        <div class="container">
          <div class="subtitle">智慧农贸-未来市场数智化应用场景</div>
<!--          <h2>-->
<!--            <span>智慧农贸</span>-->
<!--            未来市场数智化应用场景-->
<!--          </h2>-->
          <div class="content">
            <img src="@/assets/images/banner_b.png" class="bigimg" alt="" />
            <div class="s3_sub">
              <div class="item">
                <img class="cover" src="@/assets/images/zhnm_a.png" />
                <div class="name">
                  数字化营销窗口
                  <img src="@/assets/images/more_blue.png" alt="" />
                </div>
                <div class="desc">增设线上渠道 全方面品宣化</div>
              </div>

              <div class="item">
                <img class="cover" src="@/assets/images/zhnm_b.png" alt="" />
                <div class="name">
                  展屏播放系统
                  <img src="@/assets/images/more_blue.png" alt="" />
                </div>
                <div class="desc">数据公示与信息互动</div>
              </div>

              <div class="item">
                <img class="cover" src="@/assets/images/zhnm_c.png" alt="" />
                <div class="name">
                  智能收银系统
                  <img src="@/assets/images/more_blue.png" alt="" />
                </div>
                <div class="desc">创新研发思路 搭建前沿系统</div>
              </div>

              <div class="item">
                <img class="cover" src="@/assets/images/zhnm_d.png" alt="" />
                <div class="name">
                  数智化运营管理中台
                  <img src="@/assets/images/more_blue.png" alt="" />
                </div>
                <div class="desc">互联网+农贸 助力转型升级</div>
              </div>
            </div>
          </div>

          <div class="flex-more more">
            <a @click="PhoneDialogview()">
              我要咨询
              <img src="@/assets/images/enter_white.png" alt="" />
            </a>
            <a @click="toSolution(0)">
              解决方案
              <img src="@/assets/images/enter_blue_right.png" alt="" />
            </a>
          </div>
        </div>
      </div>
      <div v-if="titleIdex === 1" class="section2">
        <div class="container">
          <div class="subtitle">智慧农批-未来城市数智化农产品集配中心</div>
<!--          <h2>-->
<!--            <span>智慧农批</span>-->
<!--            未来城市数智化农产品集配中心-->
<!--          </h2>-->

          <div class="content">
            <img src="@/assets/images/banner_c.png" class="bigimg" alt="" />

            <div class="s3_sub">
              <div class="item">
                <img class="cover" src="@/assets/images/zhnp_a.png" />
                <div class="name">
                  一体化平台
                  <img src="@/assets/images/more_blue.png" alt="" />
                </div>
                <div class="desc">赋能新模式 开启新变革</div>
              </div>

              <div class="item">
                <img class="cover" src="@/assets/images/zhnp_b.png" alt="" />
                <div class="name">
                  预约进场
                  <img src="@/assets/images/more_blue.png" alt="" />
                </div>
                <div class="desc">线上提前预约 过闸畅通无阻</div>
              </div>

              <div class="item">
                <img class="cover" src="@/assets/images/zhnp_c.png" alt="" />
                <div class="name">
                  智慧交易
                  <img src="@/assets/images/more_blue.png" alt="" />
                </div>
                <div class="desc">打通交易环节 打破传统经营</div>
              </div>

              <div class="item">
                <img class="cover" src="@/assets/images/zhnp_d.png" alt="" />
                <div class="name">
                  数智赋能
                  <img src="@/assets/images/more_blue.png" alt="" />
                </div>
                <div class="desc">集成市场数据 统一管理标准</div>
              </div>
            </div>
          </div>

          <div class="flex-more more">
            <a @click="PhoneDialogview()">
               我要咨询
              <img src="@/assets/images/enter_white.png" alt="" />
            </a>
            <a @click="toSolution(1)">
              解决方案
              <img src="@/assets/images/enter_blue_right.png" alt="" />
            </a>
          </div>
        </div>
      </div>
      <div v-if="titleIdex === 2" class="section3">
        <div class="container">
          <div class="subtitle">数智监管-实时监管一张网</div>
<!--          <h2>-->
<!--            <span>数智监管</span>-->
<!--            实时监管一张网-->
<!--          </h2>-->

          <div class="content">
            <img src="@/assets/images/banner_d.png" class="bigimg" alt="" />

            <div class="s3_sub">
              <div class="item">
                <img class="cover" src="@/assets/images/szjg_a.png" />
                <div class="name">
                  智能抓拍
                  <img src="@/assets/images/more_blue.png" alt="" />
                </div>
                <div class="desc">经营户占道经营行为检测</div>
              </div>

              <div class="item">
                <img class="cover" src="@/assets/images/szjg_b.png" alt="" />
                <div class="name">
                  动态监管
                  <img src="@/assets/images/more_blue.png" alt="" />
                </div>
                <div class="desc">数据实时更新 情况实时掌握</div>
              </div>

              <div class="item">
                <img class="cover" src="@/assets/images/szjg_c.png" alt="" />
                <div class="name">
                  实时预警
                  <img src="@/assets/images/more_blue.png" alt="" />
                </div>
                <div class="desc">人员登记在册 异常及时排查</div>
              </div>

              <div class="item">
                <img class="cover" src="@/assets/images/szjg_d.png" alt="" />
                <div class="name">
                  驾驶舱
                  <img src="@/assets/images/more_blue.png" alt="" />
                </div>
                <div class="desc">数据汇集大屏 实现高效监管</div>
              </div>
            </div>
          </div>

          <div class="flex-more more">
            <a @click="PhoneDialogview()">
              我要咨询
              <img src="@/assets/images/enter_white.png" alt="" />
            </a>
            <a @click="toSolution(2)">
              解决方案
              <img src="@/assets/images/enter_blue_right.png" alt="" />
            </a>
          </div>
        </div>
      </div>
      <div v-if="titleIdex === 3" class="section4">
        <div class="container">
          <div>
            <div class="wldp">
              <div class="name">未来店铺</div>
              <div class="desc">未来新零售体验</div>
            </div>
            <a class="more4" @click="toProduct(3)">
              进一步了解
              <img src="@/assets/images/enter_white.png" />
            </a>
          </div>
          <div>
            <div class="znds">
              <div class="name">智能导示</div>
              <div class="desc">数智展示惠生活</div>
            </div>
            <a class="more4" @click="toProduct(4)">
              进一步了解
              <img src="@/assets/images/enter_white.png" />
            </a>
          </div>
        </div>
      </div>
      <div class="about_zm">
          <div class="zm_title">千万客户选择筑美的原因</div>
          <div class="box_zm">
            <div class="zm_item">
              <img src="@/assets/images/wanlu.png" alt="" />
              <div class="zm_p1">不走弯路</div>
              <div class="zm_p2">丰富的智慧市场改造实战经验</div>
            </div>
            <div class="zm_item">
              <img src="@/assets/images/chengben.png" alt="" />
              <div class="zm_p1">降低成本</div>
              <div class="zm_p2">成熟产品，即开即用交付周期短</div>
            </div>
            <div class="zm_item">
              <img src="@/assets/images/zengjia.png" alt="" />
              <div class="zm_p1">增加营收</div>
              <div class="zm_p2">行业未来模式转型方案和运营服务</div>
            </div>
            <div class="zm_item">
              <img src="@/assets/images/xuanze.png" alt="" />
              <div class="zm_p1">选择安心</div>
              <div class="zm_p2">银行级技术和数据安全保障</div>
            </div>
          </div>
        <div class="flex-more more">
          <a @click="PhoneDialogview(0)">
            我要咨询
            <img src="@/assets/images/enter_white.png" alt="" />
          </a>
          <a @click="PhoneDialogview(0)">
            选择筑美
            <img src="@/assets/images/enter_blue_right.png" alt="" />
          </a>
        </div>
      </div>
    </Wrap>
    <ClassicCase :is-show-case-items="false" :serveShow="true" @consulT="PhoneDialogview"></ClassicCase>
    <Wrap>
      <div class="information_main">
        <div class=information_title>新闻资讯</div>
        <div class="information">
          <div class="information_left" @click="details(infoList[0].id)">
            <img :src="infoList[0].img" alt="">
            <div class="caption">&nbsp;&nbsp;{{infoList[0].title}}</div>
          </div>
          <div class="information_right">
            <div class="journalism" v-for="(item,index) in infoList.slice(1,4)" :key="index" @click="details(item.id)">
              <div class="headline">{{item.title}}</div>
              <div class="time">发布时间：{{item.create_time}}</div>
            </div>
          </div>
        </div>
        <div class="flex-more more">
<!--          <a @click="PhoneDialogview(0)">-->
<!--            我要咨询-->
<!--            <img src="@/assets/images/enter_white.png" alt="" />-->
<!--          </a>-->
          <a @click="toSolution(6)">
            更多资讯
            <img src="@/assets/images/enter_white.png" alt="" />
          </a>
        </div>
      </div>
    </Wrap>
    <Partners></Partners>
    <Contactus class="fix-contact" v-if="fixContact" ></Contactus>
    <Contactus></Contactus>
    <PhoneDialog ref="PhoneDialog"></PhoneDialog>
  </div>
</template>

<script>
import Banner from "@/components/Banner"
import Partners from "@/components/Partners"
import Contactus from "@/components/Contactus"
import ClassicCase from "@/components/ClassicCase"
import PhoneDialog from "@/components/dialog/PhoneDialog"
import Wrap from "@/components/common/Wrap"
import { getAboutCompanyInformation } from "@/api/about"

export default {
  components: {
    Wrap,
    Banner,
    Partners,
    Contactus,
    ClassicCase,
    PhoneDialog
  },
  data(){
    return{
      infoList: [],
      titleIdex: 0,
      fixContact: true,
      boxTitleData: [
        '智慧农贸',
        '智慧农批',
        '智慧监管',
        '智慧导示'
      ]
    }
  },
  created() {
  this.getCompanyInformation()
  },
  methods: {
    toSolution(e) {
      if (e === 0) {
        this.$router.push({
          name: "ProductPage1",
          params: {
            isShowSolution: false,
          },
        })
      } else if (e === 1) {
        this.$router.push({
          name: "ProductPage2",
          params: {
            isShowSolution: false,
          },
        })
      } else if (e === 2) {
        this.$router.push({
          name: "ProductPage3",
          params: {
            isShowSolution: false,
          },
        })
      }else if (e === 6) {
        this.$router.push({
          path: "/about",
          params: {
            isShowSolution: false,
          },
        })
      }
    },
    toProduct(e) {
      if (e === 1) {
        this.$router.push({
          name: "ProductPage2",
        })
      } else if (e === 2) {
        this.$router.push({
          name: "ProductPage3",
        })
      } else if (e === 3) {
        this.$router.push({
          name: "ProductPage4",
        })
      } else if (e === 4) {
        this.$router.push({
          name: "ProductPage5",
        })
      } else {
        this.$router.push({
          name: "ProductPage1",
        })
      }
    },
    getCompanyInformation() {
      getAboutCompanyInformation().then(res => {
        this.infoList = res.data.data
      })
    },
    tapClk(index){
      this.titleIdex = index
    },
    PhoneDialogview() {
      this.$refs['PhoneDialog'].$data.codeMaskShow = true
    },
    details(id) {
      let routeData = this.$router.resolve({
        name: "InformationDetail",
        query: {
          id: id,
          info: true,
        },
      })
      window.open(routeData.href, "_blank")
    },
    scrolling() {
      // 滚动条距文档顶部的距离
      const scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      // 滚动条滚动的距离
      const scrollStep = scrollTop - this.oldScrollTop;
   //   console.log("header 滚动距离 ", scrollTop);
   //   console.log("滚动条滚动的距离 ", scrollStep);
      // // 更新——滚动前，滚动条距文档顶部的距离
      // this.oldScrollTop = scrollTop;
      //
      // //变量windowHeight是可视区的高度
      const windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
      // console.log(windowHeight)
      // //变量scrollHeight是滚动条的总高度
      let scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight;
    //  console.log('滚动条的总高度',scrollHeight)



      // //滚动条到底部的条件
      if (scrollTop + windowHeight >= scrollHeight) {
        //你想做的事情
      //  console.log("header  你已经到底部了");
      }
      console.log(scrollHeight,scrollTop,windowHeight)
      console.log(scrollHeight-scrollTop)
      if(  scrollHeight - scrollTop- windowHeight <= 220) {
        this.fixContact = false
       // console.log('距离底部还有一屏')
      }else {
       this.fixContact = true
      }

      // if (scrollStep < 0) {
      //   //  console.log("header 滚动条向上滚动了！");
      //   this.IsSticky = false
      // } else if (scrollTop > windowHeight) {
      //   this.IsSticky = true
      // }
      // 判断是否到了最顶部
      // if (scrollTop <= 0) {
      //   this.IsSticky = false
      // }
    }
  },
  mounted() {
    // 监听页面滚动事件
    window.addEventListener("scroll", this.scrolling);
  },
}
</script>

<style lang="less" scoped>
@import "../assets/styles/home.less";
.index {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  .header {
    flex-shrink: 0;
  }

  .module {
    flex-shrink: 0;
    flex-grow: 1;
  }

  .footer {
    flex-shrink: 0;
  }
}
.section1,.section2,.section3,.section4{
  .container {
    padding: 0 .1rem;
    padding-bottom: .8rem;
    .subtitle{
      position: absolute;
      color: #fff;
      background: rgba(2,86,255,.5);
      padding: .06rem .1rem;
    }
  }
}
.section4 .container {
  display: grid;
  grid-template-columns: repeat(2,1fr);
  padding: 0;
  padding-bottom: .3rem;
}
.modifiedVersion1_1 {
  background: #fff;
  width: 100%;
  //padding-top: .8rem;
.advantage_box{
  margin: 0 .2rem;
  display: grid;
  height: 3.15rem;
  grid-template-columns: repeat(4,1fr);
  grid-column-gap: .2rem;
 // grid-row-gap: .2rem;
  img {
    width: .6rem;
    height: .6rem;
    margin-top: .6rem;
    margin-bottom: .2rem;
  }
  .advantage_p1 {
    font-size: .24rem;
    font-weight: 600;
    color: #FFFFFF;
  }
  .advantage_p2{
    font-size: .18rem;
    font-weight: normal;
    color: #FFFFFF;
    margin-top: .4rem;
  }
  .advantage_item1 {
  //   background: url("@/assets/images/home_advantage_bg1.png") no-repeat;
    background: #5CA6FF;
    background-size: 100% 100%;
  }
  .advantage_item2 {
   // background: url("@/assets/images/home_advantage_bg2.png") no-repeat;
    background: #578FFF;
    background-size: 100% 100%;
  }
  .advantage_item3 {
  //  background: url("@/assets/images/home_advantage_bg3.png") no-repeat;
    background: #688CFF;
    background-size: 100% 100%;
  }
  .advantage_item4 {
   // background: url("@/assets/images/home_advantage_bg4.png") no-repeat;
    background: #677DFF;
    background-size: 100% 100%;
  }
}
  .box_title {
    display: block;
    height: .7rem;
    text-align: center;
    font-weight: 500;
    font-size: .28rem;
    color: #00215E;
    padding-top: .8rem;
  }
  .box_tab_title {
    width: 6rem;
    height: .4rem;
    text-align: center;
    margin: 0 auto;
    padding-bottom: .2rem;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: .2rem;
    .tab_item {
      text-align: center;
      font-size: .22rem;
      font-weight: 400;
      color: #03162C;
      cursor: pointer;
    }
    .activeTile {
      color: #0256FF;
      font-weight: 500;
      .xian {
        width: 55%;
        margin: 0 auto;
        margin-top: 0.1rem;
        height: 0.03rem;
        background:#0256FF ;
      }
    //  border-bottom: 2px solid #0256FF;
    }

  }
}
.about_zm {
  width: 100%;
  height: 5.02rem;
  background: url("@/assets/images/about_zm_bj.png") no-repeat;
  background-size: 100% 100%;
  .zm_title {
    font-size: .22rem;
    font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
    font-weight: 500;
    color: #FFFFFF;
    padding-top: .3rem;
  }
  .box_zm {
    margin: .2rem .2rem;
    margin-bottom: .3rem;
    display: grid;
    grid-template-columns: repeat(4,1fr);
    grid-column-gap: 20px;
    height: 3.12rem;
    .zm_item {
      background: #fff;
      img {
        width: 1.2rem;
        height: 1.2rem;
        margin-top: .3rem;
      }
      .zm_p1 {
        font-size: 18px;
        font-weight: 600;
        color: #03162C;
        margin: .3rem 0 .12rem 0rem;
      }
      .zm_p2 {
        font-size: 16px;
        font-weight: 400;
        line-height: 30px;
        color: #03162C;
        padding: 0 .2rem;
      }
    }
  }

}
.flex-more {
  display: flex;
  align-items: center;
  justify-content: center;
}
.more {
  margin-top: 0.30rem;
  font-size: 0.14rem;
  a {
    width: 1.97rem;
    height: 0.37rem;
    font-size: .14rem;
    color: #FFFFFF;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    background: linear-gradient(to left, #0850D3 50%, #00389d 50%) right;
    background-size: 200%;
    transition: .5s ease-out;

    &:hover {
      background-position: left;
    }

    img {
      display: inline-block;
      margin-left: 0.04rem;
      height: .13rem;
      //vertical-align: middle;
    }
  }

  a:nth-child(2) {
    color: #0850D3;
    background: linear-gradient(to left, #FDCD03 50%, #ffb100 50%) right;
    background-size: 200%;
    &:hover {
      background-position: left;
    }
  }
}
.fix-contact {
  width: 100%;
  position: fixed;
  bottom: 0px;
  z-index: 99;
}
.information_main {
  height: 4.58rem;
  background: #fff;
  .information_title {
    display: block;
    height: .7rem;
    text-align: center;
    font-weight: 500;
    font-size: .28rem;
    color: #00215E;
    padding-top: .8rem;
  }
  .information {
    display: grid;
    height: 2.68rem;
    grid-template-columns: repeat(2,1fr);
    grid-column-gap: .16rem;
    margin: .2rem;

  }
  .information_left {
    position: relative;
    width: 100%;
    height: 2.68rem;
      img{
        width: 100%;
        height: 100%;
      }
    .caption {
      width: 100%;
      position: absolute;
      z-index: 9;
      bottom: 0rem;
      background: #0850D3;
      color: #fff;
      text-align: left;
      height: .38rem;
      line-height: .38rem;
      font-size: .16rem;
    }
  }
  .information_right {
    height: 2.66rem;
    .journalism {
     // height:0.92rem;
      margin: .26rem 0;
      text-align: left;
      padding:0.1rem;
      &:first-child {
        margin-top: 0;
       // padding-top: 0;
      }
      &:hover {
        border: 1px solid #0A64FB;
        border-left: 3px solid #0A64FB;
      }
    }
    .headline {
      font-weight: 500;
      color: #03162C;
      font-size: .18rem;
      /* 设置元素最多显示两行文本 */
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      /* 当文本超过两行时，用省略号代替 */
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .time {
      font-weight: 400;
      color: #535353;
      font-size: .16rem;
      margin-top: .06rem;
    }
  }

}
</style>
