<template>
  <div class="advantage-items">
    <div class="item" v-for="item in data">
      <div class="title">{{ item.name }}</div>
      <div class="sub-title">{{ item.enName }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Advantage",
  props: {
    page: "",
  },
  data() {
    return {
      data: [],
    }
  },
  mounted() {
    if (this.page === 2) {
      this.data = [
        {
          name: "管理便利化",
          enName: "Linkage management",
        },
        {
          name: "运营数据化",
          enName: "Intelligence operations",
        },
        {
          name: "服务产业化",
          enName: "Authors efficiency",
        },
        {
          name: "系统模块化",
          enName: "Innovation making profits",
        },
        {
          name: "部署多样化",
          enName: "Plan to customize",
        },
        {
          name: "案例经典化",
          enName: "On site service",
        },
      ]
    } else if (this.page === 3) {
      this.data = [
        {
          name: "优化勤务",
          enName: "权益保障从被动向主动转变",
        },
        {
          name: "规范执法",
          enName: "权益保障从被动向主动转变",
        },
        {
          name: "升级安全",
          enName: "从人盯眼看向主动控制转变",
        },
        {
          name: "减负增效",
          enName: "执法工作从体力向智力转变",
        },
        {
          name: "加创盈收",
          enName: "市场利润空间从微到厚转变",
        },
        {
          name: "…",
          enName: "",
        },
      ]
    } else if (this.page === 5) {
      this.data = [
        {
          name: "科技",
          enName: "人员管控从传统向科技转变",
        },
        {
          name: "美学",
          enName: "权益保障从被动向主动转变",
        },
        {
          name: "应用",
          enName: "从人盯眼看向主动控制转变",
        },
      ]
    } else {
      this.data = [
        {
          name: "品牌一线化",
          enName: "Intelligent trading",
        },
        {
          name: "运营一体化",
          enName: "Number of intelligence operations",
        },
        {
          name: "管理智能化",
          enName: "The quality of experience",
        },
        {
          name: "产品前瞻化",
          enName: "Poly guest drainage",
        },
        {
          name: "服务专业化",
          enName: "Exclusive consultant",
        },
        {
          name: "案例全国化",
          enName: "Entire case custom",
        },
      ]
    }
  },
}
</script>

<style scoped lang="less">
.advantage-items {
  width: 100%;
  height: 5.22rem;
  background: url("../assets/images/product/6.png") no-repeat;
  background-size: 100% 100%;
  color: #000000;
  display: grid;
  grid-template-columns: repeat(3, 3.14rem);
  grid-gap: 0.07rem 0.07rem;
  justify-content: center;
  align-content: center;
  .item {
    //width: 3.2725rem;
    height: 1.4rem;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .title {
      font-size: 0.2rem;
    }
    .sub-title {
      font-size: 0.12rem;
      //margin: 0.05rem;
    }
  }
}
</style>
