<template>
  <div>
    <wrap class="my-wrap">
      <div class="wrap-div">
        <!--        <blue-title-center :title="info.title" :sub-title="info.subtitle" style="margin-top: 0"></blue-title-center>-->
        <div class="img-container">
          <el-image class="imgs" :src="i" v-for="i in info.images"></el-image>
        </div>
      </div>
    </wrap>
  </div>
</template>

<script>
import { getProductDetails } from "@/api/product"
import Wrap from "@/components/common/Wrap"
import BlueTitleCenter from "@/components/BlueTitleCenter"

export default {
  name: "ProductDetail",
  components: { BlueTitleCenter, Wrap },
  data() {
    return {
      info: {},
    }
  },
  created() {
    const id = this.$route.query.id
    if (!id) {
      alert("产品不存在!")
      return
    }
    this.getProductDetail(id)
  },
  methods: {
    getProductDetail(id) {
      getProductDetails(id).then(res => {
        this.info = res.data
      })
    },
  },
}
</script>

<style scoped lang="less">
.my-wrap {
  padding-top: @headerHeight;
  display: flex;
  flex-direction: column;
  .wrap-div {
    background-color: #ffffff;
    flex: 1;
  }
}
.img-container {
  display: flex;
  flex-direction: column;
  padding: 0 0.7rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
  .imgs {
    width: 100%;
  }
}
</style>
