<template>
  <div id="app">
    <banner-one :img="require('../assets/images/product/banner.png')" title="智慧农贸解决方案" sub-title="按需定制，直击痛点，高效转型"></banner-one>
    <wrap class="main-warp">
      <div class="warp-div">
        <img class="img1" src="../assets/images/product/1.png" />
        <div style="margin-top: 0.5rem">
          <div class="top-text">融合互联网+理念，依托云平台，建立信息化、长效性、可持续的管理机制，</div>
          <div class="top-text">通过数据采集、交易结算、客群分析、经营管理等方式，搭建数字化场景，助力传统农贸市场转型升级。</div>
        </div>
        <blue-title-center title="业务流程" sub-title="按需定制，直击痛点，携手高效，助力转型"></blue-title-center>
        <div class="img-container">
          <img src="../assets/images/product/2.png" />
        </div>
        <blue-title-center title="功能模块" sub-title="六大核心、新颖体验、智慧监管、高效经营"></blue-title-center>
      </div>
    </wrap>
    <div class="function-items">
      <div class="item">
        <div class="title">智慧管理</div>
        <div class="sub-title">Wisdom management</div>
<!--        <div class="more">了解更多</div>-->
      </div>
      <div class="item">
        <div class="title">智慧交易</div>
        <div class="sub-title">Wisdom trading</div>
       <!--        <div class="more">了解更多</div>-->
      </div>
      <div class="item">
        <div class="title">智慧公示</div>
        <div class="sub-title">Wisdom show</div>
       <!--        <div class="more">了解更多</div>-->
      </div>
      <div class="item">
        <div class="title">智慧采集</div>
        <div class="sub-title">Intelligence gathering</div>
       <!--        <div class="more">了解更多</div>-->
      </div>
      <div class="item">
        <div class="title">智慧分析</div>
        <div class="sub-title">Intelligence analysis</div>
       <!--        <div class="more">了解更多</div>-->
      </div>
      <div class="item">
        <div class="title">O2O商城</div>
        <div class="sub-title">Online to offline mall</div>
       <!--        <div class="more">了解更多</div>-->
      </div>
    </div>
    <wrap class="main-warp">
      <div class="warp-div">
        <div class="img3-container">
          <img src="../assets/images/product/3.png" />
        </div>
        <blue-title-center title="应用系统" sub-title="智慧应用、全链运转、一体管理、精细监管"></blue-title-center>
        <utility-system></utility-system>
        <blue-title-center title="应用产品" sub-title="洞察行业、前沿思维、创新系统、数智运营"></blue-title-center>
        <div class="img-container">
          <img src="../assets/images/product/4.png" />
        </div>
        <blue-sub-title title="专属服务 Partners"></blue-sub-title>
        <div class="img5-container">
          <img src="../assets/images/product/5.png" />
        </div>
        <blue-title-center id="solution" title="解决方案" sub-title="围绕“美好生活建设”核心，深入运用信息系统，提高市场现代化管理水平"></blue-title-center>
        <product-solution :id="5"></product-solution>
        <blue-title-center title="核心优势" sub-title="帮助商户搭建品牌营销，为市场方提供整体解决方案，助力转型智慧农贸市场"></blue-title-center>
      </div>
    </wrap>
    <advantage></advantage>
    <classic-case></classic-case>
    <contactus></contactus>
  </div>
</template>

<script>
import BannerOne from "@/components/BannerOne"
import Wrap from "@/components/common/Wrap"
import BlueTitleCenter from "@/components/BlueTitleCenter"
import UtilitySystem from "@/components/UtilitySystem"
import BlueSubTitle from "@/components/BlueSubTitle"
import ClassicCase from "@/components/ClassicCase"
import Advantage from "@/components/Advantage"
import ProductSolution from "@/components/ProductSolution"
import Partners from "@/components/Partners"
import Contactus from "@/components/Contactus"
import { getProductConsulting } from "@/api/product"
//农贸
export default {
  name: "ProductPage1",
  components: { Contactus, Partners, ProductSolution, Advantage, ClassicCase, BlueSubTitle, UtilitySystem, BlueTitleCenter, Wrap, BannerOne },
  mounted() {
    if (this.$route.params.isShowSolution) {
      setTimeout(() => {
        document.getElementById("solution").scrollIntoView()
      }, 100)
    }
  },
  created() {
    this.getConsulting()
  },
  methods: {
    getConsulting() {
      // getProductConsulting(1, 1).then(res => {})
    },
  },
}
</script>

<style scoped lang="less">
.main-warp {
  display: flex;
  flex-direction: column;
  //background-color: #ffffff;
  align-items: center;
  .warp-div {
    background-color: #ffffff;
    width: 100%;
  }
}
.img1 {
  width: 100%;
  margin-top: 0.29rem;
}
.top-text {
  width: 100%;
  background-color: #ffffff;
  font-size: 0.14rem;

  font-weight: 400;
  color: #303030;
  line-height: 0.22rem;
}
.img3-container {
  padding: 0 0.77rem;
  margin-top: 0.68rem;
  margin-bottom: 0.37rem;
  img {
    width: 100%;
  }
}
.function-items {
  width: 100%;
  height: 4.23rem;
  background-color: #0b60d6;
  color: #ffffff;

  display: grid;
  grid-template-columns: repeat(3, 3.13rem);
  grid-gap: 0.11rem 0.11rem;
  justify-content: center;
  align-content: center;
  .item {
    //width: 3.24rem;
    height: 1.69rem;
    background: #0572fa;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .title {
      font-size: 0.18rem;
    }
    .sub-title {
      font-size: 0.12rem;
      margin-bottom: 0.05rem;
    }
    .more {
      width: 0.81rem;
      height: 0.25rem;
      background: #1caff7;
      font-size: 0.12rem;
      line-height: 0.25rem;
    }
  }
}
.img-container {
  margin: 0 0.77rem;
  img {
    width: 100%;
  }
}
.img5-container {
  padding: 0 0.77rem;
  img {
    width: 100%;
    margin-left: -0.14rem;
  }
}
</style>
