<template>
  <el-dialog title="申请成功" :visible.sync="isShow" width="14.65rem">
    <div class="main-container">
      <div class="container">
        <img src="../../assets/images/zm_qrcode.png" alt="" />
        <div style="margin-top: 0.11rem; margin-bottom: 0.18rem">扫码关注筑美科技公众号</div>
        <div>敬请留意客服电话：400-0068-552</div>
        <div class="ok-btn" @click="hide">提交成功</div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "SuccessDialog",
  data() {
    return {
      isShow: false,
    }
  },
  methods: {
    show() {
      this.isShow = true
    },
    hide() {
      this.isShow = false
    },
  },
}
</script>

<style scoped lang="less">
@import "../../assets/styles/dialog.less";
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 0.17rem;

  font-weight: 500;
  color: #080707;
  margin-top: 0.5rem;
  .img {
    width: 1.7rem;
    height: 1.7rem;
  }
}
.ok-btn {
  width: 3.91rem;
  height: 0.37rem;
  background: #33a954;
  opacity: 1;
  font-size: 0.16rem;
  font-weight: 500;
  color: #ffffff;
  line-height: 0.37rem;
  margin-top: 0.65rem;
  margin-bottom: 1rem;
  cursor: pointer;
}
</style>
