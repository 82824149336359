<template>
  <div id="app">
    <BannerOne v-if="isPc" :img="bannerData.img" :title="bannerData.title" :sub-title="bannerData.subtitle"></BannerOne>
    <wrap class="warp-container">
      <div class="main-case">
        <blue-title class="my-blue" title="案例" en-title="Company history"></blue-title>
        <div class="info">
          <div class="main">
            <div class="title">{{ introduceInfo.introduce_title }}</div>
            <div class="en">{{ introduceInfo.introduce_english_title }}</div>
            <div class="desc">{{ introduceInfo.introduce_subtitle }}</div>
            <div class="operation">
              <div class="op-main">
                <div class="item">
                  <img src="../../assets/images/case/case_10.png" alt="" />
                  <div>{{ introduceInfo.introduce_author }}</div>
                </div>
                <div class="item">
                  <img class="mini" src="../../assets/images/case/case_9.png" alt="" />
                  <div>{{ introduceInfo.introduce_views }}</div>
                </div>
                <div class="item">
                  <img class="mini" src="../../assets/images/case/case_15.png" alt="" />
                  <div>{{ introduceInfo.introduce_dzs }}</div>
                </div>
                <div class="item">
                  <img class="mini" src="../../assets/images/case/case_14.png" alt="" />
                  <div>{{ introduceInfo.introduce_share_num }}</div>
                </div>
              </div>
              <div class="op-sub" v-if="isPc" @mouseenter="wapHover" @mouseleave="wapHover">
                <img src="../../assets/images/case/case_8.png" alt="" />
                <div>手机阅读</div>
                <div class="qr-item" v-if="isShowQr">
                  <div class="qr-text">微信扫一扫</div>
                  <div class="qr-text">分享本文</div>
                  <div class="qr-div">
                    <vue-qr class="vue-qr" :text="qrUrl"></vue-qr>
                  </div>
                </div>
              </div>
            </div>
            <div class="main-info">
              <div>项目时间：{{ introduceInfo.introduce_item_date }}</div>
              <div>项目名称：{{ introduceInfo.introduce_item_name }}</div>
              <div>项目内容：{{ introduceInfo.introduce_item_content }}</div>
              <div>项目地址：{{ introduceInfo.introduce_item_address }}</div>
            </div>
            <div v-html="introduceInfo.introduce_text_introduction" class="html-text"></div>
          </div>
        </div>
      </div>
      <div class="sub-info" v-if="isPc">
        <div class="sub-title">看报道</div>
        <div class="report-item" v-for="item in reportList" @click="toReport(item)">
          <div class="ri-main">
            <div class="rim-title">{{ item.title }}</div>
            <div class="rim-time">{{ item.create_time }}</div>
          </div>
          <img :src="item.img" class="ri-img" alt="" />
        </div>
        <div class="sub-title">相关推荐</div>
        <div class="recommend-item" v-for="item in recommendList" @click="toRecommend(item)">
          <img :src="item.img" class="rci-img" />
          <div class="rci-title">{{ item.title }}</div>
          <div class="rci-time">{{ item.create_time }}</div>
        </div>
      </div>
    </wrap>
  </div>
</template>

<script>
import { isMobile } from "@/utils"
import BannerOne from "@/components/BannerOne"
import { getExamplesTopImg, getMoreCommonExamplesDetails, getMoreCommonExamplesReport, getMoreCommonExamplesRecommend } from "@/api/case"
import Wrap from "@/components/common/Wrap"
import BlueTitle from "@/components/BlueTitle"

export default {
  name: "CaseInfo",
  components: { BlueTitle, Wrap, BannerOne },
  data() {
    return {
      id: null,
      bannerData: {
        img: "",
        title: "",
        subtitle: "",
      },
      introduceInfo: {},
      isPc: true,
      reportList: [],
      recommendList: [],
      qrUrl: null,
      isShowQr: false,
    }
  },
  beforeCreate() {
    if (!this.$app.isPc) {
      document.querySelector("body").setAttribute("style", "min-width: initial !important;")
      this.$app.hiddenHeaderAndFooter = true
    }
  },
  beforeDestroy() {
    if (!this.$app.isPc) {
      document.querySelector("body").removeAttribute("style")
      this.$app.hiddenHeaderAndFooter = false
    }
  },
  created() {
    this.id = this.$route.query.id
    this.qrUrl = window.location.href
    if (!this.id) {
      alert("案例不存在!")
      return
    }
    if (isMobile()) {
      this.isPc = false
    } else {
      this.isPc = true
    }
    this.getTopImg()
    this.getMoreCommonExamplesDetails()
    this.getMoreCommonExamplesReport()
    this.getMoreCommonExamplesRecommend()
  },
  methods: {
    wapHover() {
      this.isShowQr = !this.isShowQr
    },
    toReport(e) {
      if (e.type == 1) {
        window.location.href = e.content
      } else {
        this.$router.push({
          name: "InformationDetail",
          query: {
            id: e.content,
          },
        })
      }
    },
    toRecommend(e) {
      if (e.type == 1) {
        window.location.href = e.content
      } else {
        this.$router.push({
          name: "InformationDetail",
          query: {
            id: e.content,
          },
        })
      }
    },
    getTopImg() {
      getExamplesTopImg().then(res => {
        this.bannerData = res.data
      })
    },
    getMoreCommonExamplesDetails() {
      getMoreCommonExamplesDetails(this.id).then(res => {
        this.introduceInfo = res.data
      })
    },
    getMoreCommonExamplesReport() {
      getMoreCommonExamplesReport(this.id).then(res => {
        this.reportList = res.data
      })
    },
    getMoreCommonExamplesRecommend() {
      getMoreCommonExamplesRecommend(this.id).then(res => {
        this.recommendList = res.data
      })
    },
  },
}
</script>

<style scoped lang="less">
.warp-container {
  display: flex;
  background-color: #ededed;
  .main-case {
    padding: 0 0.3rem;
    flex: 1;
    background-color: #ffffff;
  }
  .sub-info {
    margin-left: 10px;
    width: 2.2rem;
    background-color: #ffffff;
    padding: 0 0.2rem;
  }
}
.my-blue {
  padding: 0.36rem 0;
}
.info {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 0.3rem;

  .icon {
    height: 0.76rem;
    width: 0.76rem;
    margin-right: 0.23rem;
  }

  .main {
    margin-top: -0.05rem;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .title {
      font-size: 0.29rem;
      text-align: left;
      font-weight: 500;
      color: #141414;
    }

    .en {
      font-size: 0.15rem;

      font-weight: 500;
      color: #626262;
    }

    .desc {
      font-size: 0.18rem;

      font-weight: 500;
      color: #626262;
    }
  }

  .sub {
    margin-top: 0.05rem;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .star {
      font-size: 0.22rem;

      font-weight: 400;
      color: #fca518;
    }

    .desc {
      font-size: 0.15rem;

      font-weight: 500;
      color: #626262;
    }
  }
}
.main-info {
  display: flex;
  flex-direction: column;
  align-items: self-start;
  font-size: 0.16rem;
  text-align: left;

  font-weight: 400;
  color: #626262;
  line-height: 0.25rem;
}
.operation {
  display: flex;
  justify-content: space-between;
  width: 100%;
  .op-main {
    padding: 0.2rem 0;
    display: flex;
    width: 100%;
  }
  .op-sub {
    margin-top: 0.14rem;
    display: flex;
    align-items: center;
    font-size: 0.15rem;
    font-weight: 500;
    color: #626262;
    flex-shrink: 0;
    cursor: pointer;
    position: relative;
    img {
      width: 0.18rem;
      height: 0.18rem;
      margin-right: 2px;
    }
    .qr-item {
      width: 0.87rem;
      height: 1.05rem;
      background: url("../../assets/images/case/case_20.png") no-repeat center;
      background-size: 100% 100%;
      position: absolute;
      top: 0.45rem;
      left: -0.05rem;
      z-index: 99999;
      padding: 0.2rem 0;
      .qr-text {
        text-align: center;
        font-size: 0.1rem;
      }
      .qr-div {
        height: 0.8rem;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
        .vue-qr {
          height: 0.75rem;
          width: 0.75rem;
          position: initial;
        }
      }
    }
  }

  .item {
    margin-right: 0.2rem;
    font-size: 0.19rem;

    font-weight: 500;
    color: #7c7c7c;
    display: flex;
    align-items: center;
    .mini {
      height: 0.18rem;
      width: 0.2rem;
    }
    > img {
      width: 0.37rem;
      height: 0.37rem;
      object-fit: contain;
      margin-right: 0.08rem;
    }
  }
}
.main-info {
  display: flex;
  flex-direction: column;
  align-items: self-start;
  font-size: 0.16rem;
  text-align: left;

  font-weight: 400;
  color: #626262;
  line-height: 0.25rem;
}
.html-text {
  margin-top: 0.2rem;
  font-size: 0.14rem;
  text-align: justify;
}
.sub-title {
  font-size: 0.13rem;
  font-weight: 500;
  color: #19232d;
  line-height: 0.4rem;
  border-bottom: 1px solid #777777;
  text-align: left;
  margin-top: 0.4rem;
}
.marginStyle {
  //margin-top: @headerHeight;
}
.report-item {
  display: flex;
  margin-top: 0.1rem;
  padding: 0.1rem 0;
  border-bottom: 1px solid #d0d0d0;
  cursor: pointer;
  .ri-main {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: left;

    .rim-title {
      font-size: 0.1rem;
      font-weight: 400;
      color: #000000;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .rim-time {
      font-size: 0.09rem;
      font-weight: 400;
      color: #adadad;
    }
  }
  .ri-img {
    flex-shrink: 0;
    width: 0.48rem;
    height: 0.48rem;
  }
}
.recommend-item {
  margin-top: 0.28rem;
  display: flex;
  flex-direction: column;
  text-align: left;
  cursor: pointer;
  .rci-img {
    width: 100%;
    aspect-ratio: 1.74;
    @supports not (aspect-ratio: 1.74) {
      height: 0.9rem;
    }
  }
  .rci-title {
    margin-top: 0.04rem;
    font-size: 0.12rem;
    font-weight: 400;
    color: #000000;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .rci-time {
    margin-top: 0.14rem;
    font-size: 0.09rem;
    font-weight: 400;
    color: #adadad;
  }
}
</style>
