<template>
  <div id="app">
    <wrap style="height: 100%">
      <div class="list-container">
        <div class="list-item" v-for="(i, k) in list">
          <div class="top-item" @click="toProduct(k)">
            <!--            <img class="top-img" :src="i.img" />-->
            <div class="title">
              {{ i.title }}
            </div>
            <div class="sub-title">整体解决方案</div>
            <div class="more-new">
              了解更多
              <img src="@/assets/images/enter_white.png" alt="" />
            </div>
          </div>
          <div class="item" v-for="j in i.list" @click="clickItem(j)">
            <!--            <img :src="j.icon" alt="" />-->
            <div>{{ j.title }}</div>
          </div>
        </div>
      </div>
    </wrap>
  </div>
</template>

<script>
//解决方案弹窗
import Wrap from "@/components/common/Wrap"
import { getProgrammesData } from "@/api/solution"

export default {
  name: "Solution",
  components: { Wrap },
  data() {
    return {
      list: [],
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      getProgrammesData().then(res => {
        this.list = res.data
      })
    },
    clickItem(e) {
      this.$app.$refs.applyDialog.show(e, true)
    },
    toProduct(e) {
      if (e === 1) {
        this.$router.push({
          name: "ProductPage2",
        })
      } else if (e === 2) {
        this.$router.push({
          name: "ProductPage3",
        })
      } else if (e === 3) {
        this.$router.push({
          name: "ProductPage4",
        })
      } else if (e === 4) {
        this.$router.push({
          name: "ProductPage5",
        })
      } else {
        this.$router.push({
          name: "ProductPage1",
        })
      }

      this.hideMe()
    },
    hideMe() {
      this.$emit("hideMe")
    },
  },
}
</script>

<style scoped lang="less">
#app {
  background-color: #f7f7f7;
  width: 100%;
  //height: 7.42rem;
  padding-bottom: 1rem;
}
.list-container {
  margin-top: 0.35rem;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  justify-content: space-between;
  grid-gap: 7px;

  .list-item {
    background-color: #ffffff;
    padding: 0.13rem 0.07rem 0.35rem 0.07rem;
    .top-item {
      height: 1.3rem;
      background: #0647b9;
      &:hover {
        background: #1180ef;
        .title {
          background: #1180ef;
        }
      }
      .top-img {
        width: 100%;
        aspect-ratio: 2;
        vertical-align: top;
      }
      .title {
        padding-top: 0.45rem;
        font-size: 0.12rem;
        font-weight: 500;
        color: #ffffff;
        //margin-bottom: 0.09rem;
        text-align: center;
      }
      .sub-title {
        font-size: 11px;
        font-family: PingFang SC-Light, PingFang SC;
        font-weight: 300;
        color: #ffffff;
      }
      .more-new {
        padding-top: 0.1rem;
        font-size: .12rem;
        color: #fff;
        img {
          width: .12rem;
        }
      }
    }

    overflow: hidden;
    &:hover {
      cursor: pointer;
    }

    .item {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-top: 0.065rem;
      font-size: 0.12rem;
      width: 98%;
      div {
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
      :hover {
        color: #0647b9;
        cursor: pointer;
      }
      img {
        width: 0.19rem;
        height: 0.19rem;
        margin-right: 0.06rem;
      }
    }
  }
}
</style>
