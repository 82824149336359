<template>
  <div id="app">
    <BannerOne :img="topImgData.img" :title="topImgData.title" :sub-title="topImgData.subtitle"></BannerOne>
    <wrap>
      <div class="main-wrap">
        <div class="product-container">
          <div class="product-item" :style="{ 'background-color': colorList[k] }" v-for="(item, k) in goodsList" :key="item.id" @click="toProductDetail(item)">
            <div class="main">
              <div class="name">
                <div class="n-title">{{ item.title }}</div>
                <img src="@/assets/images/more_white.png" alt="" />
              </div>
              <div class="desc">{{ item.subtitle }}</div>
            </div>
            <img class="goods-img" :src="item.img" />
          </div>
        </div>
        <div class="line-gray"></div>
        <div class="product-main" v-for="item in typesList">
          <div class="blue-title">{{ item.name }}</div>
          <div class="project-container">
            <div class="project-item" v-for="(j, index) in item.list">
              <img :src="j.icon" alt="" />
              <div class="right">
                <div class="main">
                  <div class="left">
                    <el-tooltip class="item" effect="dark" :content="j.title" placement="bottom">
                      <div class="title">{{ j.title }}</div>
                    </el-tooltip>
                    <!--                <div class="sub">{{ j.title }}</div>-->
                  </div>
                  <div class="btn" @click="clickItem(j)">获取</div>
                </div>
                <div class="line" v-if="index < calShowIndex(item)"></div>
              </div>
            </div>
          </div>
          <div class="project-big" v-if="item.list_deom.length > 0">
            <div class="main" v-for="k in item.list_deom" @click="showVideo(k)">
              <div class="img-bg">
                <img class="bg" :src="k.img" alt="" />
                <div class="shadow">
                  <img class="play" src="../assets/images/product/play.png" alt="" />
                </div>
              </div>
              <div class="title">{{ k.title }}</div>
              <div class="sub">{{ k.subtitle }}</div>
            </div>
          </div>
          <div v-else style="height: 0.2rem"></div>
        </div>
      </div>
    </wrap>
  </div>
</template>

<script>
import { getProductConsulting, getProductsData, getProductsTopImg } from "@/api/product"
import Wrap from "@/components/common/Wrap"
import BannerOne from "@/components/BannerOne"

export default {
  name: "ProductCenterNew",
  components: { Wrap, BannerOne },
  data() {
    return {
      goodsList: [],
      typesList: [],
      topImgData: {},
      pibgColor: "background-color: #363636;",
      colorList: ["#363636", "#5505a5", "#009d00", "#ff8700", "#0901ff", "#CD5C5C", "#DAA520"],
    }
  },
  created() {
    this.getTopImg()
    this.getSwiperData()
    this.getConsulting()
  },
  computed: {},
  methods: {
    getTopImg() {
      getProductsTopImg().then(res => {
        this.topImgData = res.data
      })
    },
    calShowIndex(i) {
      let remainder = i.list.length % 5
      let num = i.list.length - remainder
      if (remainder == 0) {
        num -= 5
      }
      return num
    },
    getSwiperData() {
      getProductsData().then(res => {
        this.goodsList = res.data
      })
    },
    getConsulting() {
      getProductConsulting().then(res => {
        this.typesList = res.data
      })
    },
    toProductDetail(i) {
      this.$router.push({
        name: "ProductDetail",
        query: {
          id: i.id,
        },
      })
    },
    clickItem(e) {
      this.$app.$refs.applyDialog.show(e)
    },
    showVideo(e) {
      this.$app.$refs.videoDialog.show({
        url: e.video,
      })
    },
  },
}
</script>

<style scoped lang="less">
#app {
  min-height: 100%;
  //padding-top: 0.48rem;
  width: 100%;
  //height: 7.42rem;
  //padding-bottom: 0.5rem;
}
.main-wrap {
  min-height: 100%;
  background-color: white;
  width: calc(100% - 0.2rem);
  padding: 0 0.1rem 0.5rem 0.1rem;
}
.top-banner {
  width: 100%;
  //height: 2.78rem;
  aspect-ratio: 2.6667;
  position: relative;
  .top-img {
    width: 100%;
    height: 100%;
    vertical-align: top;
  }
  .title {
    position: absolute;
    font-size: 0.29rem;
    font-weight: 600;
    color: #ffffff;
    top: 35%;
    left: 0.5rem;
  }
  .sub {
    position: absolute;
    font-size: 0.18rem;
    font-weight: 400;
    color: #ffffff;
    top: 48%;
    left: 0.5rem;
  }
}

.product-container {
  padding: 0.1rem 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 0.07rem;
}
.product-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.16rem 0.2rem;
  background-color: #f7f7f8;
  cursor: pointer;
  .main {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .name {
      display: flex;
      align-items: center;

      .n-title {
      //  transform: scale(0.8);
        font-size: 0.20rem;
        font-weight: 500;
        //color: #272727;
        color: white;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
      img {
        margin-left: 0.05rem;
      }
    }
    .desc {
      font-size: 0.18rem;
      transform: scale(0.9);
      font-weight: 400;
      //color: #7c7c7c;
      color: white;
      margin-left: -0.05rem;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }
  .goods-img {
    width: 1.1rem;
    height: 1.1rem;
    border-radius: 0.55rem;
    object-fit: cover;
  }
}
.line-gray {
  width: 100%;
  height: 1px;
  border-top: 1px solid #707070;
  transform-origin: 50% 100%;
  transform: scaleY(0.5);
  margin-top: 0.3rem;
}
.blue-title {
  font-size: 0.18rem;
  font-weight: 600;
  color: #0747b9;
  text-align: left;
  padding: 0.18rem 0;
}
.project-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 0.11rem;
  .project-item {
    height: 0.4rem;
    display: flex;
    justify-content: space-between;
    margin-top: 0.1rem;
    img {
      width: 0.24rem;
      height: 0.24rem;
      margin-right: 0.11rem;
      flex-shrink: 0;
    }
    .right {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .main {
        flex: 1;
        display: flex;
        justify-content: space-between;
        .left {
          flex: 1;
          .title {
            text-align: left;
            font-size: 0.16rem;
            transform: scale(0.9);
            font-weight: 500;
            color: #272727;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            //margin-left: -0.1rem;
          }
          .sub {
            text-align: left;
            font-size: 0.1rem;
            transform: scale(0.6);
            font-weight: 400;
            color: #7c7c7c;
            margin-left: -0.09rem;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
          }
        }
        .btn {
          flex-shrink: 0;
          width: 0.5rem;
          height: 0.2rem;
          line-height: 0.2rem;
          background: #f2f2f7;
          border-radius: 7px 7px 7px 7px;
          font-size: 0.15rem;
          transform: scale(0.8);
          font-weight: 500;
          color: #3f7ef6;
          cursor: pointer;
        }
      }
      .line {
        width: 100%;
        height: 1px;
        background-color: #707070;
        transform: scaleY(0.3);
      }
    }
  }
}
.project-big {
  margin: 0.1rem 0 0.3rem 0;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 0.11rem;
  .main {
    cursor: pointer;
    .img-bg {
      width: 100%;
      height: 1.37rem;
      position: relative;
      .bg {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
      }
      .shadow {
        //background: rgba(0, 0, 0, 0.7);
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .play {
          width: 0.21rem;
          height: 0.25rem;
          z-index: 2;
        }
      }
    }
    .title {
      text-align: left;
      font-size: 0.18rem;
      font-weight: 500;
      transform: scale(0.9);
      color: #272727;
      margin-top: 0.06rem;
      margin-left: -0.1rem;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
    .sub {
      text-align: left;
      font-size: 0.17rem;
      transform: scale(0.8);
      font-weight: 400;
      color: #7c7c7c;
      margin-left: -0.2rem;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }
}
.product-main {
}
</style>
