<template>
  <div class="index-header" @touchmove.prevent>
    <wrap class="pc-tab" :class="isBlue ? 'custom-tab' : ''">
      <img class="logo" src="../../assets/images/zm_logo.png" @click="reload" />
      <div class="content">
        <div
          class="item"
          :class="{
            selected: $route.path == '/' || $route.path.includes('/Home'),
          }"
          @click="$router.replace('/Index/Home')"
        >
          首页
        </div>
        <div
          class="item"
          :class="{
            selected: $route.path.includes('/productCenter'),
          }"
          @click="$router.replace('/productCenter')"
        >
          产品中心
        </div>
        <div
          class="item"
          :class="{
            selected: $route.path.includes('/page'),
            hover: isShowSolution,
          }"
          @mouseenter="showSolutionTab"
          @mouseleave="hideSolutionTab"
        >
          解决方案
        </div>
        <div class="item" :class="{ selected: $route.path.includes('/cases') }" @click="$router.replace('/cases')">经典案例</div>
        <div class="item" :class="{ selected: $route.path.includes('/about') }" @click="$router.replace('/about')">关于筑美</div>
        <!--        <div class="item" @click="toManage">企业登录</div>-->
      </div>
    </wrap>
    <transition name="slide-top">
      <div class="pc_sub_tab" v-show="isShowProduct" @mouseenter="showProductTab" @mouseleave="hideProductTab">
        <Product @hideMe="hideProductTab"></Product>
        <!--        <Solution></Solution>-->
      </div>
    </transition>
    <transition name="slide-top">
      <div class="pc_sub_tab" v-show="isShowSolution" @mouseenter="showSolutionTab" @mouseleave="hideSolutionTab">
        <Solution @hideMe="hideSolutionTab"></Solution>
      </div>
    </transition>
  </div>
</template>

<script>
import Wrap from "./Wrap"
import Product from "@/components/Product"
import Solution from "@/components/Solution"

export default {
  name: "IndexHeader",
  components: { Solution, Product, Wrap },
  data: () => ({
    lithiumLeft: 0,
    isMenuOpen: false,
    isBlue: false,
    isShowSolution: false,
    isShowProduct: false,
  }),
  mounted() {
    window.addEventListener("scroll", this.handleScroll, true)
  },
  methods: {
    showSolutionTab() {
      clearTimeout(this.showSolutionTimer)
      this.isShowSolution = true
    },
    hideSolutionTab() {
      this.showSolutionTimer = setTimeout(() => {
        this.isShowSolution = false
      }, 100)
    },
    showProductTab() {
      clearTimeout(this.showProductTimer)
      this.isShowProduct = true
    },
    hideProductTab() {
      this.showProductTimer = setTimeout(() => {
        this.isShowProduct = false
      }, 100)
    },
    handleScroll(e) {
      const scrollTop = e.target.documentElement.scrollTop
      if (scrollTop > 600) {
        this.isBlue = true
      } else {
        this.isBlue = false
      }
    },
    reload() {
      if (!this.$route.path.includes("/Home")) {
        this.$router.replace("Home")
      } else {
        location.reload()
      }
    },
    toManage() {
      location.href = ""
    },
    replace(path) {
      this.isMenuOpen = false
      this.$router.replace(path)
    },
  },
}
</script>

<style lang="less" scoped>
.index-header {
  //min-width: 900px;
  min-width: 1240px;
  width: 100%;
  position: fixed;
  z-index: 99;
  top: 0;
  .pc-tab {
    position: relative;
    z-index: 2;
    height: @headerHeight;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgba(0, 0, 0, 0.6);
    .logo {
      width: 1.3rem;
      pointer-events: auto;
      cursor: pointer;
    }
    .content {
      align-self: stretch;
      display: flex;
      justify-content: flex-end;
      justify-items: center;
      align-items: stretch;
      .item {
        position: relative;
        width: 0.77rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.12rem;
        color: #ffffff;
        cursor: pointer;
        transition: font-size 0.15s;
        //&:last-child {
        //  margin-right: -0.39rem;
        //}
        &.selected,
        &:hover,
        &.hover {
          //font-size: 0.2rem;
          color: #ffffff;
          //font-weight: bold;
          background-color: #2889f2;
        }
      }
    }
  }
  .custom-tab {
    background: linear-gradient(166deg, #24278b 0%, #01096c 100%);
    box-shadow: 0 0 0.1rem rgba(0, 0, 0, 0.04);
  }
  .pc_sub_tab {
    z-index: 1;
    position: absolute;
    left: 0;
    bottom: 0;
    transform: translateY(100%);
    width: 100%;
    background: #f2f2f2;
    display: flex;
    align-items: center;
    box-shadow: 0 0 0.2rem rgba(0, 0, 0, 0.15);
  }

  .slide-top-enter {
    transform: translateY(0) !important;
    opacity: 0;
  }
  .slide-top-enter-active {
    transition: transform 0.3s ease-out, opacity 0.25s;
  }
  .slide-top-leave-active {
    transition: transform 0.3s ease-out, opacity 0.3s;
    transform: translateY(0) !important;
    opacity: 0;
  }
}
</style>
