<template>
  <div id="container">
    <img :src="spliceUrl(item)" alt="" v-for="item in list" />
  </div>
</template>

<script>
export default {
  name: "Application",
  props: {
    list: [],
  },
  methods: {
    spliceUrl(e) {
      return require(`../../assets/images/product/np_${e}.png`)
    },
  },
}
</script>

<style scoped lang="less">
#container {
  padding: 0 0.77rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1px;
  justify-content: center;

  & :last-child {
    grid-column-start: span 2;
  }

  //width: 100%;
  > img {
    width: 100%;
  }
}
</style>
