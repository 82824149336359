<template>
  <div class="container">
    <div class="title">
      {{ title }}
      <span class="en" v-if="enTitle">/ {{ enTitle }}</span>
    </div>
    <div class="sub-title">{{ subTitle }}</div>
  </div>
</template>

<script>
export default {
  name: "BlueTitle",
  props: {
    title: "",
    enTitle: "",
    subTitle: "",
  },
}
</script>

<style scoped lang="less">
.container {
  background-color: #ffffff;
  //padding: 0.5rem 0.78rem;
  //padding: 1.3rem 0.78rem 0.7rem 0.78rem;
  padding: 1rem 0.78rem 0.5rem 0.78rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  .title {
    font-size: 0.28rem;

    font-weight: 500;
    color: #0646c1;
    .en {
      font-size: 0.2rem;
    }
  }
  .sub-title {
    font-size: 0.14rem;

    font-weight: 400;
    color: #0646c1;
  }
}
</style>
