<template>
  <div class="divider" :class="noPadding ? 'no-padding' : ''"></div>
</template>

<script>
export default {
  name: "MyDivider",
  props: {
    noPadding: false,
  },
}
</script>

<style scoped lang="less">
.divider {
  position: relative;
  margin-top: 0.25rem;
  padding-bottom: 0.3rem;
  &::before {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 100%;
    height: 1px;
    border-top: 1px solid #707070;
    transform-origin: 50% 100%;
    transform: scaleY(0.3);
  }
}
.no-padding {
  padding: 0;
  margin: 0;
}
</style>
