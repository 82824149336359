<template>
  <div id="app">
    <div class="items">
      <img src="../assets/images/product/jg_25.png" alt="" />
      <div class="title">AI监控抓拍系统：实时监控、有效取证</div>
      <div class="sub-title">AI monitoring capture system</div>
    </div>
    <div class="items">
      <img src="../assets/images/product/jg_26.png" alt="" />
      <div class="title">食安追溯系统：来源可查、去向可追</div>
      <div class="sub-title">Food safety traceability system</div>
    </div>
    <div class="items">
      <img src="../assets/images/product/jg_27.png" alt="" />
      <div class="title">食安检测系统：快速检测、登记在册</div>
      <div class="sub-title">Food safety detection system</div>
    </div>
    <div class="items">
      <img src="../assets/images/product/jg_28.png" alt="" />
      <div class="title">价格直采系统：远程采集、实时掌握</div>
      <div class="sub-title">Price direct system</div>
    </div>
    <div class="items">
      <img src="../assets/images/product/jg_29.png" alt="" />
      <div class="title">实时预警系统：动态监管、有效处理</div>
      <div class="sub-title">Real-time alerts system</div>
    </div>
    <div class="items">
      <img src="../assets/images/product/jg_30.png" alt="" />
      <div class="title">数字扫描采集系统：便捷操作、电子管理</div>
      <div class="sub-title">Digital scanning and acquisition system</div>
    </div>
    <div class="items">
      <img src="../assets/images/product/jg_31.png" alt="" />
      <div class="title">现场移动管理系统：办公高效、处理迅速</div>
      <div class="sub-title">On-site mobile management system</div>
    </div>
    <div class="items">
      <img src="../assets/images/product/jg_32.png" alt="" />
      <div class="title">大数据分析系统：数据汇总、数据赋能</div>
      <div class="sub-title">Big data analysis system</div>
    </div>
    <div class="items">
      <img src="../assets/images/product/jg_33.png" alt="" />
      <div class="title">设备管理系统：实时工况、远程协作</div>
      <div class="sub-title">Equipment management system</div>
    </div>
  </div>
</template>

<script>
//产品中心 应用系统
export default {
  name: "UtilitySystem2",
}
</script>

<style scoped lang="less">
#app {
  background: white;
  display: grid;
  grid-template-columns: repeat(3, 3rem);
  justify-content: center;
  align-items: center;
}
.items {
  font-weight: 400;
  color: #303030;
  padding: 0.12rem;
  img {
    height: 0.74rem;
    width: 0.74rem;
  }
  .title {
    font-size: 0.12rem;
  }
  .sub-title {
    font-size: 0.1rem;
  }
}
</style>
