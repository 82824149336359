import request from "@/utils/request"

//产品中心-顶部产品
export function getProductsData() {
  return request({
    url: `/api.php/Products/getProductsData`,
    method: "get",
  })
}

//产品中心-产品列表
export function getProductConsulting() {
  return request({
    url: `/api.php/Products/getProductConsulting`,
    method: "get",
  })
}

//产品中心-产品咨询 内容详情
export function getProductConsultingDetails(id) {
  return request({
    url: `/api.php/Products/getProductConsultingDetails`,
    method: "get",
    params: { id },
  })
}

// 产品中心 - 顶部产品 - 产品详情
export function getProductDetails(id) {
  return request({
    url: `/api.php/Products/getProductDetails`,
    method: "get",
    params: { id },
  })
}

// 产品中心-提交产品咨询
export function getConsultingrRcord(data) {
  return request({
    url: `/api.php/Products/getConsultingrRcord`,
    method: "get",
    params: data,
  })
}

// 产品中心-产品顶部banner接口
export function getProductsTopImg() {
  return request({
    url: `/api.php/Products/getProductsTopImg`,
    method: "get",
  })
}
