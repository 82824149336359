<template>
  <div id="app">
    <banner-one :img="require('../assets/images/product/wldp_1.png')" title="未来店铺" sub-title="未来社区数智门店新模式"></banner-one>
    <wrap class="main-warp">
      <div class="warp-div">
        <img class="img1" src="../assets/images/product/wldp_2.png" />
        <div>
          <div class="top-title">未来店铺</div>
          <div class="top-text">未来店铺是专为社区购物打造的24h社区门店。以门店的智慧系统为基础，以全国品牌供应链为仓储，</div>
          <div class="top-text">构建产销服务一体的智能收银+供应链+互联网运营的新零售平台。颠覆传统店铺模式，让中国实体店铺不再难做。</div>
          <div class="top-text">本地化生鲜搭配全国供应链，筛选出源头直采的优质食材，开启社区品质生活。</div>
        </div>
        <blue-title-center title="创新模式" sub-title="创新研发、系统云集，协同运转、智能高效"></blue-title-center>
        <div class="model-container">
          <div class="item" v-for="item in modelList">
            <img :src="item.img" alt="" />
            <div class="title">{{ item.title }}</div>
            <div class="sub-title">{{ item.subTitle }}</div>
          </div>
        </div>
        <black-title class="bt-title" title="系统架构" sub-title="核心大脑、一网统管、门户搭建、一体平台"></black-title>
        <div class="bt-img">
          <img src="../assets/images/product/wldp_9.png" />
        </div>
        <black-title class="bt-title" title="核心产品" sub-title="未来店铺开店一站式设备"></black-title>
        <div class="core-container">
          <div class="item" v-for="item in coreList">
            <img :src="item.img" alt="" />
            <div class="title">{{ item.title }}</div>
            <div class="sub-title">{{ item.subTitle }}</div>
            <template v-if="item.rightTitle">
              <div class="right-title">{{ item.rightTitle }}</div>
              <div class="right-sub-title">{{ item.rightSubTitle }}</div>
            </template>
          </div>
        </div>
        <blue-title-center title="供应链资源" sub-title="蒙牛品牌 温氏集团 五常大米"></blue-title-center>
        <div class="chain-container">
          <img src="../assets/images/product/wldp_38.png" alt="" />
          <img src="../assets/images/product/wldp_39.png" alt="" />
          <img src="../assets/images/product/wldp_40.png" alt="" />
          <img src="../assets/images/product/wldp_41.png" alt="" />
          <img src="../assets/images/product/wldp_42.png" alt="" />
          <img src="../assets/images/product/wldp_43.png" alt="" />
        </div>
        <blue-title-center title="核心优势" sub-title="创新研发，转变形式，数智运营"></blue-title-center>
      </div>
    </wrap>
    <advantage :page="3"></advantage>
    <wrap class="main-warp">
      <div class="warp-div">
        <blue-sub-title title="专属服务 Partners"></blue-sub-title>
        <div class="img5-container">
          <img src="../assets/images/product/jg_34.png" />
        </div>
        <black-title class="bt-title" title="案例" sub-title="构建开放协同的超融合生态、确保超前的平台技术机构"></black-title>
        <div class="case-board">
          <div class="item" v-for="item in caseList">
            <img :src="item.img" alt="" />
            <div>{{ item.title }}</div>
          </div>
        </div>
      </div>
    </wrap>
    <contactus></contactus>
  </div>
</template>

<script>
import BannerOne from "@/components/BannerOne"
import Wrap from "@/components/common/Wrap"
import BlueTitleCenter from "@/components/BlueTitleCenter"
import UtilitySystem from "@/components/UtilitySystem"
import BlueSubTitle from "@/components/BlueSubTitle"
import ClassicCase from "@/components/ClassicCase"
import Advantage from "@/components/Advantage"
import ProductSolution from "@/components/ProductSolution"
import Partners from "@/components/Partners"
import Contactus from "@/components/Contactus"
import Application from "@/components/product/Application"
import BlackTitle from "@/components/BlackTitle"
import BlueTitle from "@/components/BlueTitle"
import SceneTitle from "@/components/SceneTitle"
import UtilitySystem2 from "@/components/UtilitySystem2"
//未来店铺
export default {
  name: "ProductPage4",
  components: { UtilitySystem2, SceneTitle, BlueTitle, BlackTitle, Application, Contactus, Partners, ProductSolution, Advantage, ClassicCase, BlueSubTitle, UtilitySystem, BlueTitleCenter, Wrap, BannerOne },
  data() {
    return {
      coreList: [
        {
          img: require("../assets/images/product/wldp_31.png"),
          title: "社区门店",
          subTitle: "智能收银系统",
        },
        {
          img: 'https://www.zhumei.net/uploads/20230407/7d0595994ff1c94bf693e163c848acde.png',
          title: "品牌商",
          subTitle: "智能收银系统",
        },
        {
          img: require("../assets/images/product/wldp_30.png"),
          title: "消费者",
          subTitle: "会员小程序",
          rightTitle: "商家",
          rightSubTitle: "菜商宝APP",
        },
        {
          img: "https://www.zhumei.net/uploads/20230407/b7b396fb912396af66eb8e7968af276e.png",
          title: "社区门店",
          subTitle: "自助收银+导购",
        },
        {
          img: 'https://www.zhumei.net/uploads/20230407/683cd72fa961014fb0149c113b75d8b0.png',
          title: "门店价目",
          subTitle: "广告+价目单",
        },
        {
          img: require("../assets/images/product/wldp_26.png"),
          title: "品牌数据展示中心",
          subTitle: "智能驾驶舱大屏",
        },
      ],
      modelList: [
        {
          img: require("../assets/images/product/wldp_3.png"),
          title: "为什么是会员店？",
          subTitle: "因为我们专为社区会员服务",
        },
        {
          img: require("../assets/images/product/wldp_4.png"),
          title: "为什么是组合装？",
          subTitle: "为会员提供高品质 高性价比分类组合",
        },
        {
          img: require("../assets/images/product/wldp_5.png"),
          title: "为什么预约？",
          subTitle: "即能确保有货有能为您节省购物时间",
        },
        {
          img: require("../assets/images/product/wldp_6.png"),
          title: "预制菜",
          subTitle: "配制食谱 即可下厨",
        },
        {
          img: require("../assets/images/product/wldp_7.png"),
          title: "热销爆款",
          subTitle: "源头直采 品牌特供",
        },
        {
          img: require("../assets/images/product/wldp_8.png"),
          title: "24小时自提",
          subTitle: "全天营养不打烊",
        },
      ],
      caseList: [
        {
          img: require("../assets/images/product/wldp_16.png"),
          title: "优化勤务，人员管控从传统向科技转变；",
        },
        {
          img: require("../assets/images/product/wldp_17.png"),
          title: "规范执法，权益保障从被动向主动转变；",
        },
        {
          img: require("../assets/images/product/wldp_15.png"),
          title: "升级安全，从人盯眼看向主动控制转变；",
        },
        {
          img: require("../assets/images/product/wldp_14.png"),
          title: "减负增效，执法工作从体力向智力转变；",
        },
        {
          img: require("../assets/images/product/wldp_12.png"),
          title: "",
        },
      ],
    }
  },
}
</script>

<style scoped lang="less">
.main-warp {
  display: flex;
  flex-direction: column;
  //background-color: #ffffff;
  align-items: center;
  .warp-div {
    background-color: #ffffff;
    width: 100%;
  }
}
.img1 {
  width: 100%;
  margin-top: 0.29rem;
  //height: 4.6rem;
}
.img2 {
  //height: 4.56rem;
  width: 10.33rem;
}
.img3 {
  width: 8.7rem;
  margin-top: 0.68rem;
  margin-bottom: 0.1rem;
}
.img4 {
  width: 10rem;
}
.img5-container {
  padding: 0 0.77rem;
  img {
    width: 100%;
    margin-left: -0.14rem;
  }
}

.function-items {
  width: 100%;
  height: 4.23rem;
  background-color: #0b60d6;
  color: #ffffff;

  display: grid;
  grid-template-columns: repeat(3, 3.24rem);
  grid-gap: 0.11rem 0.11rem;
  justify-content: center;
  align-content: center;
  .item {
    width: 3.24rem;
    height: 1.69rem;
    background: #0572fa;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .title {
      font-size: 0.18rem;
    }
    .sub-title {
      font-size: 0.12rem;
      margin-bottom: 0.05rem;
    }
    .more {
      width: 0.81rem;
      height: 0.25rem;
      background: #1caff7;
      font-size: 0.12rem;
      line-height: 0.25rem;
    }
  }
}
.bt-title {
  padding-left: 0.77rem;
}
.bt-img {
  //width: 10rem;
  padding: 0 0.77rem;
  img {
    width: 100%;
  }
}
.scene4 {
  display: grid;
  padding: 0 0.78rem;
  grid-template-columns: repeat(4, 1fr);
  justify-content: space-between;
  grid-gap: 0.11rem;
  .item {
    img {
      width: 100%;
      height: 1.4rem;
      vertical-align: middle;
    }
    div {
      width: 100%;
      height: 0.43rem;
      background: #4770c1;
      line-height: 0.43rem;
      font-size: 0.13rem;

      font-weight: 400;
      color: #ffffff;
    }
  }
}
.scene3 {
  display: grid;
  padding: 0 0.78rem;
  grid-template-columns: repeat(3, 1fr);
  justify-content: space-between;
  grid-gap: 0.08rem;
  .item {
    img {
      width: 100%;
      height: 1.4rem;
      vertical-align: middle;
    }
    div {
      width: 100%;
      height: 0.43rem;
      background: #4770c1;
      line-height: 0.43rem;
      font-size: 0.13rem;

      font-weight: 400;
      color: #ffffff;
    }
  }
}
.scene2 {
  display: grid;
  padding: 0 0.78rem;
  grid-template-columns: repeat(2, 1fr);
  justify-content: space-between;
  grid-gap: 0.08rem;
  margin-bottom: 1rem;
  .item {
    img {
      width: 100%;
      height: 2.43rem;
      vertical-align: middle;
    }
    div {
      width: 100%;
      height: 0.43rem;
      background: #4770c1;
      line-height: 0.43rem;
      font-size: 0.13rem;

      font-weight: 400;
      color: #ffffff;
    }
  }
}
.case-board {
  margin-bottom: 1.5rem;
  padding: 0 0.77rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 4px;
  justify-content: center;

  & :last-child {
    grid-column-start: span 2;
    //img {
    //  height: 3.11rem !important;
    //}
  }

  .item {
    img {
      width: 100%;
      //height: 2.54rem;
      vertical-align: middle;
      background-color: #091686;
    }
    div {
      text-align: left;
      padding-left: 0.5rem;
      height: 0.43rem;
      line-height: 0.43rem;
      font-size: 0.15rem;
      font-weight: 400;
      color: #696969;
    }
  }
}
.top-text {
  width: 100%;
  background-color: #ffffff;
  font-size: 0.14rem;

  font-weight: 400;
  color: #303030;
  line-height: 0.22rem;
}
.top-title {
  font-size: 0.24rem;

  font-weight: 400;
  color: #404040;
  margin-top: 0.45rem;
  margin-bottom: 0.1rem;
}
.model-container {
  display: grid;
  grid-template-columns: repeat(3, 3.2rem);
  grid-row-gap: 0.3rem;
  justify-content: center;
  img {
    width: 0.98rem;
    height: 0.98rem;
  }
  .item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: 400;
    color: #303030;
    align-items: center;
    .title {
      font-size: 0.15rem;

      margin-top: 0.13rem;
      margin-bottom: 0.03rem;
    }
    .sub-title {
      font-size: 0.13rem;
    }
  }
}
.core-container {
  padding: 0 0.77rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0.13rem;
  justify-content: center;

  & > :first-child,
  :last-child {
    grid-column-start: span 2;
    //height: 4.8rem !important;
  }

  //width: 100%;
  .item {
    position: relative;

    //height: 2.5rem;

    color: #ffffff;
    .title {
      position: absolute;
      left: 0.06rem;
      top: 0.09rem;
      font-size: 0.24rem;
      font-weight: 500;
      line-height: 0.41rem;
    }
    .sub-title {
      position: absolute;
      left: 0.06rem;
      top: 0.38rem;
      font-size: 0.16rem;
      font-weight: 400;
      line-height: 0.32rem;
    }
    .right-title {
      position: absolute;
      right: 0.06rem;
      top: 0.09rem;
      font-size: 0.24rem;
      font-weight: 500;
      line-height: 0.41rem;
    }
    .right-sub-title {
      position: absolute;
      right: 0.06rem;
      top: 0.38rem;
      font-size: 0.16rem;
      font-weight: 400;
      line-height: 0.32rem;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
}
.chain-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
  grid-gap: 0.13rem;
  padding: 0 0.77rem;

  img {
    //height: 2rem;
    width: 100%;
  }
}
</style>
