<template>
  <div id="app" @touchmove>
    <index-header v-if="!hiddenHeaderAndFooter" className="header" />
    <transition name="fade">
      <router-view class="app-page" />
      <!--      <perfect-scrollbar ref="scroll">-->
      <!--      </perfect-scrollbar>-->
    </transition>
    <index-footer v-if="!hiddenHeaderAndFooter" className="footer" />
    <apply-dialog ref="applyDialog"></apply-dialog>
    <contact-dialog ref="contactDialog"></contact-dialog>
    <success-dialog ref="successDialog"></success-dialog>
    <video-dialog ref="videoDialog"></video-dialog>
    <el-image-viewer
      v-if="imageViewerShow"
      :z-index="2999"
      :initial-index="imageViewerInitialIndex"
      :on-close="
        () => {
          imageViewerShow = false
        }
      "
      :url-list="imageViewerSrcList"
    />

    <div class="tel-main" @mouseover="telMainShow = true" @mouseleave="telMainShow = false">
      <img class="wx_logo" src="@/assets/images/home-phone.png" alt=""  >
      <div class="qrcodeMain" v-if="telMainShow">
        <div class="title">了解更多，请联系售前顾问 180-0588-7019</div>
        <img class="sanjiao" src="@/assets/images/sanjiao.png" alt=""  >
      </div>
    </div>

    <div class="wx-main" @mouseover="wxMainShow = true" @mouseleave="wxMainShow = false">
      <img class="wx_logo" src="@/assets/images/wx_phone.png" alt=""  >
      <div class="qrcodeMain" v-if="wxMainShow">
        <div class="title">扫码咨询售前顾问</div>
        <img src="@/assets/images/zm_erweima.png" />
        <img class="sanjiao" src="@/assets/images/sanjiao.png" alt=""  >
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue"
import IndexHeader from "./components/common/IndexHeader"
import IndexFooter from "./components/common/IndexFooter"
import ApplyDialog from "@/components/dialog/ApplyDialog"
import ContactDialog from "@/components/dialog/ContactDialog"
import SuccessDialog from "@/components/dialog/SuccessDialog"
import ElImageViewer from "element-ui/packages/image/src/image-viewer"
import VideoDialog from "@/components/dialog/VideoDialog"
import { isMobile } from "@/utils"
export default {
  name: "App",
  components: { VideoDialog, SuccessDialog, ContactDialog, IndexFooter, IndexHeader, ApplyDialog, ElImageViewer },
  data() {
    return {
      imageViewerShow: false,
      imageViewerSrcList: [],
      imageViewerInitialIndex: 0,
      videoViewerShow: false,
      videoViewerSrc: null,
      isPc: true,
      hiddenHeaderAndFooter: false,
      telMainShow: false,
      wxMainShow: false,
    }
  },
  created() {
    Vue.prototype.$app = this
    if (isMobile()) {
      this.isPc = false
    } else {
      this.isPc = true
    }
    this.$router.afterEach((to, from) => {
      setTimeout(() => {
        document.body.scrollTop = 0
        document.documentElement.scrollTop = 0
      }, 100)
    })
  },
  methods: {
    previewImage(src, srcList = [src]) {
      this.imageViewerSrcList = srcList
      let srcIndex = srcList.indexOf(src)
      this.imageViewerInitialIndex = srcIndex >= 0 ? srcIndex : 0
      this.imageViewerShow = true
    },
  },
}
</script>
<style lang="less">
//html {
// //灰度
//  filter: grayscale(100%);
//  -webkit-filter: grayscale(100%);
//  -moz-filter: grayscale(100%);
//  -o-filter: grayscale(100%);
//  -webkit-filter: grayscale(1);
//}
.el-popup-parent--hidden {
  padding-right: 4px !important;
}
html,
body {
  &::-webkit-scrollbar {
    /*动条整体样式*/
    width: 4px; /*高宽分别对应横竖滚动条的尺寸*/
    height: 4px;
  }
  &::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 5px;
    -webkit-box-shadow: inset005pxrgba(0, 0, 0, 0.2);
    background: rgba(0, 0, 0, 0.2);
  }
  &::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    -webkit-box-shadow: inset005pxrgba(0, 0, 0, 0.2);
    border-radius: 0;
    background: rgba(0, 0, 0, 0.1);
  }
}
html {
  margin: 0;
  padding: 0;
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
  min-width: 1240px;
  //overflow-x: hidden;
  //overflow-y: auto;
  //&::-webkit-scrollbar {
  //  width: 1px;
  //}
  /*滚动条样式*/

  -webkit-tap-highlight-color: transparent;
  user-select: none;
  -webkit-text-size-adjust: none;
  font-size: 16px;
}
#app {
  font-family: PingFang SC-Medium, PingFang SC, MicrosoftYaHei;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  //background-color: #ededed;
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
.app-page {
  transition-duration: 0.8s;
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #ededed;
}
a {
  text-decoration: none;
}
img {
  pointer-events: none;
}
input,
textarea {
  -webkit-appearance: none;
  outline: none;
  margin: 0;
  padding: 0;
  border: none;
  background: none;
}

.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  transition: opacity 0.1s ease-in;
}
.fade-leave-active {
  opacity: 0;
  transition: opacity 0s ease-out;
}
.fade-in-enter {
  opacity: 0;
}
.fade-in-enter-active {
  transition: opacity 0.25s ease-out;
}
.fade-in-leave-active {
  transition: opacity 0s;
  transition-delay: 0s !important;
}
.insert-enter {
  height: 0 !important;
}
.insert-enter-active {
  transition: height 0.25s;
}
.insert-leave-active {
  height: 0 !important;
  transition: height 0.25s;
}
.tel-main {
  position: fixed;
  right: 0.1rem;
  bottom: 2.1rem;
  z-index: 99;
  .wx_logo {
    display: block;
    width: .7rem;
    height: .7rem;
    position: relative;
    cursor: pointer;
    z-index: 99;
  }
  .qrcodeMain {
    position: absolute;
    width: 2rem;
    top: .1rem;
    right: .8rem;
    background: #3662EC;
    .title {
      font-size: 14px;
      font-weight: 400;
      color: #FFFFFF;
      padding-top: 0.1rem;
    }
    .sanjiao {
      position: absolute;
      width: 0.06rem;
      margin: 0.06rem;
      top: 0.12rem;
      right: -0.12rem;
    }
  }

}
.wx-main {
  position: fixed;
  right: 0.1rem;
  bottom: 1.2rem;
  z-index: 99;
  .wx_logo {
    display: block;
    width: .7rem;
    height: .7rem;
    position: relative;
    cursor: pointer;
    z-index: 99;
  }
  .qrcodeMain {
    position: absolute;
    width: 1.8rem;
    top: 0;
    right: .8rem;
    background: #3662EC;
    .title {
      font-size: 16px;
      font-weight: 400;
      color: #FFFFFF;
      padding-top: 0.2rem;
    }
    img {
      width: 1.2rem;
      margin: .1rem 0;
    }
    .sanjiao {
      position: absolute;
      width: 0.06rem;
      margin: 0.06rem;
      top: 0.22rem;
      right: -0.12rem;
    }
  }

}
</style>
