<template>
  <div class="container">
    <div class="title">
      {{ title }}
    </div>
    <div class="sub-title">{{ subTitle }}</div>
  </div>
</template>

<script>
export default {
  props: {
    title: "",
    subTitle: "",
  },
}
</script>

<style scoped lang="less">
.container {
  background-color: #ffffff;
  margin-top: 0.5rem;
  padding: 0.5rem 0.78rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  .title {
    font-size: 0.28rem;

    font-weight: 500;
    color: #0646c1;
  }
  .sub-title {
    font-size: 0.14rem;

    font-weight: 400;
    color: #0646c1;
  }
}
</style>
