import request from "@/utils/request"

//案例-顶部展示图
export function getExamplesTopImg() {
  return request({
    url: `/api.php/example/getExamplesTopImg`,
    method: "get",
  })
}

//案例-经典案例-列表接口
export function getExamplesList() {
  return request({
    url: `/api.php/example/getExamplesList`,
    method: "get",
  })
}

//案例-经典案例-看介绍接口
export function getExamplesClassic(id) {
  return request({
    url: `/api.php/example/getExamplesClassic`,
    method: "get",
    params: { id },
  })
}

//案例-经典案例-看图片接口
export function getExamplesClassicImgs(id) {
  return request({
    url: `/api.php/example/getExamplesClassicImgs`,
    method: "get",
    params: { id },
  })
}

//案例-经典案例-看产品
export function getExamplesClassicProducts(id) {
  return request({
    url: `/api.php/example/getExamplesClassicProducts`,
    method: "get",
    params: { id },
  })
}

//案例-经典案例-看数据
export function getExamplesClassicData(id) {
  return request({
    url: `/api.php/example/getExamplesClassicData`,
    method: "get",
    params: { id },
  })
}

//案例-经典案例-看评论
export function getExamplesClassicComment(id) {
  return request({
    url: `/api.php/example/getExamplesClassicComment`,
    method: "get",
    params: { id },
  })
}

//案例-经典案例-看报道
export function getExamplesClassicReport(id) {
  return request({
    url: `/api.php/example/getExamplesClassicReport`,
    method: "get",
    params: { id },
  })
}

//案例-经典案例-相关推荐
export function getExamplesClassicRecommend(id) {
  return request({
    url: `/api.php/example/getExamplesClassicRecommend`,
    method: "get",
    params: { id },
  })
}

//案例-更多案例-列表（普通案例）
export function getMoreCommonExamplesList(data) {
  return request({
    url: `/api.php/example/getMoreCommonExamplesList`,
    method: "get",
    params: data
  })
}

//案例-更多案例-详情
export function getMoreCommonExamplesDetails(id) {
  return request({
    url: `/api.php/example/getMoreCommonExamplesDetails`,
    method: "get",
    params: { id },
  })
}
//案例-更多案例-看报道
export function getMoreCommonExamplesReport(id) {
  return request({
    url: `/api.php/example/getMoreCommonExamplesReport`,
    method: "get",
    params: { id },
  })
}

//案例-更多案例-相关推荐
export function getMoreCommonExamplesRecommend(id) {
  return request({
    url: `/api.php/example/getMoreCommonExamplesRecommend`,
    method: "get",
    params: { id },
  })
}
