<template>
  <div id="app">
    <banner-one :img="require('../assets/images/product/jg_5.png')" title="未来监管新平台" sub-title="从源头到终端 全程可视化监管"></banner-one>
    <wrap class="main-warp">
      <div class="warp-div">
        <black-title title="数字大脑" sub-title="构建开放协同的超融合生态、确保超前的平台技术架构"></black-title>
        <div class="img-container">
          <img src="../assets/images/product/jg_23.png" />
        </div>
        <black-title title="数字驾驶舱" sub-title="领导驾驶舱、部门驾驶舱、手机驾驶舱"></black-title>
        <blue-title class="bt-title" title="应用场景" sub-title="快速检测、一键溯源、菜价直采、AI抓拍、在线防疫、预警处理"></blue-title>
        <div class="img-container">
          <img src="../assets/images/product/jg_4.png" />
        </div>
        <black-title style="padding-top: 0.3rem" title="平台架构" sub-title="核心大脑、一网统管、门户搭建、一体平台"></black-title>
        <div class="platform-container">
          <img src="../assets/images/product/jg_24.png" />
        </div>
        <black-title title="应用场景" sub-title="快速检测、一键溯源、菜价直采、AI抓拍、在线防疫、预警处理"></black-title>
        <scene-title style="margin-top: 0" title="视频AI应用场景-市场管理"></scene-title>
        <div class="scene4">
          <div class="item" v-for="item in scene4List">
            <img :src="item.img" alt="" />
            <div>{{ item.title }}</div>
          </div>
        </div>
        <scene-title title="视频AI应用场景-安防&消防"></scene-title>
        <div class="scene3">
          <div class="item" v-for="item in scene3List">
            <img :src="item.img" alt="" />
            <div>{{ item.title }}</div>
          </div>
        </div>
        <scene-title title="视频AI应用场景-疫情防控"></scene-title>
        <div class="scene2">
          <div class="item" v-for="item in scene2List">
            <img :src="item.img" alt="" />
            <div>{{ item.title }}</div>
          </div>
        </div>
        <blue-title-center title="功能模块" sub-title="智慧监管的数智终端，数据驱动、动态监管更高效。"></blue-title-center>
      </div>
    </wrap>
    <div class="function-items">
      <div class="item">
        <div class="title">智慧监管</div>
        <div class="sub-title">Epidemic prevention</div>
       <!--        <div class="more">了解更多</div>-->
      </div>
      <div class="item">
        <div class="title">智能抓拍</div>
        <div class="sub-title">In The Booking</div>
       <!--        <div class="more">了解更多</div>-->
      </div>
      <div class="item">
        <div class="title">实时预警</div>
        <div class="sub-title">Wisdom trading</div>
       <!--        <div class="more">了解更多</div>-->
      </div>
      <div class="item">
        <div class="title">动态监管</div>
        <div class="sub-title">Wisdom management</div>
       <!--        <div class="more">了解更多</div>-->
      </div>
      <div class="item">
        <div class="title">驾驶舱</div>
        <div class="sub-title">Wisdom regulation</div>
       <!--        <div class="more">了解更多</div>-->
      </div>
      <div class="item">
        <div class="title">多端联动</div>
        <div class="sub-title">Integrated platform</div>
       <!--        <div class="more">了解更多</div>-->
      </div>
    </div>
    <wrap class="main-warp">
      <div class="warp-div">
        <blue-title-center title="应用系统" sub-title="创新研发、系统云集，协同运转、智能高效"></blue-title-center>
        <utility-system2></utility-system2>
        <blue-sub-title title="专属服务 Partners"></blue-sub-title>
        <div class="img5-container">
          <img src="../assets/images/product/jg_34.png" />
        </div>
        <blue-title-center title="解决方案" id="solution" sub-title="以行业痛点为导向，提供“数据+平台+服务”解决思路，建设信息化、智能化的监督管理体系"></blue-title-center>
        <product-solution :id="3"></product-solution>
        <blue-title-center title="核心优势" sub-title="创新研发，转变形式，数智运营"></blue-title-center>
      </div>
    </wrap>
    <advantage :page="3"></advantage>
    <wrap class="main-warp">
      <div class="warp-div">
        <black-title title="案例" sub-title="构建开放协同的超融合生态、确保超前的平台技术机构"></black-title>
        <div class="case-board">
          <div class="item" v-for="item in caseList">
            <img :src="item.img" alt="" />
            <div>{{ item.title }}</div>
          </div>
        </div>
      </div>
    </wrap>
    <contactus></contactus>
  </div>
</template>

<script>
import BannerOne from "@/components/BannerOne"
import Wrap from "@/components/common/Wrap"
import BlueTitleCenter from "@/components/BlueTitleCenter"
import UtilitySystem from "@/components/UtilitySystem"
import BlueSubTitle from "@/components/BlueSubTitle"
import ClassicCase from "@/components/ClassicCase"
import Advantage from "@/components/Advantage"
import ProductSolution from "@/components/ProductSolution"
import Partners from "@/components/Partners"
import Contactus from "@/components/Contactus"
import Application from "@/components/product/Application"
import BlackTitle from "@/components/BlackTitle"
import BlueTitle from "@/components/BlueTitle"
import SceneTitle from "@/components/SceneTitle"
import UtilitySystem2 from "@/components/UtilitySystem2"
//农批
export default {
  name: "ProductPage3",
  components: { UtilitySystem2, SceneTitle, BlueTitle, BlackTitle, Application, Contactus, Partners, ProductSolution, Advantage, ClassicCase, BlueSubTitle, UtilitySystem, BlueTitleCenter, Wrap, BannerOne },
  data() {
    return {
      caseList: [
        {
          img: require("../assets/images/product/jg_5.png"),
          title: "优化勤务，人员管控从传统向科技转变；",
        },
        {
          img: require("../assets/images/product/jg_6.png"),
          title: "规范执法，权益保障从被动向主动转变；",
        },
        {
          img: require("../assets/images/product/jg_37.png"),
          title: "升级安全，从人盯眼看向主动控制转变；",
        },
        {
          img: require("../assets/images/product/jg_38.png"),
          title: "减负增效，执法工作从体力向智力转变；",
        },
        {
          img: require("../assets/images/product/jg_39.png"),
          title: "加创盈收，市场利润空间从微到厚转变。",
        },
      ],
      scene2List: [
        {
          img: require("../assets/images/product/jg_21.png"),
          title: "对市场内的人群密集进行检测",
        },
        {
          img: require("../assets/images/product/jg_22.png"),
          title: "疫情防控期间、对进入市场人员以及市场经营户是否佩戴口罩进行检测",
        },
      ],
      scene3List: [
        {
          img: require("../assets/images/product/jg_15.png"),
          title: "消防车道被车辆占用检测并识别车牌",
        },
        {
          img: require("../assets/images/product/jg_16.png"),
          title: "消防栓门下是否堆放杂物检测",
        },
        {
          img: require("../assets/images/product/jg_17.png"),
          title: "安全通道 / 逃生通道堵塞检测 ",
        },
        {
          img: require("../assets/images/product/jg_18.png"),
          title: "对市场内打架、肢体冲突行为检测",
        },
        {
          img: require("../assets/images/product/jg_19.png"),
          title: "对市场内可疑人员或黑名单检测",
        },
        {
          img: require("../assets/images/product/jg_20.png"),
          title: "火苗 / 烟雾检测",
        },
      ],
      scene4List: [
        {
          img: require("../assets/images/product/jg_7.png"),
          title: "经营户占道经营行为检测",
        },
        {
          img: require("../assets/images/product/jg_8.png"),
          title: "市场地面卫生检测",
        },
        {
          img: require("../assets/images/product/jg_9.png"),
          title: "市场内人员跌倒行为检测",
        },
        {
          img: require("../assets/images/product/jg_10.png"),
          title: "手推车在市场乱停放检测",
        },
        {
          img: require("../assets/images/product/jg_11.png"),
          title: "非机动车乱停放检测",
        },
        {
          img: require("../assets/images/product/jg_12.png"),
          title: "车牌违停检测并识别车牌",
        },
        {
          img: require("../assets/images/product/jg_13.png"),
          title: "值班人员离岗行为检测",
        },
        {
          img: require("../assets/images/product/jg_14.png"),
          title: "值班人员睡岗行为检测",
        },
      ],
    }
  },
  mounted() {
    if (this.$route.params.isShowSolution) {
      setTimeout(() => {
        document.getElementById("solution").scrollIntoView()
      }, 100)
    }
  },
}
</script>

<style scoped lang="less">
.main-warp {
  display: flex;
  flex-direction: column;
  //background-color: #ffffff;
  align-items: center;
  .warp-div {
    background-color: #ffffff;
    width: 100%;
  }
}
.img5-container {
  padding: 0 0.77rem;
  img {
    width: 100%;
    margin-left: -0.14rem;
  }
}
.function-items {
  width: 100%;
  height: 4.23rem;
  background-color: #0b60d6;
  color: #ffffff;

  display: grid;
  grid-template-columns: repeat(3, 3.13rem);
  grid-gap: 0.11rem 0.11rem;
  justify-content: center;
  align-content: center;
  .item {
    height: 1.69rem;
    background: #0572fa;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .title {
      font-size: 0.18rem;
    }
    .sub-title {
      font-size: 0.12rem;
      margin-bottom: 0.05rem;
    }
    .more {
      width: 0.81rem;
      height: 0.25rem;
      background: #1caff7;
      font-size: 0.12rem;
      line-height: 0.25rem;
    }
  }
}
.scene {
  margin: 0 0.78rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
  grid-gap: 0.05rem;
  .item {
    font-size: 0.15rem;
    font-weight: 400;
    color: #696969;
    > img {
      height: 2.53rem;
    }
  }
}
.bt-title {
  //padding-left: 1rem;
  margin: -0.3rem 0 0.1rem 1rem;
  padding: 0;
}
.scene4 {
  display: grid;
  padding: 0 0.78rem;
  grid-template-columns: repeat(4, 1fr);
  justify-content: space-between;
  grid-gap: 0.11rem;
  .item {
    img {
      width: 100%;
      height: 1.4rem;
      vertical-align: middle;
    }
    div {
      width: 100%;
      height: 0.43rem;
      background: #4770c1;
      line-height: 0.43rem;
      font-size: 0.13rem;

      font-weight: 400;
      color: #ffffff;
    }
  }
}
.scene3 {
  display: grid;
  padding: 0 0.78rem;
  grid-template-columns: repeat(3, 1fr);
  justify-content: space-between;
  grid-gap: 0.08rem;
  .item {
    img {
      width: 100%;
      height: 1.4rem;
      vertical-align: middle;
    }
    div {
      width: 100%;
      height: 0.43rem;
      background: #4770c1;
      line-height: 0.43rem;
      font-size: 0.13rem;

      font-weight: 400;
      color: #ffffff;
    }
  }
}
.scene2 {
  display: grid;
  padding: 0 0.78rem;
  grid-template-columns: repeat(2, 1fr);
  justify-content: space-between;
  grid-gap: 0.08rem;
  margin-bottom: 1rem;
  .item {
    img {
      width: 100%;
      height: 2.43rem;
      vertical-align: middle;
    }
    div {
      width: 100%;
      height: 0.43rem;
      background: #4770c1;
      line-height: 0.43rem;
      font-size: 0.13rem;

      font-weight: 400;
      color: #ffffff;
    }
  }
}
.case-board {
  margin-bottom: 1.5rem;
  padding: 0 0.77rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 4px;
  justify-content: center;

  & :last-child {
    grid-column-start: span 2;
    img {
      height: 3.11rem !important;
    }
  }

  .item {
    img {
      width: 100%;
      height: 2.54rem;
      vertical-align: middle;
      background-color: #091686;
    }
    div {
      text-align: left;
      padding-left: 0.5rem;
      height: 0.43rem;
      line-height: 0.43rem;
      font-size: 0.15rem;
      font-weight: 400;
      color: #696969;
    }
  }
}
.platform-container {
  padding-top: 0.57rem;
  margin: 0 0.77rem;
  img {
    width: 100%;
  }
}
.img-container {
  margin: 0 0.77rem;
  img {
    width: 100%;
  }
}
</style>
