import request from "@/utils/request"

//关于-顶部背景接口
export function getAboutTopImg() {
  return request({
    url: `/api.php/About/getAboutTopImg`,
    method: "get",
  })
}

//关于-头部展示图
export function getAboutTopEnterpriseImg() {
  return request({
    url: `/api.php/About/getAboutTopEnterpriseImg`,
    method: "get",
  })
}

//关于-公司团队
export function getAboutTeam() {
  return request({
    url: `/api.php/About/getAboutTeam`,
    method: "get",
  })
}

//关于-公司资讯
export function getAboutCompanyInformation() {
  return request({
    url: `/api.php/About/getAboutCompanyInformation`,
    method: "get",
  })
}

//关于-业务覆盖
export function getAboutBusiness() {
  return request({
    url: `/api.php/About/getAboutBusiness`,
    method: "get",
  })
}

//关于-媒体报道
export function getAboutMedia() {
  return request({
    url: `/api.php/About/getAboutMedia`,
    method: "get",
  })
}

//关于-公司资讯详情
export function getCompanyInformationDetails(id) {
  return request({
    url: `/api.php/About/getCompanyInformationDetails`,
    method: "get",
    params: { id },
  })
}
