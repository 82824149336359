<template>
  <div id="app">
    <div class="banner-div">
      <img class="banner-img" :src="bannerData.img" />
      <wrap class="banner-warp">
        <div class="b-left">
          <div class="main">{{ bannerData.title }}</div>
          <div class="sub">{{ bannerData.subtitle }}</div>
        </div>
        <div class="b-right">
          <div class="item" v-for="item in bannerData.words">· {{ item }}</div>
        </div>
      </wrap>
    </div>
    <wrap>
      <div class="img-container" v-if="enterpriseImgs.length > 0">
        <template v-for="i in enterpriseImgs">
          <el-image lazy class="img-item" :src="i" :preview-src-list="enterpriseImgs"></el-image>
        </template>
      </div>
      <blue-title title="公司历程" en-title="Company history" sub-title="筑美科技为国内较早的智能导示系统和智慧农贸系统市场运用研发机构…"></blue-title>
      <img src="../assets/images/about/history.png" class="history-img" />
      <blue-title title="业务覆盖" en-title="National coverage" sub-title="筑美科技为全国16个省级市30多个城市…"></blue-title>
      <div class="national">
        <div class="national-stores">
          <swiper class="ns-lmg" :options="imgSwiperOption" v-if="nationalImgs.length > 0">
            <swiper-slide v-for="(item, k) in nationalImgs" :key="k">
              <img :src="item" alt="" />
            </swiper-slide>
          </swiper>
          <div class="ns-outer">
            <swiper class="ns-list" :options="swiperOption" v-if="storeList.length > 0">
              <swiper-slide v-for="(item, k) in storeList" :key="k">
                <div>{{ item }}</div>
              </swiper-slide>
            </swiper>
          </div>
        </div>
        <div class="national-data">
          <div class="nd-item">
            <div class="nd-title">
              <span>10</span>
              <span style="font-size: 0.2rem">+年</span>
            </div>
            <div class="nd-dis">应用系统</div>
          </div>
          <div class="nd-item">
            <div class="nd-title">
              <span>500</span>
              <span style="font-size: 0.2rem">+家</span>
            </div>
            <div class="nd-dis">服务市场</div>
          </div>
          <div class="nd-item">
            <div class="nd-title">
              <span>600000</span>
              <span style="font-size: 0.2rem">+位</span>
            </div>
            <div class="nd-dis">终端用户</div>
          </div>
          <div class="nd-item">
            <div class="nd-title">
              <span>60</span>
              <span style="font-size: 0.2rem">+项</span>
            </div>
            <div class="nd-dis">专利</div>
          </div>
          <div class="nd-item">
            <div class="nd-title">
              <span>100</span>
              <span style="font-size: 0.2rem">+城市</span>
            </div>
            <div class="nd-dis">服务网络</div>
          </div>
          <div class="nd-item">
            <div class="nd-title">
              <span>80</span>
              <span style="font-size: 0.2rem">+套</span>
            </div>
            <div class="nd-dis">应用系统</div>
          </div>
        </div>
      </div>
      <blue-title title="公司团队" en-title="Company team"></blue-title>
      <div class="team">
        <div class="team-item" v-for="item in teamList">
          <el-image class="team-img" :src="item.avatar" />
          <div class="team-name">{{ item.name }}</div>
          <div class="team-title">{{ item.occupation_label }}</div>
        </div>
      </div>
      <template v-if="mediaList.length > 0">
        <blue-title title="媒体报道" en-title="Media reports"></blue-title>
        <video-player ref="mediaPlayer" :options="playerOptions"></video-player>
        <div style="background-color: #ffffff">
          <swiper class="media-swiper" ref="mediaSwiper" :options="mediaSwiperOption">
            <swiper-slide v-for="(item, k) in mediaList" :key="item.id" class="media-swiper-item">
              <img class="media-img" :src="item.img" />
            </swiper-slide>
          </swiper>
        </div>
      </template>
      <blue-title title="公司资讯" en-title=" Information"></blue-title>
      <div class="information">
        <div class="info-item" v-for="item in infoList" @click="toInformation(item)">
          <div class="info-img-div">
            <el-image class="info-img" :src="item.img" />
          </div>
          <div class="info-title">{{ item.title }}</div>
          <div class="info-sub">{{ item.subtitle }}</div>
          <div class="info-date">
            <img src="../assets/images/about/date.png" />
            {{ item.create_time }}
          </div>
        </div>
      </div>
      <blue-title title="企业风采" en-title=" Enterprise style"></blue-title>
      <div class="enterprise">
        <div class="main-item">
          <el-image :src="calEnterpriseList(i)" v-for="(i, k) in enterpriseList1" :key="k" />
        </div>
        <div class="sub-item">
          <el-image :src="calEnterpriseList(i)" v-for="(i, k) in enterpriseList2" :key="k" />
        </div>
      </div>
      <blue-title title="招募服务商" en-title="Recruiting Partners"></blue-title>
      <div class="partner-div" @click="toall">
        <div class="title">加入筑美城市合伙人</div>
        <div class="sub">全国百城追梦人 连接开启创富之路</div>
      </div>
    </wrap>
    <contactus></contactus>
  </div>
</template>

<script>
import Wrap from "@/components/common/Wrap"
import Contactus from "@/components/Contactus"
import BlueTitle from "@/components/BlueTitle"
import { getAboutTopImg, getAboutTopEnterpriseImg, getAboutTeam, getAboutCompanyInformation, getAboutBusiness, getAboutMedia } from "@/api/about"

let vm = null
export default {
  name: "About",
  components: { BlueTitle, Contactus, Wrap },
  data() {
    return {
      //real data++++++++++++++
      bannerData: {},
      enterpriseImgs: [],
      //real data++++++++++++++
      imgList: [],
      enterpriseList1: ["enterprise_19.png", "enterprise_15.png", "enterprise_6.png", "enterprise_18.png"],
      enterpriseList2: ["enterprise_2.png", "enterprise_14.png", "enterprise_20.png", "enterprise_13.png", "enterprise_5.png", "enterprise_1.png", "enterprise_9.png", "enterprise_10.png", "enterprise_8.png", "enterprise_22.png", "enterprise_12.png", "enterprise_16.png", "enterprise_17.png", "enterprise_11.png", "enterprise_21.png", "enterprise_4.png", "enterprise_3.png", "enterprise_7.png"],
      infoList: [],
      teamList: [],
      storeList: [],
      nationalImgs: [],
      imgSwiperOption: {
        effect: "fade",
        fadeEffect: {
          crossFade: true,
        },
        loop: true,
        delay: 5000,
        speed: 1000,
        autoplay: true,
      },
      swiperOption: {
        direction: "vertical",
        slidesPerView: 18,
        speed: 360,
        freeMode: true,
        centeredSlides: true,
        pagination: {
          el: ".swiper-pagination",
        },
        loop: true,
        autoplay: {
          delay: 0,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
      },
      mediaSwiperOption: {
        slidesPerView: 4,
        speed: 2000,
        freeMode: true,
        // loop: true,
        pagination: {
          el: ".swiper-pagination",
        },
        autoplay: {
          delay: 0,
          stopOnLastSlide: false,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        },
        on: {
          click: function () {
            vm.playChange(this.realIndex)
          },
        },
      },
      mediaList: [],
      mediaSelected: {
        video: null,
        img: null,
      },
      playerOptions: {
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [
          {
            type: "video/mp4",
            src: "",
          },
        ],
        poster: "",
      },
    }
  },
  computed: {
    bannerBackImage() {
      return `background: url(${this.bannerData.img}) no-repeat;`
    },
  },
  watch: {
    mediaSelected(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.playerOptions.poster = newVal.img
        this.playerOptions.sources[0].src = newVal.video
      }
    },
  },
  created() {
    vm = this
    this.getBanner()
    this.getTopEnterpriseImg()
    this.getTeam()
    this.getCompanyInformation()
    this.getBusiness()
    this.getMedia()
  },
  methods: {
    calEnterpriseList(e) {
      return require(`../assets/images/about/${e}`)
    },
    toall(){
      this.$router.push({name: "partner"})
    },
    playChange(e) {
      console.log(e)
      const i = this.$refs.mediaSwiper.$swiper.clickedIndex
      this.mediaSelected = this.mediaList[i]
    },
    getBanner() {
      getAboutTopImg().then(res => {
        this.bannerData = res.data
      })
    },
    getTopEnterpriseImg() {
      getAboutTopEnterpriseImg().then(res => {
        this.enterpriseImgs = res.data.imgs
      })
    },
    getTeam() {
      getAboutTeam().then(res => {
        this.teamList = res.data
      })
    },
    getCompanyInformation() {
      getAboutCompanyInformation().then(res => {
        this.infoList = res.data.data
      })
    },
    getBusiness() {
      getAboutBusiness().then(res => {
        this.nationalImgs = res.data.imgs
        this.storeList = res.data.words
      })
    },
    getMedia() {
      getAboutMedia().then(res => {
        this.mediaList = res.data
        if (this.mediaList.length > 0) {
          this.mediaSelected = this.mediaList[0]
        }
      })
    },
    toInformation(e) {
      if (e.type == 1) {
        //url
        // window.location.href = e.content
        window.open(e.content, "_blank")
      } else {
        let routeData = this.$router.resolve({
          name: "InformationDetail",
          query: {
            id: e.id,
            info: true,
          },
        })
        window.open(routeData.href, "_blank")
      }
    },
  },
}
</script>
<style scoped lang="less">
::v-deep .video-js {
  width: 100%;
  aspect-ratio: 2.2;
  height: auto;
  .vjs-big-play-button {
    top: 50% !important;
    left: 50% !important;
    margin-left: -1.5em;
    margin-top: -1em
  }
  @supports not (aspect-ratio: 2.2) {
    height: 4.35rem;
  }
}

.banner-div {
  width: 100%;
  //height: 7.2rem;
  //background: url("../assets/images/about_banner.png") no-repeat;
  //background-size: 100% 100% !important;
  color: #ffffff;
  position: relative;
  .banner-img {
    //position: absolute;
    //left: 0;
    //top: 0;
    width: 100%;
    vertical-align: top;
    z-index: -1;
    object-fit: cover;
    aspect-ratio: 2.6667;

    @supports not (aspect-ratio: 2.6667) {
      height: 7.2rem;
    }
    //aspect-ratio: 3.8;
    //
    //@supports not (aspect-ratio: 3.8) {
    //  height: 5.1rem;
    //}
  }
  .banner-warp {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 1.6rem;

    //margin-top: 1rem;
    height: 70%;
    .b-left {
      margin-left: 0.78rem;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      text-align: left;

      .main {
        font-size: 0.4rem;
        font-weight: 600;
      }
      .sub {
        font-size: 0.34rem;
        line-height: 0.59rem;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
    }
    .b-right {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: self-start;
      height: 100%;
      .item {
        font-size: 0.18rem;
        color: #ffffff;
        line-height: 0.32rem;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
    }
  }
}
.img-container {
  padding: 0.44rem 0.77rem 0 0.77rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
  justify-items: center;
  background-color: white;
  grid-gap: 4px;
  .img-item {
    aspect-ratio: 1.73;
    //object-fit: contain;
    width: 100%;
    @supports not (aspect-ratio: 1.73) {
      height: 1.5rem;
    }
  }
  ::v-deep img {
    pointer-events: auto;
  }
}
.partner-div {
  background: url("../assets/images/about/partner.png") no-repeat;
  background-size: 100% 101%;
  width: 100%;
  height: 4.53rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .title {
    font-size: 0.53rem;

    font-weight: 600;
    color: #ffffff;
  }
  .sub {
    font-size: 0.47rem;

    font-weight: 400;
    color: #ffffff;
  }
}
.enterprise {
  padding: 0 0.79rem;
  background-color: white;
  .main-item {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 3px;
    .el-image {
      width: 100%;
    }
  }
  .sub-item {
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    grid-gap: 3px;
    margin-top: 3px;
    .el-image {
      width: 100%;
    }
  }
}
.information {
  display: grid;
  padding: 0 0.79rem;
  grid-template-columns: repeat(4, 1fr);
  justify-content: space-between;
  background-color: white;
  grid-gap: 0.04rem;
  .info-item {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 0.12rem;
    //width: 2.4037rem;
    //height: 2.7255rem;
    background-color: #f0f0f0;
    text-align: left;
    border-radius: 4px 4px 4px 4px;

    &:hover {
      .info-img {
        transition: 1s;
        transform: scale(1.1);
      }
    }
    .info-img-div {
      width: 100%;
      //height: 1.392rem;
      aspect-ratio: 1.7266;
      overflow: hidden;
      @supports not (aspect-ratio: 1.7266) {
        height: 1.15rem;
      }
      .info-img {
        width: 100%;
        height: 100%;
        //background: #ffffff;X
      }
    }

    .info-title {
      font-size: 0.11rem;
      font-weight: 600;
      color: #333333;
      //line-height: 0.49rem;
      margin-bottom: 0.1rem;
      margin-top: 0.16rem;
      padding: 0 0.12rem;
      //white-space: nowrap;
      //overflow: hidden;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
    .info-sub {
      font-size: 0.09rem;
      font-weight: 400;
      color: #7e7e7e;
      line-height: 0.16rem;
      //height: 0.5rem;
      padding: 0 0.11rem;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .info-date {
      display: flex;
      align-items: center;
      img {
        width: 0.1226rem;
        height: 0.1127rem;
        margin-right: 0.05rem;
      }
      font-size: 0.1rem;

      font-weight: 400;
      color: #696969;
      //line-height: 0.16rem;
      padding: 0.2rem 0.12rem;
    }
  }
}
.team {
  display: grid;
  padding: 0 0.79rem;
  grid-template-columns: repeat(4, 1fr);
  justify-content: space-between;
  background-color: white;
  grid-gap: 0.2rem;
  color: #222222;
  .team-item {
    text-align: left;
    margin-bottom: 0.3rem;
    width: 100%;
    //height: 100%;
    .team-img {
      width: 100%;
      //height: 2.18rem;
    }
    .team-name {
      font-size: 0.16rem;
    }
    .team-title {
      font-size: 0.13rem;
    }
  }
}
.national {
  .national-stores {
    //background: url("../assets/images/about/national_cover.png") no-repeat;
    //background-size: 100% 100%;
    width: 100%;
    height: 5.2rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    .ns-outer {
      width: 34%;
      height: 4.8rem;
      font-size: 0.13rem;
      background-color: #ffffff;
      margin-right: 0.42rem;
      display: flex;
      align-items: center;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      border-radius: 5px;
      .ns-list {
        height: 90%;
      }
      z-index: 2;
    }
    .ns-lmg {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
  }
  .national-data {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-color: #ffffff;
    padding: 0.3rem 0;
    .nd-item {
      text-align: center;
      .nd-title {
        font-size: 0.27rem;
        color: #132cb5;
      }
      .nd-dis {
        font-size: 0.14rem;
        font-weight: 400;
        color: #898787;
      }
    }
  }
}
::v-deep .swiper-container-free-mode > .swiper-wrapper {
  -webkit-transition-timing-function: linear; /*之前是ease-out, 实现平滑滚动*/
  -moz-transition-timing-function: linear;
  -ms-transition-timing-function: linear;
  -o-transition-timing-function: linear;
  transition-timing-function: linear;
  margin: 0 auto;
}
.history-img {
  width: 100%;
  vertical-align: top;
  //height: 5.14rem;
}
::v-deep .vcp-progress-played {
  background-color: #1080ef;
}
::v-deep .play-pause-layer svg path {
  fill: #000000;
}
::v-deep .play-pause-layer svg g rect {
  fill: #000000;
}
::v-deep .settings-control {
  display: none;
}
::v-deep .volume-control .btn-control-panel .progress .volume-current {
  background-color: #1080ef;
}
.media-swiper {
  margin: 0 0.7rem;
  padding-top: 0.15rem;
  background-color: #ffffff;
  .media-swiper-item {
    margin-right: 0.09rem;
    &:hover {
      cursor: pointer;
    }

    .media-img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
