import axios from "axios"
import { MessageBox, Message } from "element-ui"

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 20000, // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    config.headers["Content-Type"] = "application/json"
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const data = response.data

    // if the custom code is not 1, it is judged as an error.
    if (data.code == 1) {
      return data
    } else {
      Message({
        message: data.msg || "发生错误",
        type: "error",
        duration: 5 * 1000,
      })

      return Promise.reject(new Error(data.msg || "发生错误"))
    }
  },
  error => {
    console.log("err" + error) // for debug
    // 当响应异常时做一些处理
    if (error && error.response) {
      switch (error.response.status) {
        case 400:
          error.message = "请求错误(400)"
          break
        case 401:
          error.message = "请求错误(401)"
          break
        case 403:
          error.message = "请求错误(403)"
          break
        case 404:
          error.message = "请求出错(404)"
          break
        case 408:
          error.message = "请求超时(408)"
          break
        case 500:
          error.message = "服务器错误(500)"
          break
        case 501:
          error.message = "服务器错误(501)"
          break
        case 502:
          error.message = "网络错误(502)"
          break
        case 503:
          error.message = "服务不可用(503)"
          break
        case 504:
          error.message = "网络超时(504)"
          break
        case 505:
          error.message = "HTTP版本不受支持(505)"
          break
        default:
          error.message = `连接出错(${error.response.status})!`
      }
    } else {
      error.message = "服务器响应失败"
    }
    Message({
      message: error.message,
      type: "error",
      duration: 5 * 1000,
    })
    return Promise.reject(error)
  }
)

export default service
