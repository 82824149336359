<template>
  <div class="container">
    <div class="title">{{ title }}</div>
    <div class="description">{{ description }}</div>
    <div class="imgs">
      <div class="img-item" v-for="item in imgs">
        <img :src="item.img" alt="" />
        <div>{{ item.des }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ApplicationVersion",
  props: {
    title: "",
    description: "",
    imgs: [],
  },
}
</script>

<style scoped lang="less">
.container {
  padding: 0 0.77rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  font-weight: 400;
  color: #404040;
  text-align: left;
  .title {
    font-size: 0.25rem;
  }
  .description {
    font-size: 0.16rem;
    line-height: 0.3rem;
    padding: 0.1rem 0;
  }
  .imgs {
    padding-top: 0.52rem;
    padding-bottom: 0.79rem;
    width: 100%;
    //display: grid;
    //grid-gap: 0.6rem;
    //grid-template-columns: repeat(auto-fit, 1.6rem);
    //justify-content: center;
    display: flex;
    justify-content: space-evenly;

    .img-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      img {
        width: 1.8rem;
        height: 1.8rem;
      }
      div {
        margin-top: 0.16rem;
        font-size: 0.16rem;

        font-weight: 400;
        color: #404040;
      }
    }
  }
}
</style>
