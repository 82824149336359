<template>
  <div id="app">
    <banner-one :img="require('../assets/images/product/zndd_1.png')" title="智能导示" sub-title="未来智能导示"></banner-one>
    <wrap class="main-warp">
      <div class="warp-div">
        <img class="img1" src="../assets/images/product/zndd_46.png" />
        <div>
          <div class="top-title">智能导视系统智能化商场的新标签</div>
          <div class="top-text">智能导视系统采用高科技先进手段和一流设备,具备轻松互动、海量数据存储、操作简单、信息安全、操作快捷等无与伦比的优势。</div>
          <div class="top-text">结合人性化的设计，为各种公共区域量身定做实用高效的智能导视系统,适用于大型商场、会展中心、</div>
          <div class="top-text">医院、住宅等公共区域。随着跨平台云同步的实现、移动互联网的发展,筑美科技将触摸屏终端、手机终端、</div>
          <div class="top-text">Web终端等不同终端之间的数据进行共享,将消费者的行为串联成一个整体</div>
        </div>
        <black-title class="bt-title" title="系统功能" sub-title="集供应链、收银、会员为一体的店铺数智化应用终端"></black-title>
        <div class="system-container">
          <div class="item" v-for="item in systemList">
            <img :src="item.img" alt="" />
            <div>{{ item.title }}</div>
          </div>
        </div>
        <div class="system-container2">
          <div class="item" v-for="item in systemList2">
            <img :src="item.img" alt="" />
            <div class="content" :style="calSystemBgColor(item.color)">
              <div class="title">{{ item.title }}</div>
              <div class="sub-title">{{ item.subTitle }}</div>
            </div>
          </div>
        </div>
        <black-title class="bt-title" title="应用场景" sub-title="构建开放协同的超融合生态、确保超前的平台技术机构"></black-title>
        <div class="application-container">
          <div class="item" v-for="item in applicationList">
            <img :src="item.img" alt="" />
            <div>{{ item.title }}</div>
          </div>
        </div>
        <black-title class="bt-title" title="应用版本" sub-title="导示系统利用不同的场景的应用"></black-title>
        <application-version :title="item.title" :description="item.description" :imgs="item.imgs" v-for="item in versionList" :key="item.title"></application-version>
        <black-title class="bt-title" title="案例" sub-title="构建开放协同的超融合生态、确保超前的平台技术机构"></black-title>
        <div class="case-container">
          <img src="../assets/images/product/zndd_40.png" alt="" />
          <div class="sub">
            <div class="item" v-for="item in caseList">
              <img :src="item.img" alt="" />
              <div>{{ item.title }}</div>
            </div>
          </div>
        </div>
        <blue-title-center title="核心优势" sub-title="创新研发，转变形式，数智运营"></blue-title-center>
      </div>
    </wrap>
    <advantage :page="5"></advantage>
    <wrap class="main-warp">
      <div class="warp-div">
        <blue-sub-title title="专属服务 Partners"></blue-sub-title>
        <div class="img5-container">
          <img src="../assets/images/product/zndd_47.png" />
        </div>
      </div>
    </wrap>
    <contactus></contactus>
  </div>
</template>

<script>
import BannerOne from "@/components/BannerOne"
import Wrap from "@/components/common/Wrap"
import BlueTitleCenter from "@/components/BlueTitleCenter"
import UtilitySystem from "@/components/UtilitySystem"
import BlueSubTitle from "@/components/BlueSubTitle"
import ClassicCase from "@/components/ClassicCase"
import Advantage from "@/components/Advantage"
import ProductSolution from "@/components/ProductSolution"
import Partners from "@/components/Partners"
import Contactus from "@/components/Contactus"
import Application from "@/components/product/Application"
import BlackTitle from "@/components/BlackTitle"
import BlueTitle from "@/components/BlueTitle"
import SceneTitle from "@/components/SceneTitle"
import UtilitySystem2 from "@/components/UtilitySystem2"
import ApplicationVersion from "@/components/product/ApplicationVersion"
//导视
export default {
  name: "ProductPage5",
  components: { ApplicationVersion, UtilitySystem2, SceneTitle, BlueTitle, BlackTitle, Application, Contactus, Partners, ProductSolution, Advantage, ClassicCase, BlueSubTitle, UtilitySystem, BlueTitleCenter, Wrap, BannerOne },
  data() {
    return {
      versionList: [
        {
          imgs: [
            {
              img: require("../assets/images/product/zndd_7.png"),
              des: "农贸市场",
            },
            {
              img: require("../assets/images/product/zndd_34.png"),
              des: "农批市场",
            },
            {
              img: require("../assets/images/product/zndd_35.png"),
              des: "专业市场",
            },
          ],
          title: "市场版 Shop Edition",
          description: "运用科技手段建立一个长效、全面、可持续发展的市场信息化管理体系，重点实现“数据采集、信息公示、需求查询、行业监管\n" + "四大主要功能。使市场在管理、服务和监管等各个方面，能更快更好地实现信息网络化、工作规范化、管理现代化。  ",
        },
        {
          imgs: [
            {
              img: require("../assets/images/product/zndd_35.png"),
              des: "上海科技馆",
            },
            {
              img: require("../assets/images/product/zndd_12.png"),
              des: "杭州低碳科技馆",
            },
            {
              img: require("../assets/images/product/zndd_22.png"),
              des: "敦煌国际会展中心",
            },
          ],
          title: "展陈版 Exhibition version",
          description: "筑美智慧展陈系统包括展会版系统（主办方）和展示版系统（参展商用），都由“展会帮”云端平台提供统一管理和维护服务。\n" + "展会版为主办方提供展会精准推广、展会展位导视、展会参展商查询、互动广告等服务；展示版可以为参展商减少成本、增加\n" + "吸引力、提高布展效率。系统基于最新的大尺寸触控等互动技术和互联网技术为参展企业和展会主办机构提供智慧化的展陈方\n" + "案，让展会因智慧而精彩，让参展因科技而轻松。",
        },
        {
          imgs: [
            {
              img: require("../assets/images/product/zndd_31.png"),
              des: "融科",
            },
            {
              img: require("../assets/images/product/zndd_48.png"),
              des: "绿城",
            },
          ],
          title: "房产版 Exhibition version",
          description: "利用三维数字图像技术、网络科技、人工智能等计算机科学的前沿技术，具有数字全景沙盘、虚拟样板房、数字三维户型透视、宣传片、楼\n" + "书等多媒体互动功能，还提供房型检索、按揭计算等查询服务。可显著提升售楼处接待效率和顾客体验，方便不同项目重复使用，也方便在\n" + "酒店、社区等目标人群集中的地方开展房产宣传和销售。",
        },
        {
          imgs: [
            {
              img: require("../assets/images/product/zndd_43.png"),
              des: "公司",
            },
            {
              img: require("../assets/images/product/zndd_15.png"),
              des: "工厂",
            },
            {
              img: require("../assets/images/product/zndd_42.png"),
              des: "茶馆",
            },
            {
              img: require("../assets/images/product/zndd_23.png"),
              des: "报社",
            },
          ],
          title: "企业版 Enterprise Edition",
          description: "筑美科技通过云计算、数据分析和人工智能等科技帮助企业实现数字化转型，抓住稍纵即逝的产业机遇，获得数据洞察和商业价值，协助企业\n" + "走上智能之路。",
        },
        {
          imgs: [
            {
              img: require("../assets/images/product/zndd_3.png"),
              des: "医院",
            },
            {
              img: require("../assets/images/product/zndd_2.png"),
              des: "汽车",
            },
            {
              img: require("../assets/images/product/zndd_27.png"),
              des: "展品",
            },
            {
              img: require("../assets/images/product/zndd_32.png"),
              des: "地产",
            },
          ],
          title: "其他版 Other version",
          description: "利用三维数字图像技术、网络科技、人工智能等计算机科学的前沿技术，不仅将数字全景沙盘、虚拟样板房、数字三维户型透视、宣传片、楼\n" + "书等内容包含其中，还将房型检索、按揭计算等工具集成到系统中，方便随时查用。",
        },
      ],
      caseList: [
        {
          img: require("../assets/images/product/zndd_17.png"),
          title: "杭州万象城",
        },
        {
          img: require("../assets/images/product/zndd_18.png"),
          title: "杭州大厦",
        },
        {
          img: require("../assets/images/product/zndd_19.png"),
          title: "龙湖天街",
        },
        {
          img: require("../assets/images/product/zndd_25.png"),
          title: "西湖银泰",
        },
        {
          img: require("../assets/images/product/zndd_14.png"),
          title: "苏州印象城",
        },
        {
          img: require("../assets/images/product/zndd_26.png"),
          title: "无锡万象城",
        },
        {
          img: require("../assets/images/product/zndd_16.png"),
          title: "丽水万地",
        },
        {
          img: require("../assets/images/product/zndd_20.png"),
          title: "万达广场",
        },
        {
          img: require("../assets/images/product/zndd_21.png"),
          title: "湖滨银泰",
        },
        {
          img: require("../assets/images/product/zndd_5.png"),
          title: "第六空间",
        },
      ],
      applicationList: [
        {
          img: require("../assets/images/product/zndd_39.png"),
          title: "购物中心",
        },
        {
          img: require("../assets/images/product/zndd_44.png"),
          title: "专业市场",
        },
        {
          img: require("../assets/images/product/zndd_45.png"),
          title: "博物馆",
        },
        {
          img: require("../assets/images/product/zndd_13.png"),
          title: "科技馆",
        },
        {
          img: require("../assets/images/product/zndd_33.png"),
          title: "售楼部",
        },
        {
          img: require("../assets/images/product/zndd_4.png"),
          title: "医院",
        },
      ],
      systemList2: [
        {
          img: require("../assets/images/product/zndd_41.png"),
          title: "3D导视地图",
          subTitle: "智能导示3D地图可对商场、展馆、楼宇、等建筑进行2D/3D\n" + "模式展现,对用户当前位置进行标注,在地图中可自定义设置文\n" + "字、图片、建筑样式,可自定义显示导示路径。",
          color: "#fffbf8",
        },
        {
          img: require("../assets/images/product/zndd_36.png"),
          title: "智能导视终端",
          subTitle: "筑美智能导视终端采用最新红外线触屏技术,外观,硬件配置、\n" + "屏幕可根据客户需求进行定制。",
          color: "#cbe3e2",
        },
        {
          img: require("../assets/images/product/zndd_37.png"),
          title: "导视后台管理",
          subTitle: "筑美智能导视后台管理系统可对智能导视终端中的各种信息\n" + "进行图文按钮的管理,通过后台的操控可随时调整和控制前后\n" + "台的显示。",
          color: "#e6f4ff",
        },
        {
          img: require("../assets/images/product/zndd_38.png"),
          title: "导视移动端",
          subTitle: "智能导视手机端当用户在智能导视终端机上面查询想去得\n" + "区域,然后系统会提供一条指引线路,然后用户在终端机上\n" + "扫码后,手机上就会显示这条线路图,帮助用户找到这个地方。",
          color: "#f0fdf4",
        },
      ],
      systemList: [
        {
          img: require("../assets/images/product/zndd_41.png"),
          title: "3D地图",
        },
        {
          img: require("../assets/images/product/zndd_8.png"),
          title: "后台管理",
        },
        {
          img: require("../assets/images/product/zndd_9.png"),
          title: "路径引导",
        },
        {
          img: require("../assets/images/product/zndd_28.png"),
          title: "视屏客服",
        },
        {
          img: require("../assets/images/product/zndd_10.png"),
          title: "数据分析",
        },
        {
          img: require("../assets/images/product/zndd_11.png"),
          title: "寻车系统",
        },
        {
          img: require("../assets/images/product/zndd_29.png"),
          title: "预约呼叫",
        },
        {
          img: require("../assets/images/product/zndd_30.png"),
          title: "智慧推送图",
        },
      ],
    }
  },
  methods: {
    calSystemBgColor(e) {
      return `background-color: ${e};`
    },
  },
}
</script>

<style scoped lang="less">
.main-warp {
  display: flex;
  flex-direction: column;
  //background-color: #ffffff;
  align-items: center;
  .warp-div {
    background-color: #ffffff;
    width: 100%;
  }
}
.img1 {
  width: 100%;
  margin-top: 0.29rem;
  //height: 4.6rem;
}
.img2 {
  //height: 4.56rem;
  width: 10.33rem;
}
.img3 {
  width: 8.7rem;
  margin-top: 0.68rem;
  margin-bottom: 0.1rem;
}
.img4 {
  width: 10rem;
}
.img5 {
  width: 10.2rem;
  margin-left: -0.14rem;
}
.function-items {
  width: 100%;
  height: 4.23rem;
  background-color: #0b60d6;
  color: #ffffff;

  display: grid;
  grid-template-columns: repeat(3, 3.24rem);
  grid-gap: 0.11rem 0.11rem;
  justify-content: center;
  align-content: center;
  .item {
    width: 3.24rem;
    height: 1.69rem;
    background: #0572fa;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .title {
      font-size: 0.18rem;
    }
    .sub-title {
      font-size: 0.12rem;
      margin-bottom: 0.05rem;
    }
    .more {
      width: 0.81rem;
      height: 0.25rem;
      background: #1caff7;
      font-size: 0.12rem;
      line-height: 0.25rem;
    }
  }
}
.scene {
  margin: 0 0.78rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
  grid-gap: 0.05rem;
  .item {
    font-size: 0.15rem;
    font-weight: 400;
    color: #696969;
    > img {
      height: 2.53rem;
    }
  }
}
.bt-title {
  padding-left: 0.77rem;
}
.bt-img {
  width: 10rem;
}
.scene4 {
  display: grid;
  padding: 0 0.78rem;
  grid-template-columns: repeat(4, 1fr);
  justify-content: space-between;
  grid-gap: 0.11rem;
  .item {
    img {
      width: 100%;
      height: 1.4rem;
      vertical-align: middle;
    }
    div {
      width: 100%;
      height: 0.43rem;
      background: #4770c1;
      line-height: 0.43rem;
      font-size: 0.13rem;

      font-weight: 400;
      color: #ffffff;
    }
  }
}
.scene3 {
  display: grid;
  padding: 0 0.78rem;
  grid-template-columns: repeat(3, 1fr);
  justify-content: space-between;
  grid-gap: 0.08rem;
  .item {
    img {
      width: 100%;
      height: 1.4rem;
      vertical-align: middle;
    }
    div {
      width: 100%;
      height: 0.43rem;
      background: #4770c1;
      line-height: 0.43rem;
      font-size: 0.13rem;

      font-weight: 400;
      color: #ffffff;
    }
  }
}
.scene2 {
  display: grid;
  padding: 0 0.78rem;
  grid-template-columns: repeat(2, 1fr);
  justify-content: space-between;
  grid-gap: 0.08rem;
  margin-bottom: 1rem;
  .item {
    img {
      width: 100%;
      height: 2.43rem;
      vertical-align: middle;
    }
    div {
      width: 100%;
      height: 0.43rem;
      background: #4770c1;
      line-height: 0.43rem;
      font-size: 0.13rem;

      font-weight: 400;
      color: #ffffff;
    }
  }
}
.case-board {
  margin-bottom: 1.5rem;
  padding: 0 0.77rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 4px;
  justify-content: center;

  & :last-child {
    grid-column-start: span 2;
    img {
      height: 3.11rem !important;
    }
  }

  .item {
    img {
      width: 100%;
      height: 2.54rem;
      vertical-align: middle;
      background-color: #091686;
    }
    div {
      text-align: left;
      padding-left: 0.5rem;
      height: 0.43rem;
      line-height: 0.43rem;
      font-size: 0.15rem;
      font-weight: 400;
      color: #696969;
    }
  }
}
.top-text {
  width: 100%;
  background-color: #ffffff;
  font-size: 0.14rem;

  font-weight: 400;
  color: #303030;
  line-height: 0.22rem;
}
.top-title {
  font-size: 0.24rem;

  font-weight: 400;
  color: #404040;
  margin-top: 0.45rem;
  margin-bottom: 0.1rem;
}
.model-container {
  display: grid;
  grid-template-columns: repeat(3, 3rem);
  grid-row-gap: 0.3rem;
  justify-content: center;
  img {
    width: 0.98rem;
    height: 0.98rem;
  }
  .item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: 400;
    color: #303030;
    align-items: center;
    .title {
      font-size: 0.15rem;

      margin-top: 0.13rem;
      margin-bottom: 0.03rem;
    }
    .sub-title {
      font-size: 0.13rem;
    }
  }
}
.core-container {
  padding: 0 0.77rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0.13rem;
  justify-content: center;

  & > :first-child,
  :last-child {
    grid-column-start: span 2;
    height: 4.8rem !important;
  }

  //width: 100%;
  .item {
    position: relative;

    height: 2.5rem;

    color: #ffffff;
    .title {
      position: absolute;
      left: 0.15rem;
      top: 0.15rem;
      font-size: 0.24rem;
      font-weight: 500;
      line-height: 0.41rem;
    }
    .sub-title {
      position: absolute;
      left: 0.15rem;
      top: 0.5rem;
      font-size: 0.16rem;
      font-weight: 400;
      line-height: 0.32rem;
    }
    .right-title {
      position: absolute;
      right: 0.15rem;
      top: 0.15rem;
      font-size: 0.24rem;
      font-weight: 500;
      line-height: 0.41rem;
    }
    .right-sub-title {
      position: absolute;
      right: 0.15rem;
      top: 0.5rem;
      font-size: 0.16rem;
      font-weight: 400;
      line-height: 0.32rem;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
}
.chain-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
  grid-gap: 0.13rem;
  padding: 0 0.77rem;

  img {
    width: 100%;
  }
}
.img5-container {
  padding: 0 0.77rem 1.07rem 0.77rem;
  img {
    width: 100%;
    margin-left: -0.14rem;
  }
}
.system-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 0.08rem;
  padding: 0 0.77rem;
  img {
    width: 100%;
  }
}
.application-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0.1rem;
  padding: 0 0.77rem;
  img {
    width: 100%;
  }
  div {
    margin-top: 0.1rem;
  }
}
.case-container {
  padding: 0 0.77rem;

  > img {
    width: 100%;
  }
  .sub {
    margin-top: 0.8rem;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 0.23rem;
    .item {
      font-size: 0.16rem;

      font-weight: 400;
      color: #404040;
      img {
        width: 100%;
        padding: 0.1rem 0;
      }
    }
  }
}
.system-container2 {
  padding: 0.44rem 0.77rem;
  display: flex;
  flex-direction: column;

  .item {
    display: flex;

    &:nth-child(2n) {
      flex-direction: row-reverse;
    }
    width: 100%;
    img {
      width: 50%;
    }
    .content {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      text-align: left;

      font-weight: 400;
      color: #404040;
      padding: 0.6rem;
      .title {
        font-size: 0.24rem;
      }
      .sub-title {
        font-size: 0.13rem;
        line-height: 0.25rem;
      }
    }
  }
}
</style>
