<template>
  <div id="app">
    <div class="items">
      <img src="../assets/images/product/54.png" alt="" />
      <div class="title">市场管理系统 / 管理便捷 人员高效</div>
      <div class="sub-title">Market management system</div>
    </div>
    <div class="items">
      <img src="../assets/images/product/55.png" alt="" />
      <div class="title">进出场预登记系统 / 在线预约、快速进场</div>
      <div class="sub-title">Pre-registration for entry and exit</div>
    </div>
    <div class="items">
      <img src="../assets/images/product/56.png" alt="" />
      <div class="title">物业管理系统 / 智能办公、减员增效</div>
      <div class="sub-title">Property management system</div>
    </div>
    <div class="items">
      <img src="../assets/images/product/59.png" alt="" />
      <div class="title">合同管理系统 / 电子合同、在线签章</div>
      <div class="sub-title">Contract management system</div>
    </div>
    <div class="items">
      <img src="../assets/images/product/58.png" alt="" />
      <div class="title">食安检测系统 /来源可查、去向可追</div>
      <div class="sub-title">Food safety detection system</div>
    </div>
    <div class="items">
      <img src="../assets/images/product/57.png" alt="" />
      <div class="title">结算系统 / 在线支付、平台结算</div>
      <div class="sub-title">Settlement system</div>
    </div>
    <div class="items">
      <img src="../assets/images/product/60.png" alt="" />
      <div class="title">进销存系统 / 进出交易、笔笔清晰</div>
      <div class="sub-title">Invoicing system</div>
    </div>
    <div class="items">
      <img src="../assets/images/product/61.png" alt="" />
      <div class="title">市场巡检系统 / 移动巡检、实时知晓</div>
      <div class="sub-title">Market inspection system</div>
    </div>
    <div class="items">
      <img src="../assets/images/product/62.png" alt="" />
      <div class="title">信息公示系统 / 电子展播、一键发布</div>
      <div class="sub-title">Information disclosure system</div>
    </div>
    <div class="items">
      <img src="../assets/images/product/65.png" alt="" />
      <div class="title">收银系统 / 智能交易、智慧收银</div>
      <div class="sub-title">Cashier system</div>
    </div>
    <div class="items">
      <img src="../assets/images/product/64.png" alt="" />
      <div class="title">B2B电子商务系统 / 智能仓储、移动电商</div>
      <div class="sub-title">B2B electronic commerce system</div>
    </div>
    <div class="items">
      <img src="../assets/images/product/63.png" alt="" />
      <div class="title">市场收费系统 / 智能收费、便捷高效</div>
      <div class="sub-title">Market charging system</div>
    </div>
    <div class="items">
      <img src="../assets/images/product/66.png" alt="" />
      <div class="title">商户管理系统 / 电子档案、一键查阅</div>
      <div class="sub-title">Merchant management system</div>
    </div>
    <div class="items">
      <img src="../assets/images/product/67.png" alt="" />
      <div class="title">大数据分析系统 / 数据汇总、数据赋能</div>
      <div class="sub-title">Big data analysis system</div>
    </div>
    <div class="items">
      <img src="../assets/images/product/68.png" alt="" />
      <div class="title">设备管理系统 / 实时工况、远程协作</div>
      <div class="sub-title">Equipment management system</div>
    </div>
  </div>
</template>

<script>
//产品中心 应用系统
export default {
  name: "UtilitySystem",
}
</script>

<style scoped lang="less">
#app {
  background: white;
  display: grid;
  grid-template-columns: repeat(3, 3rem);
  justify-content: center;
  align-items: center;
}
.items {
  font-weight: 400;
  color: #303030;
  padding: 0.12rem;
  img {
    height: 0.74rem;
    width: 0.74rem;
  }
  .title {
    font-size: 0.12rem;
  }
  .sub-title {
    font-size: 0.1rem;
  }
}
</style>
