<template>
  <div id="app">
    <wrap style="height: 100%">
      <div class="swiper-container">
        <swiper class="goods-swiper" ref="goodsSwiper" :options="goodsSwiperOption" @click-slide="playChange">
          <swiper-slide v-for="(item, k) in goodsList" :key="k">
            <div class="goods-container">
              <img class="goods-img" :src="item" />
              <div class="name">
                数字化营销窗口
                <img src="@/assets/images/more_blue.png" alt="" />
              </div>
              <div class="desc">增设线上渠道 全方面品宣化</div>
            </div>
          </swiper-slide>
          <div class="swiper-button-prev swiper-button" slot="button-prev"></div>
          <div class="swiper-button-next swiper-button" slot="button-next"></div>
        </swiper>
      </div>
      <div class="list-container">
        <div class="list-item" v-for="(i, k) in 5">
          <div class="title" @click="toProduct(k)">智慧农贸</div>
          <div class="item" v-for="i in 7">
            <img src="" alt="" />
            <div>农贸市场管理系统</div>
          </div>
        </div>
      </div>
    </wrap>
  </div>
</template>

<script>
//产品中心弹窗
import Wrap from "@/components/common/Wrap"
export default {
  name: "Product",
  components: { Wrap },
  data() {
    return {
      goodsList: [],
      goodsSwiperOption: {
        slidesPerView: 5,
        pagination: {
          el: ".swiper-pagination",
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
    }
  },
  mounted() {
    this.$nextTick(() => {})
    // setTimeout(() => {
    //   this.goodsList = []
    // }, 2000)
  },
  methods: {
    toProduct(e) {
      if (e === 1) {
        this.$router.push({
          name: "ProductPage2",
        })
      } else if (e === 2) {
        this.$router.push({
          name: "ProductPage3",
        })
      } else if (e === 3) {
        this.$router.push({
          name: "ProductPage4",
        })
      } else if (e === 4) {
        this.$router.push({
          name: "ProductPage5",
        })
      } else {
        this.$router.push({
          name: "ProductPage1",
        })
      }

      this.hideMe()
    },
    hideMe() {
      this.$emit("hideMe")
    },
    playChange() {},
  },
}
</script>

<style scoped lang="less">
#app {
  background-color: #ffffff;
  width: 100%;
  //height: 7.42rem;
  padding-bottom: 1rem;
}
.list-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  justify-content: space-between;
  grid-gap: 1px;
  .list-item {
    :hover {
      cursor: pointer;
    }
    .title {
      background: #0647b9;
      height: 0.28rem;
      line-height: 0.28rem;
      font-size: 0.12rem;

      //font-weight: 500;
      color: #ffffff;
      margin: 0.09rem 0;
    }
    .item {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-top: 0.13rem;
      :hover {
        color: #0647b9;
        cursor: pointer;
      }
    }
  }
}
.goods-swiper {
  .goods-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0.23rem 0.035rem;
    .name {
      font-size: 0.13rem;

      font-weight: 400;
      color: #03162c;
      margin-top: 0.11rem;
      margin-bottom: 0.04rem;
    }
    .desc {
      font-size: 0.1rem;
    }
    .goods-img {
      width: 100%;
      height: 1.27rem;
      background-color: gold;
    }
  }
}
.swiper-button-next,
.swiper-button-prev {
  width: 0.16rem;
  height: 0.25rem;
  top: 43%;
  background-color: white;
  opacity: 0.6;
}
.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: initial;
}
</style>
