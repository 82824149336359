import "core-js/stable"
import "regenerator-runtime/runtime"
import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import Element from "element-ui"
import "./assets/styles/element.less"
// swiper全局引入
import VueAwesomeSwiper from "vue-awesome-swiper"
import "swiper/swiper-bundle.min.css"
import Swiper2, { Navigation, Pagination, Controller, EffectFade, Autoplay } from "swiper"
import PerfectScrollbar from "vue2-perfect-scrollbar"
import "vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css"
import VueVideoPlayer from "vue-video-player"

// require videojs style
import "video.js/dist/video-js.css"
// import 'vue-video-player/src/custom-theme.css'
import VueQr from "vue-qr"

Vue.use(
  VueVideoPlayer /* {
  options: global default options,
  events: global videojs events

} */
)

Vue.use(VueQr)

Vue.use(PerfectScrollbar)

Swiper2.use([Navigation, Pagination, Controller, EffectFade, Autoplay])

Vue.use(VueAwesomeSwiper /* { default options with global component } */)
Vue.use(Element)

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount("#app")
