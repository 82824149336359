<template>
  <div
    class="wrap"
    :style="{
      paddingLeft: padding + `px`,
      paddingRight: padding + `px`,
      backgroundImage: bgSrc ? `url(${bgSrc})` : '',
    }"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "Wrap",
  props: ["bgSrc"],
  data: () => ({
    padding: 0,
  }),
  created() {
    this.updatePadding()
    window.addEventListener("resize", this.updatePadding)
  },
  destroyed() {
    window.removeEventListener("resize", this.updatePadding)
  },
  methods: {
    updatePadding() {
      let clientWidth = document.documentElement.clientWidth
      // console.log(clientWidth)
      // const fontSize = getComputedStyle(window.document.documentElement)['font-size']
      // 1920*0.5=960
      let fontSize = 0
      if (!this.$app.isPc) {
        this.padding = 0
        return
      }
      if (clientWidth <= 1240) {
        fontSize = 82
        this.padding = (1240 - 9.6 * fontSize) / 2
      } else if (clientWidth <= 1920) {
        fontSize = 100 * (1 - (1920 - clientWidth) / 1920 / 2)
        this.padding = (clientWidth - 9.6 * fontSize) / 2
      } else {
        fontSize = 100 * (1 + (clientWidth - 1920) / 1920)
        this.padding = (clientWidth - 9.6 * fontSize) / 2
      }
    },
  },
}
</script>

<style lang="less" scoped>
.wrap {
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
  background-position: center;
  background-size: cover;
  flex: 1;

  //min-width: 1020px;
  //@media screen and (max-width: 1200px) {
  //  padding-left: 0 !important;
  //  padding-right: 0 !important;
  //}
}
</style>
